/*eslint-disable*/
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link  } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";
// import HeaderTotal from "components/Headers/HeaderTotal";

export default function PosKasir() {
    // const warehouse = localStorage.warehouse;
    const token = localStorage.token;
    const username  = localStorage.username
    const [statuskasir,setStatusKasir] = useState("")
    const allInfo = JSON.parse(localStorage.allinfo);
    const Kasir = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Pos Cashier").map((p) => p.read_access));
  
    

	const cekKasir = useCallback(async () => {
		const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/chasier-settlement/check-by-username/${username}`);
		if (res.data.status === 200) 
				  setStatusKasir(true);
		else 	
    setStatusKasir(false);
	},[token]);

    useEffect(() => {
		cekKasir();
	}, [cekKasir]);

    return (
    <>
      {Kasir && Kasir === "1" ? (
			<div>
      <SimpleHeader name="POS KASIR" parentName="Report" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                            <Card>
                                <Link to={statuskasir ? "/admin/pos/modal" : "/admin/pos/so-kasir"}>
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/kasir-besar.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            
                            <Card>
                                <Link to="/admin/pelunasan">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/pelunasan-kasir-01.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/pos/history">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history-kasir.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/kasir-retur">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/retur-kasir-01.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            
                            <Card>
                                <Link to="/admin/cek-barcode">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/cek-barcode.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>

                            </Card>
                            <Card>
                                <Link to="/admin/closing-kasir">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/settlement-01.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>

                            </Card>
                            <Card>
                                <Link to="/admin/surat-jalan-kasir">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/surat-jalan-kasir-01.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            
                            <Card>

                            </Card>
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
      ):(
        <Halaman404 />
      )};
    </>
  );
}