
/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "views/pages/login/Index.js";
import Dashboard from "views/pages/dashboard/Index.js";
import Users from "views/pages/user/Index3.js";
import CreateUser from "views/pages/user/Create.js";
import EditUser from "views/pages/user/Edit3.js";
import EditPrivileges from "views/pages/user/privileges/Edit.js";

import DetailRegion from "views/pages/item/itemMain/DetailRegion";
import ItemCabang from "views/pages/ItemRegion/Index.js"

import ItemToko from "views/pages/ItemCabang/Index.js"


import karyawanbaru from "views/pages/karyawanbaru/Index"
import CreateKaryawanBaru from "views/pages/karyawanbaru/Create";

import Alamat from "views/pages/address/Index.js";
import CreateAlamat from "views/pages/address/Create.js";
import EditAlamat from "views/pages/address/Edit.js";

import Aplikator from "views/pages/aplikator/Index";
import CreateAplikator from "views/pages/aplikator/Create.js";

import Account from "views/pages/account/Index.js";
import CreateAccount from "views/pages/account/Create.js";
import EditAccount from "views/pages/account/Edit.js";

import Bank from "views/pages/bank/Index2";
import CreateBank from "views/pages/bank/Create";
import EditBank from "views/pages/bank/Edit";

import Asset from "views/pages/asset/Index.js";
import CreateAsset from "views/pages/asset/Create.js";
import EditAsset from "views/pages/asset/Edit.js";

import TypeAsset from "views/pages/asset/type/Index.js";
import CreateTypeAsset from "views/pages/asset/type/Create.js";
import EditTypeAsset from "views/pages/asset/type/Edit.js";

// import Absen from "views/pages/absen/Index.js";

import Uom from "views/pages/satuan/Index.js";
import CreateUom from "views/pages/satuan/Create";
import EditUom from "views/pages/satuan/Edit.js";

import Customer from "views/pages/Customer/Index.js";
import CreateCustomer from "views/pages/Customer/Create.js";
import EditCustomer from "views/pages/Customer/Edit2.js";

import Coa from "views/pages/coa/Index.js";
import CreateCoa from "views/pages/coa/Create.js";
import EditCoa from "views/pages/coa/Edit.js";

import ItemKatalog from "views/pages/Katalog/Index"

import ItemCategorys from "views/pages/Katalog/itemCategory/Index.js";
import CreateItemCategorys from "views/pages/Katalog/itemCategory/Create.js";
import EditItemCategorys from "views/pages/Katalog/itemCategory/Edit.js";

import ItemSubCategorys from "views/pages/Katalog/itemSubCategory/Index.js";
import CreateItemSubCategorys from "views/pages/Katalog/itemSubCategory/Create.js";

import ItemFunctions from "views/pages/Katalog/itemFunction/Index.js";
import CreateItemFunctions from "views/pages/Katalog/itemFunction/Create.js";

import ItemSubFunctions from "views/pages/Katalog/itemSubFunction/Index.js";
import CreateItemSubFunctions from "views/pages/Katalog/itemSubFunction/Create.js";

import ItemMereks from "views/pages/Katalog/itemMerek/Index.js";
import CreateItemMereks from "views/pages/Katalog/itemMerek/Create.js";

import ItemSubMereks from "views/pages/Katalog/itemSubMerek/Index.js";
import CreateItemSubMereks from "views/pages/Katalog/itemSubMerek/Create.js";

import ItemGrades from "views/pages/Katalog/itemGrade/Index.js";
import CreateItemGrades from "views/pages/Katalog/itemGrade/Create.js";

// import CreateItem from "views/pages/item/itemMain/Create.js";
import CreateItemProduksi from "views/pages/item/itemMain/ItemProduksi/Create";

// import Item from "views/pages/item/Index.js";
import CreateItem from "views/pages/item/itemMain/Create.js";
import EditItem from "views/pages/item/itemMain/Edit3";
import ItemMain from "views/pages/item/itemMain/ItemIndex";
import DetailItems from "views/pages/item/itemMain/detail/Index.js";

import ItemCategory from "views/pages/item/itemCategory/Index.js";
import CreateItemCategory from "views/pages/item/itemCategory/Create.js";
import EditItemCategory from "views/pages/item/itemCategory/Edit.js";

//warehouse pusat
import WarehousePusat from "views/pages/warehousePusat/Index.js";
import CreateWarehousePusat from "views/pages/warehouseAll/CreatePusat";
// import CreateGudangPusat from "views/pages/warehouseAll/CreateGudangPusat";
import CreateRegion from "views/pages/warehouseAll/CreateRegion";
// import CreateGudangRegion from "views/pages/warehouseAll/CreateGudangRegion";
import CreateToko from "views/pages/warehouseAll/CreateToko";
import CreateGudangToko from "views/pages/warehouseAll/CreateGudangToko";
import EditWarehouseaAll from "views/pages/warehousePusat/Edit"


import Pelunasan from "views/pages/so/PelunasanKasir/Index.js"
import PelunasanEdit from "views/pages/so/PelunasanKasir/Edit3"

import ItemSubCategory from "views/pages/item/itemSubCategory/Index.js";
import CreateItemSubCategory from "views/pages/item/itemSubCategory/Create.js";

import ItemFunction from "views/pages/item/itemFunction/Index.js";
import CreateItemFunction from "views/pages/item/itemFunction/Create.js";

import ItemSubFunction from "views/pages/item/itemSubFunction/Index.js";
import CreateItemSubFunction from "views/pages/item/itemSubFunction/Create.js";

import ItemMerek from "views/pages/item/itemMerek/Index.js";
import CreateItemMerek from "views/pages/item/itemMerek/Create.js";

import ItemSubMerek from "views/pages/item/itemSubMerek/Index.js";
import CreateItemSubMerek from "views/pages/item/itemSubMerek/Create.js";

import ItemGrade from "views/pages/item/itemGrade/Index.js";
import CreateItemGrade from "views/pages/item/itemGrade/Create.js";

// import Karyawan from "views/pages/karyawan/Index.js";
// import CreateKaryawan from "views/pages/karyawan/Create.js";
import EditKaryawan from "views/pages/karyawanbaru/Edit.js";

// import Komisi from "views/pages/komisi/Index.js";

import Person from "views/pages/person/Index2.js";
import CreatePerson from "views/pages/person/Create.js";
import EditPerson from "views/pages/person/Edit2";

import Pajak from "views/pages/pajak/Index.js";
import CreatePajak from "views/pages/pajak/Create.js";
import EditPajak from "views/pages/pajak/Edit.js";

// import Rak from "views/pages/Rak/Index2.js";

import Rak from "views/pages/Rak/Index2.js";
import CreateRak from "views/pages/Rak/Create";
import EditRak from "views/pages/Rak/Edit.js";

import PurchaseOrderCabang from "views/pages/po/Index1";

import ValidasiPo from "views/pages/po/ValidasiPo/Index.js";
import EditValidasiPo from "views/pages/po/ValidasiPo/Edit.js";

import AdminApprove from "views/pages/po/AdminApprove/Index.js";
import EditAdminApprove from "views/pages/po/AdminApprove/Edit.js";

import ValidatorPo from "views/pages/po/ValidatorPo/Index.js";
import EditValidatorPo from "views/pages/po/ValidatorPo/Edit.js";
import CetakPO from "views/pages/po/CetakPo/Cetak";
import DetailPO from "views/pages/po/CetakPo/Detail";

import ReceivingPO from "views/pages/po/ReceivingPo/Index1.js";
import CreateReceivingPO from "views/pages/po/ReceivingPo/ReceivingPo/Create.js";
import CetakCetakReceivingPO from "views/pages/po/ReceivingPo/ReceivingPo/Cetak";
import EditReceivingPO from "views/pages/po/ReceivingPo/ReceivingPo/Edit1";
import CetakReceivingPo from "views/pages/po/ReceivingPo/CetakReceivingPo/Cetak";
import DetailCetakReceivingPO from "views/pages/po/ReceivingPo/CetakReceivingPo/Detail";
import ValidasiReceivingPO from "views/pages/po/ReceivingPo/ValidasiReceivingPo/Edit.js";

import So from "views/pages/so/Index1.js";

import ValidasiSo from "views/pages/so/ValidasiSo/Index.js";
import EditValidasiSo from "views/pages/so/ValidasiSo/Edit.js";

import AdminApproveSo from "views/pages/so/AdminApprove/Index.js";
import EditAdminApproveSo from "views/pages/so/AdminApprove/Edit.js";

import PimpinanSo from "views/pages/so/ValidatorSo/Index.js";
import EditPimpinanSo from "views/pages/so/ValidatorSo/Edit.js";

import CreateSo from "views/pages/so/Create.js";
import EditSo from "views/pages/so/Edit.js";

// import SoKasir from "views/pages/so/soKasir/Create.js";
// import SoKasir from "views/pages/so/soKasirBaru/Index9";
import SoKasir from "views/pages/so/soKasirBaru/IndexKasirTerbaru";
import DetailHistoryKasir from "views/pages/so/Kasir/Detailhistory";
// import CetakSoKasir from "views/pages/so/soKasirBaru/Cetak";

// import KasirSettlement from "views/pages/so/Kasir/Index.js";
import KasirSettlement from "views/pages/so/Kasir/Index1";
import CreateKasirSettlement from "views/pages/so/Kasir/Create.js";
import DetailKasirSettlement from "views/pages/so/Kasir/Detail.js";

import ClosingKasir from "views/pages/so/ClosingKasir/Index";
import DetailClosingKasir from "views/pages/so/ClosingKasir/Detail";

import Opname from "views/pages/opname/Index1";
import CreateOpname from "views/pages/opname/Opname/Create1";
import EditOpname from "views/pages/opname/Opname/Edit";
import ValidasiDirekturOpname from "views/pages/opname/ValidasiDirekturOpname/Edit";
import ValidasiDirekturOpname1 from "views/pages/opname/ValidasiDirekturOpname/Index";

import CetakStokOpname from "views/pages/opname/CetakOpname/Cetak";

import Adjustment from "views/pages/adjustment/Index1";
import CreateAdjustment from "views/pages/adjustment/Adjustment/Create.js";
import EditAdjustment from "views/pages/adjustment/Adjustment/Edit1";
import ValidasiAdjustment from "views/pages/adjustment/ValidasiDirekturAdj/Edit.js";
import ValidasiAdjustmentPage from "views/pages/adjustment/ValidasiDirekturAdj/Index";
import CetakAdjustment from "views/pages/adjustment/CetakAdjustment/Cetak";
import CetakAdjustmentPage from "views/pages/adjustment/CetakAdjustment/Index";

import Promo from "views/pages/promo/Index2.js";
import CreatePromo from "views/pages/promo/PromoItem/CreateBaru";
import CreatePromo1 from "views/pages/promo/PromoKategori/Create";
import CreatePromo2 from "views/pages/promo/PromoTransaksi/Create";
import EditPromo from "views/pages/promo/Edit.js";

import CreatePromoGlobal from "views/pages/promo/PromoGlobal/Create";

import DetailKartuStok from "views/pages/itemStock/StockPribadi/KartuStok";
import DetailItemStok from "views/pages/itemStock/StockPribadi/SemuaStok";
// import ItemStock from "views/pages/itemStock/Index.js";
import ItemStock from "views/pages/itemStock/StockPribadi/Index";


import salesCanvasing from "views/pages/salesCanvasing/Index.js";
import HistorysalesCanvasing from "views/pages/salesCanvasing/History.js";
// import SalesTarcking from "views/pages/salesCanvasing/salesTrack/Index.js";

// import TransferStockWarehouse from "views/pages/transferstokwarehouse/Index.js";
// import TransferStokWarehousePages from "views/pages/transferstokwarehouse/Index1";
// import TransferStokWarehouse from "views/pages/transferstokwarehouse/transferstokwarehouse/Index";
// import CreateTransferStockWarehouse from "views/pages/transferstokwarehouse/transferstokwarehouse/Create.js";
// import EditTransferStockWarehouse from "views/pages/transferstokwarehouse/transferstokwarehouse/Edit.js";
// import DetailTransferStockWarehouse from "views/pages/transferstokwarehouse/CetakTranferStokWarehouse/Detail.js";
// import ValidasiKepalaTokoTSW from "views/pages/transferstokwarehouse/ValidasiKepalaToko/Edit.js";
// import ValidasiDirekturTSW from "views/pages/transferstokwarehouse/ValidasiDirektur/Edit";

// import Jurnal from "views/pages/jurnal/Index.js";
// import CreateJurnal from "views/pages/jurnal/Create.js";

import {
  UpdateImage,
  UploadImage,
  MainList,
  UpdateBanner,
  UploadBanner,
} from "views/pages/admin";

import MainJangka  from "views/pages/jangkaWaktu/Index";
import CreateJangkaWaktu from "views/pages/jangkaWaktu/Create";
import EditJangkaWaktu from "views/pages/jangkaWaktu/Edit";

import UpdateImageItem from "views/pages/admin/UpdateImageItem";

// import PermintaanBarang from "views/pages/po/PermintaanBarang/Index.js";
import CreatePermintaanBarang from "views/pages/po/PermintaanBarang/PermintaanBarang/Create.js"
import EditPermintaanBarang from "views/pages/po/PermintaanBarang/PermintaanBarang/Edit.js";
import ValidasiPermintaanBarang from "views/pages/po/PermintaanBarang/ValidasiPermintaanBarang/Index.js";
import EditValidasiPermintaanBarang from "views/pages/po/PermintaanBarang/ValidasiPermintaanBarang/Edit.js";

import CreatePenawaranPo from "views/pages/po/PenawaranPo/Create";
import EditPenawaranPo from "views/pages/po/PenawaranPo/Edit.js";
import DetailPenawaranPo from "views/pages/po/PenawaranPo/Detail";

import MemoKurirPO from "views/pages/po/MemoKurirPo/Index.js";
import CreateMemoKurirPO from "views/pages/po/MemoKurirPo/MemoKurirPo/Create";
import DetailMemoKurirPO from "views/pages/po/MemoKurirPo/MemoKurirPo/Detail";
import ValidasiAdminPoo from "views/pages/po/MemoKurirPo/ValidasiAdminPo/Edit";
import CetakMemoPo from "views/pages/po/MemoKurirPo/CetakMemo/Cetak";
import DetailMemoCetak from "views/pages/po/MemoKurirPo/CetakMemo/Detail";

import InvoicePO from "views/pages/po/InvoicePo/Index1.js";
import CreateInvoicePO from "views/pages/po/InvoicePo/InvoicePo/Create1.js";
import EditInvoicePO from "views/pages/po/InvoicePo/InvoicePo/Edit.js";
import DetailInvoicePO from "views/pages/po/InvoicePo/InvoicePo/Detail";
import CetakInvoicePO from "views/pages/po/InvoicePo/CetakInvoice/Cetak";

import ValidasiAdminFinance from "views/pages/po/InvoicePo/ValidasiAdminFinance/Edit.js";
import ValidasiPimpinan from "views/pages/po/InvoicePo/ValidasiPimpinan/Edit.js";

import BuktiKasKeluar from "views/pages/po/BuktiKasKeluar/Index.js";
import CreateBuktiKasKeluar from "views/pages/po/BuktiKasKeluar/BuktiKasKeluar/Create.js";
import DetailBuktiKasKeluar from "views/pages/po/BuktiKasKeluar/BuktiKasKeluar/Detail.js";
import ValidasiAdminFinanceBkk from "views/pages/po/BuktiKasKeluar/ValidasiKepalaFinance/Edit.js";
import ValidasiDirekturBkk from "views/pages/po/BuktiKasKeluar/ValidasiPimpinan/Edit.js";

import BuktiKasMasuk from "views/pages/so/BuktiKasMasuk/Index.js";
import CreateBuktiKasMasuk from "views/pages/so/BuktiKasMasuk/BuktiKasMasuk/Create.js";
import DetailBuktiKasMasuk from "views/pages/so/BuktiKasMasuk/BuktiKasMasuk/Detail.js";
import ValidasiAdminFinanceBkm from "views/pages/so/BuktiKasMasuk/ValidasiAdminFinance/Edit.js";
import ValidasiDirekturBkm from "views/pages/so/BuktiKasMasuk/ValidasiDirekturBKM/Edit.js";

import PermintaanBarangSo from "views/pages/so/PermintaanBarang/Index1.js";
import CreatePermintaanBarangSo from "views/pages/so/PermintaanBarang/PermintaanBarang/Create.js";
import DetailPermintaanBarangSo from "views/pages/so/PermintaanBarang/PermintaanBarang/Detail.js";

import EditPermintaanBarangSo from "views/pages/so/PermintaanBarang/PermintaanBarang/Edit.js";
import ValidasiPermintaanBarangSo from "views/pages/so/PermintaanBarang/ValidasiPermintaanBarang/Index.js";
import EditValidasiPermintaanBarangSo from "views/pages/so/PermintaanBarang/ValidasiPermintaanBarang/Edit.js";

import CreatePenawaranSo from "views/pages/so/PenawaranSo/Create.js"
import EditPenawaranSo from "views/pages/so/PenawaranSo/Edit.js";
import DetailPenawaranSo from "views/pages/so/PenawaranSo/Detail.js";

import SuratJalanSo from "views/pages/so/SuratJalanSo/Index1.js";
import CreateSuratJalanSo from "views/pages/so/SuratJalanSo/SuratJalanSo/Create.js";
import EditSuratJalanSo from "views/pages/so/SuratJalanSo/SuratJalanSo/Edit1";
import CetakCetakSuratJalanSo from "views/pages/so/SuratJalanSo/SuratJalanSo/Cetak";
import DetailSuratJalanSo from "views/pages/so/SuratJalanSo/SuratJalanSo/Detail.js";
import ValidasiSuratJalanSo from "views/pages/so/SuratJalanSo/ValidasiSuratJalanSo/Edit.js";
import CetakSuratJalan from "views/pages/so/SuratJalanSo/CetakSuratJalan/Cetak.js";

import SuratJalanKasir from "views/pages/so/SuratJalanKasir/Index1.js";
import CreateSuratJalanKasir from "views/pages/so/SuratJalanKasir/SuratJalanKasir/Create.js";
import EditSuratJalanKasir from "views/pages/so/SuratJalanKasir/SuratJalanKasir/Edit.js";
import CetakCetakSuratJalanKasir from "views/pages/so/SuratJalanKasir/SuratJalanKasir/Cetak";
import DetailSuratJalanKasir from "views/pages/so/SuratJalanKasir/SuratJalanKasir/Detail.js";
import ValidasiSuratJalanKasir from "views/pages/so/SuratJalanKasir/ValidasiSuratJalanKasir/Edit.js";
import CetakSuratJalanKasir from "views/pages/so/SuratJalanKasir/CetakSuratJalanKasir/Cetak.js";

import InvoiceSo from "views/pages/so/InvoiceSo/Index1.js";
import CreateInvoiceSo from "views/pages/so/InvoiceSo/InvoiceSo/Create.js"
import EditInvoiceSo from "views/pages/so/InvoiceSo/InvoiceSo/Edit.js"
import DetailInvoiceSo from "views/pages/so/InvoiceSo/InvoiceSo/Detail.js"
import Validasifinance from "views/pages/so/InvoiceSo/ValidasiInvoiceSo/Edit.js";
import ValidasiDirektur from "views/pages/so/InvoiceSo/ValidasiPemimpin/Edit.js";
import DetailInvoiceSoS from "views/pages/so/InvoiceSo/CetakInvoice/Detail"

// import ReceivingTransfer from "views/pages/transferstokwarehouse/ReceivingTransfer/Index1";
// import CreateReceivingTransfer from "views/pages/transferstokwarehouse/ReceivingTransfer/ReceivingTransfer/Create";
// import EditReceivingTransfer from "views/pages/transferstokwarehouse/ReceivingTransfer/ReceivingTransfer/Edit";
// import CetakReceivingTransfers from "views/pages/transferstokwarehouse/ReceivingTransfer/ReceivingTransfer/Cetak";
// import ValidasiKepalaGudangR from "views/pages/transferstokwarehouse/ReceivingTransfer/ValidasiKepalaGudang/Edit";
// import CetakReceivingTransfer from "views/pages/transferstokwarehouse/ReceivingTransfer/CetakReceivingTransfer/Cetak";

// import SuratJalanTransfer from "views/pages/transferstokwarehouse/SuratJalanTransfer/Index1";
// import CreateTransferSuratJalan from "views/pages/transferstokwarehouse/SuratJalanTransfer/SuratJalanTransfer/Create";
// import EditTransferSuratJalan from "views/pages/transferstokwarehouse/SuratJalanTransfer/SuratJalanTransfer/Edit";
// import ValidasiSuratJalanSuratJalanTransferStok from "views/pages/transferstokwarehouse/SuratJalanTransfer/ValidasiKepalaGudang/Edit"
// import CetakSuratJalanTransfer from "views/pages/transferstokwarehouse/SuratJalanTransfer/CetakSuratJalan/Cetak";

import TransferRak from "views/pages/transferRak/Index";
import CreateTransferRak from "views/pages/transferRak/Create";

import DurasiOperasional from "views/pages/durasiOperasional/Index2";
import CreateDurasiOperasional from "views/pages/durasiOperasional/Create";
import EditDurasiOperasional from "views/pages/durasiOperasional/Edit";

import PasswordOperasional from "views/pages/durasiPassword/Index";
import EditPasswordOperasional from "views/pages/durasiPassword/Edit";

import UsersEcommerce from "views/pages/usersEcommerce/Index.js";
import CreateUsersEcommerce from "views/pages/usersEcommerce/Create";
import DetailUsersEcommerce from "views/pages/usersEcommerce/Detail";

import TransactionEcommerce from "views/pages/transaksiEcommerce/Index.js";
import EditTransactionEcommerce from "views/pages/transaksiEcommerce/transaksiEcommerce/Edit";

import BankEcommerce from "views/pages/bankEcommerce/Index.js";
// import Price from "views/pages/Price/Index"

import PoRetur from "views/pages/po/PoRetur/Index1";
import CreatePoRetur from "views/pages/po/PoRetur/PoRetur/Create2";
import EditPoRetur from "views/pages/po/PoRetur/PoRetur/Edit";
import DetailPoRetur from "views/pages/po/PoRetur/PoRetur/Detail";
import ValidasiAdminTokoReturPo from "views/pages/po/PoRetur/ValidasiAdminToko/Edit2";
import ValidasiDirekturPoRetur from "views/pages/po/PoRetur/ValidasiDirekturPoRetur/Edit1";
import CetakReturPo from "views/pages/po/PoRetur/CetakPoRetur/Cetak";
import DetailPoReturCetak from "views/pages/po/PoRetur/CetakPoRetur/Detail";
import CreatePoReturBuktiKasMasuk from "views/pages/po/PoRetur/CetakPoRetur/Bkm";

// import SoReturKasir from "views/pages/so/SoRetur/SoReturKasir/Index";
// import SoReturProyek from "views/pages/so/SoRetur/SoReturProyek/Index";

import KurirEcommerce from "views/pages/Kurir/Index";
import CreateListKurir from "views/pages/Kurir/ListKurir/Create";
import KurirbyWarehouse from "views/pages/Kurir/KurirByWarehouse/Create";
import CreateDurasiKurir from "views/pages/Kurir/DurasiKurir/Create";
import CreateProdukGrup from "views/pages/Kurir/ProdukGrup/Create";

import CetakInvoiceSO from "views/pages/so/InvoiceSo/CetakInvoice/Cetak";
import CetakBuktiKasKeluar from "views/pages/po/BuktiKasKeluar/CetakKasKeluar/Cetak";
import DetailBkk from "views/pages/po/BuktiKasKeluar/CetakKasKeluar/Detail";

import CetakPenawaranBarang from "views/pages/so/PermintaanBarang/CetakPenawaranBarang/Cetak";
import CetakBuktiKasMasuk from "views/pages/so/BuktiKasMasuk/CetakBuktiKasMasuk/Cetak";
// import CetakTransferStokWarehouse from "views/pages/transferstokwarehouse/CetakTranferStokWarehouse/Index";

// import LaporanStok from "views/pages/Laporan/LaporanStok/Index";
// import LaporanPo from "views/pages/Laporan/LaporanPo/Index.js"
// import LaporanSo from "views/pages/Laporan/LaporanSo/Index2.js"
// import LaporanSokasir from "views/pages/Laporan/LaporanKasir/Index.js"
// import LaporanJurnal from "views/pages/Laporan/LaporanJurnal/Index.js"
// import LaporanJurnalBukuBesar from "views/pages/Laporan/LaporanJurnal/JurnalBukuBesar/Index.js"
// import LaporanJurnalLabaRugi from "views/pages/Laporan/LaporanJurnal/JurnalLabaRugi/Index.js"
// import LaporanJurnalNeraca from "views/pages/Laporan/LaporanJurnal/JurnalNeraca/Index.js"
// import LaporanJurnalPenyesuaian from "views/pages/Laporan/LaporanJurnal/JurnalPenyesuaian/Index.js"
import LaporanJurnalUmum from "views/pages/Laporan/LaporanJurnal/JurnalUmum/Index"
import DetailJurnalUmum from "views/pages/Laporan/LaporanJurnal/JurnalUmum/Detail"
// import LaporanReceivingPo from "views/pages/Laporan/LaporanReceivingPo/Index.js";
// import LaporanInvoicePo from "views/pages/Laporan/LaporanInvoicePo/Index.js";
// import LaporanInvoiceSo from "views/pages/Laporan/LaporanInvoiceSo/Index.js";
// import LaporanReceivingTransfer from "views/pages/Laporan/LaporanReceivingTransfer/Index.js";
// import LaporanSuratJalanProyek from "views/pages/Laporan/laporanSuratJalanProyek/Index.js";
// import LaporanSuratJalanKasir from "views/pages/Laporan/LaporanSuratJalanKasir/Index.js";
// import LaporanSuratJalanTransfer from "views/pages/Laporan/LaporanSuratJalanTransfer/Index.js";
// import LaporanInvoiceSoKasir from "views/pages/Laporan/LaporanInvoiceKasir/Index.js";
// import LaporanBuktiKasMasuk from "views/pages/Laporan/LaporanBuktiKasMasuk/Index.js";
// import LaporanBuktiKasKeluar from "views/pages/Laporan/LaporanBuktiKasKeluar/Index.js";

import SoRetur from "views/pages/so/SoRetur/Index1";
// import CreateSoReturProyek from "views/pages/so/SoRetur/SoReturProyek/Create";
import CreateSoReturProyek from "views/pages/so/SoRetur/SoReturProyek/Create";
import ValidasiReturSoKepalaToko from "views/pages/so/SoRetur/ValidasiAdminSo/Edit";
import EditSoKasurProyek from "views/pages/so/SoRetur/SoReturProyek/Edit";
import SoReturValidasiManajerOffice from "views/pages/so/SoRetur/ValidasiManajerOffice/Edit";
import ValidasiDirekturSoRetur from "views/pages/so/SoRetur/ValidasiDirektur/Edit";
import ValidasiDirekturSoRetur1 from "views/pages/so/SoRetur/ValidasiDirektur/Index";
import ValidasiCetakRetur from "views/pages/so/SoRetur/CetakReturSo/BkkBaru";

import SoReturKasir from "views/pages/so/SoReturKasir/Index1";
import CreateSoReturKasirBaru from "views/pages/so/SoReturKasir/SoReturKasir/Create";
import EditSoReturKasirBaru from "views/pages/so/SoReturKasir/SoReturKasir/Edit";
import ValidasiReturSoKepalaTokoBaru from "views/pages/so/SoReturKasir/ValidasiKepalaToko/Edit";
import ValidasiOfficeManager from "views/pages/so/SoReturKasir/ValidasiOfficeManager/Edit";
import ValidasiDirekturReturKasir from "views/pages/so/SoReturKasir/ValidasiDirektur/Edit";
import ValidasiDirekturReturKasir1 from "views/pages/so/SoReturKasir/ValidasiDirektur/Index";
import CetakReturKasir from "views/pages/so/SoReturKasir/CetakReturKasir/BkkBaru";
import CreateBiaya from "views/pages/Biaya/Create";
import CreatePemasukan from "views/pages/Pemasukan/Create";
import BKKFINANCE from "views/pages/financeBkk/Index1";
import CreateFinanceBkk from "views/pages/financeBkk/financeBkk/Create";
import BKMFINANCE from "views/pages/financeBkm/Index1";
import CreateFinanceBkm from "views/pages/financeBkm/financeBkm/Create";
import ValidasiKepalaTokoBKK from "views/pages/financeBkk/ValidasiKepalaFinance/Edit";
import ValidasDirekturBkkFinance from "views/pages/financeBkk/ValidasiDirektur/Edit";

import ValidasiBkmFinance from "views/pages/financeBkm/ValidasiKepalaToko/Edit";
import ValidasiDirekturBKMFinance from "views/pages/financeBkm/ValidasiDirektur/Edit";
import RfqPusat from "views/pages/po/RfqPusat/Index";
import DetailRfqPusat from "views/pages/po/RfqPusat/Detail";
import Rekonsiliasi from "views/pages/Rekonsiliasi/Index";
import CreateRekonsiliasi from "views/pages/Rekonsiliasi/Create";

import OrderPusat from "views/pages/po/OrderPusat/Index1";
import CreatePenawaranPoPusat from "views/pages/po/OrderPusat/PermintaanPo/Create";
import EditPenawaranPoPusat from "views/pages/po/OrderPusat/PermintaanPo/Edit";
import EditValidasiPoPusat from "views/pages/po/OrderPusat/ValidasiPermintaanPo/Edit";
import EditAdminPoPusat from "views/pages/po/OrderPusat/ValidasiAdminPo/Edit";
import EditValidatorPoPusat from "views/pages/po/OrderPusat/ValidasiDirektur/Edit";

import BkkInvoicePo from "views/pages/po/InvoicePo/CetakInvoice/Bbk";
import BkmInvoiceSo from "views/pages/so/InvoiceSo/CetakInvoice/Bkm";
import ValidasiBkmKepalaFinanceReturPo from "views/pages/BkmReturPo/ValidasiKepalaFinance/Edit";
import ValidasiBkmDirekturReturPo from "views/pages/BkmReturPo/ValidasiDirektur/Edit";
import ValidasiBkkKepalaFinanceReturSo from "views/pages/BkkReturSo/ValidasiKepalaFinance/Edit";
import LogPriceKartuStok from "views/pages/item/itemMain/LogPrice";
import CreateItemStok from "views/pages/itemStock/StockPribadi/Create";
import BbkFinance from "views/pages/Finance/BBK/Index1";
import CreateFinanceBbk from "views/pages/Finance/BBK/BBK/Create";
import ValidasiBbkFinance from "views/pages/Finance/BBK/ValidasiKepalaFinance/Edit";
import ValidasiDirekturFinanceBbk from "views/pages/Finance/BBK/ValidasiDirektur/Edit";
import BbmFinance from "views/pages/Finance/BBM/Index1";
import CreateFinanceBbm from "views/pages/Finance/BBM/BBM/Create";
import ValidasiBbmFinance from "views/pages/Finance/BBM/ValidasiKepalaFinance/Edit";
import ValidasiDirekturBBMFinance from "views/pages/Finance/BBM/ValidasiDirektur/Edit";
import Pembulatan from "views/pages/Pembulatan/Index";
import EditPembulatan from "views/pages/Pembulatan/Edit";
import JadwalOpnameIndex from "views/pages/Finance/JadwalOpname/Index1";
import CreateJadwalOpname from "views/pages/Finance/JadwalOpname/JadwalOpname/Create";
import EditJadwalOpname from "views/pages/Finance/JadwalOpname/JadwalOpname/Edit";
import EditHargaPerToko from "views/pages/item/itemMain/EditPriceDipakaiSekarang";
import TipeRak from "views/pages/Rak/TipeRak/Index";
import CreateTipeRak from "views/pages/Rak/TipeRak/Create";
import EditTypeRak from "views/pages/Rak/TipeRak/Edit";
import SuratJalanDetailCetak from "views/pages/so/SuratJalanKasir/CetakSuratJalanKasir/Detail";
import PenerimaBarang from "views/pages/so/SuratJalanKasir/CetakSuratJalanKasir/achiever";
import IndexIzinHrd from "views/pages/Hrd/Izin/Index";
import ValidasiHrdIzinValidasi from "views/pages/Hrd/Izin/Izin/Edit";
import IndexAbsensiKaryawan from "views/pages/Hrd/Absensi/Index1";
import IndexPromoGlobal from "views/pages/promo/PromoIndex/IndexPromoGlobal";
import EditPromoGlobal from "views/pages/promo/PromoGlobal/Edit";
import PromoIndexProduk from "views/pages/promo/PromoIndex/IndexPromoProduk";
import CreatePromoPerorangan from "views/pages/promo/PromoPerorangan/Create";
import PromoIndexBudget from "views/pages/promo/PromoIndex/IndexPromoBudget";
import EditPromoPerorangan from "views/pages/promo/PromoPerorangan/Edit";
import CreatePromoProduk from "views/pages/promo/PromoProduk/Create";
import EditPromoProduk from "views/pages/promo/PromoProduk/Edit";
import PromoIndexBundling from "views/pages/promo/PromoIndex/IndexPromoBundling";
import CreatePromoBundling from "views/pages/promo/PromoBundling/Create";
import EditPromoBundling from "views/pages/promo/PromoBundling/Edit";
// import ValidasiKepalaTokoTransferEksternal from "views/pages/transferstokwarehouse/ValidasiKepalaToko/Index";
// import ValidasiDirekturTransferEksternal from "views/pages/transferstokwarehouse/ValidasiDirektur/Index";
import AdjustmentPage from "views/pages/adjustment/Adjustment/Index";
// import ReceivingTransferPage from "views/pages/transferstokwarehouse/ReceivingTransfer/ReceivingTransfer/Index";
// import ValidasiKepalaGudangReceivingTe from "views/pages/transferstokwarehouse/ReceivingTransfer/ValidasiKepalaGudang/Index";
// import HistoriReceivingTransfer from "views/pages/transferstokwarehouse/ReceivingTransfer/CetakReceivingTransfer/Index";
// // import SuratJalanTransfers from "views/pages/transferstokwarehouse/SuratJalanTransfer/Index";
// import SuratJalanTransferEksternal from "views/pages/transferstokwarehouse/SuratJalanTransfer/SuratJalanTransfer/Index";
// import ValidasiKepalaGudangSuratJalanTransferEksternal from "views/pages/transferstokwarehouse/SuratJalanTransfer/ValidasiKepalaGudang/Index";
// import CetakSuratJalanTransferEksternal from "views/pages/transferstokwarehouse/SuratJalanTransfer/CetakSuratJalan/Index";
import PermintaanBarangPoCabang from "views/pages/po/PermintaanBarang/Index1";
import PermintaanBarangPage from "views/pages/po/PermintaanBarang/PermintaanBarang/Index";
import ValidasiKepalaPurchasingPermintaanBarang from "views/pages/po/PermintaanBarang/ValidasiPermintaanBarang/Index";
import HistoriPermintaanBarang from "views/pages/po/PermintaanBarang/CetakPermintaanBarang/Index";
import PermintaanBarangPusat from "views/pages/po/OrderPusat/PermintaanPo/Index2";
import ValidasiKepalaPurchasingPoPusat from "views/pages/po/OrderPusat/ValidasiAdminPo/Index";
import ValidasiDirekturPoPusat from "views/pages/po/OrderPusat/ValidasiDirektur/Index";
import ValidasiAdminPoPusat from "views/pages/po/OrderPusat/ValidasiPermintaanPo/Index";
import HistoriPoPusat from "views/pages/po/OrderPusat/Cetak/Index";
import ReceivingPoPage from "views/pages/po/ReceivingPo/ReceivingPo/Index";
import ValidasiKepalaGudangReceivingPo from "views/pages/po/ReceivingPo/ValidasiReceivingPo/Index";
import HistoriReceivingPo from "views/pages/po/ReceivingPo/CetakReceivingPo/Index";
import InvoicePOPage from "views/pages/po/InvoicePo/InvoicePo/Index";
import ValidasiKepalaFinanceInvoicePO from "views/pages/po/InvoicePo/ValidasiAdminFinance/Index";
import HistoryInvoicePo from "views/pages/po/InvoicePo/CetakInvoice/Index";
import ValidasiDirekturInvoicePO from "views/pages/po/InvoicePo/ValidasiPimpinan/Index";
import PoReturPoPage from "views/pages/po/PoRetur/PoRetur/Index";
import ValidasiKepalaPurchasingReturPo from "views/pages/po/PoRetur/ValidasiAdminToko/Index";
import ValidasiDirekturReturPo from "views/pages/po/PoRetur/ValidasiDirekturPoRetur/Index";
import HistoriReturPo from "views/pages/po/PoRetur/CetakPoRetur/Index";
import SuratJalanKasirPage from "views/pages/so/SuratJalanKasir/SuratJalanKasir/Index";
import ValidasiKepalaGudangSuratJalanKasir from "views/pages/so/SuratJalanKasir/ValidasiSuratJalanKasir/Index";
import HistoriSuratJalanKasir from "views/pages/so/SuratJalanKasir/CetakSuratJalanKasir/Index";
import PenawaranBarangProyekPage from "views/pages/so/PermintaanBarang/PermintaanBarang/Index";
import ValidasiManajerTokoPenawaranbarangProyek from "views/pages/so/PermintaanBarang/ValidasiPermintaanBarang/Index";
import HistoriPenawaranBarangProyek from "views/pages/so/PermintaanBarang/CetakPenawaranBarang/Index";
import SalesOrderPenjualanBarang from "views/pages/so/PenawaranSo/Index";
import ValidasiKepalaPurchasingSalesOrder from "views/pages/so/ValidasiSo/Index";
import HistoriSalesOrder from "views/pages/so/CetakSo/Index";
import SuratJalanSalesOrderPage from "views/pages/so/SuratJalanSo/SuratJalanSo/Index";
import ValidasiKepalaGudangSuratJalanSalesOrder from "views/pages/so/SuratJalanSo/ValidasiSuratJalanSo/Index";
import HistoriSuratJalanSalesOrder from "views/pages/so/SuratJalanSo/CetakSuratJalan/Index";
import InvoiceSalesOrderPage from "views/pages/so/InvoiceSo/InvoiceSo/Index";
import ValidasiKepalafinanceInvoiceSalesOrder from "views/pages/so/InvoiceSo/ValidasiInvoiceSo/Index";
import ValidasiDirekturInvoiceSalesOrder from "views/pages/so/InvoiceSo/ValidasiPemimpin/Index";
import HistoriInvoiceSalesOrder from "views/pages/so/InvoiceSo/CetakInvoice/Index";
import SoReturProyekPage from "views/pages/so/SoRetur/SoReturProyek/Index";
import ValidasiSoReturManajerOffice from "views/pages/so/SoRetur/ValidasiManajerOffice/Index";
import HistoriSoRetur from "views/pages/so/SoRetur/CetakReturSo/Index";
import SoReturKasirPage from "views/pages/so/SoReturKasir/SoReturKasir/Index";
import ValidasiKepalaTokoReturKasir from "views/pages/so/SoReturKasir/ValidasiKepalaToko/Index";
import ValidasiOfficeManagerReturKasir from "views/pages/so/SoReturKasir/ValidasiOfficeManager/Index";
import HistoriReturKasir from "views/pages/so/SoReturKasir/CetakReturKasir/Index";
import JadwalOpnamePage from "views/pages/Finance/JadwalOpname/JadwalOpname/Index";
import HistoriJadwalOpname from "views/pages/Finance/JadwalOpname/RiwayatOpname/Index";
import OpnamePage from "views/pages/opname/Opname/Index";
import HistoriOpname from "views/pages/opname/CetakOpname/Index";
import BBKPembelianPage from "views/pages/Finance/BBK/BBK/Index";
import ValidasiKepalaFinanceBBKPembelianPage from "views/pages/Finance/BBK/ValidasiKepalaFinance/Index";
import ValidasDirekturBBKPembelianPage from "views/pages/Finance/BBK/ValidasiDirektur/Index";
import BBMPenjualanPage from "views/pages/Finance/BBM/BBM/Index";
import ValidasiKepalaFinanceBbmPenjualan from "views/pages/Finance/BBM/ValidasiKepalaFinance/Index";
import ValidasiDirekturBbmFinancePage from "views/pages/Finance/BBM/ValidasiDirektur/Index";
import BKKFinancePage from "views/pages/financeBkk/financeBkk/Index";
import ValidasiKepalaFinanceBkkPembelianPage from "views/pages/financeBkk/ValidasiKepalaFinance/Index";
import ValidasiDirekturFinanceBkk from "views/pages/financeBkk/ValidasiDirektur/Index";
import BKMFinancePage from "views/pages/financeBkm/financeBkm/Index";
import ValidasiKepalaFinanceBkmFinancePage from "views/pages/financeBkm/ValidasiKepalaToko/Index";
import ValidasiDirekturBkmFinancePage from "views/pages/financeBkm/ValidasiDirektur/Index";
import BMReturBeliPage from "views/pages/BkmReturPo/BkmReturPo/Index";
import ValidasiKepalaFinanceBMReturBeli from "views/pages/BkmReturPo/ValidasiKepalaFinance/Index";
import ValidasiDirekturBMReturBeli from "views/pages/BkmReturPo/ValidasiDirektur/Index";
import BkReturJualPage from "views/pages/BkkReturSo/BkkReturSo/Index";
import ValidasiKepalaFinanceBkReturJual from "views/pages/BkkReturSo/ValidasiKepalaFinance/Index";
import ValidasiDirekturBkReturJual from "views/pages/BkkReturSo/ValidasiDirektur/Index";
import LaporanMenu from "views/pages/Laporan/Index";
import PageMaster from "views/pages/Laporan/PageMaster/Index";
import PagePembelian from "views/pages/Laporan/PagePembelian/Index";
import PagePenjualan from "views/pages/Laporan/PagePenjualan/Index";
import PageTracking from "views/pages/Laporan/PageTracking/Index";
import PageHrd from "views/pages/Laporan/PageHrd/Index";
import Pagekaryawan from "views/pages/Laporan/PageKaryawan/Index";
import PageFinance from "views/pages/Laporan/PageFinance/Index";
import PageEcommerce from "views/pages/Laporan/PageEcommerce/Index";
import PageInventori from "views/pages/Laporan/PageInventori/Index";
import PageLaporanPembelian from "views/pages/Laporan/PagePembelian/PageLaporanPembelian/Index";
import PageLaporanCabang from "views/pages/Laporan/PageMaster/PageLaporanCabang/Index";
import PageLaporanPenjualan from "views/pages/Laporan/PagePenjualan/PageLaporanPenjualan/Index";
import PageLaporanCanvaser from "views/pages/Laporan/PageTracking/PageLaporanCanvaser/Index";
import PageLaporanStok from "views/pages/Laporan/PageInventori/PageLaporanStok.js/Index";
import PageLaporanKaryawanHrd from "views/pages/Laporan/PageHrd/PageLaporanKaryawanHrd/Index";
import PageLaporanAbsen from "views/pages/Laporan/PageKaryawan/PageLaporanAbsen/Index";
import PageLaporanBuktiKasMasuk from "views/pages/Laporan/PageFinance/PageLaporanBuktiKasMasuk/Index";
import PageLaporanKurirEcommerce from "views/pages/Laporan/PageEcommerce/PageLaporanKurir/Index";
import PageLaporanRfqPembelian from "views/pages/Laporan/PagePembelian/PageLaporanRfq/Index";
import PageLaporanBPBPembelian from "views/pages/Laporan/PagePembelian/PageLaporanBPBPembelian/Index";
import PageLaporanFakturPembelian from "views/pages/Laporan/PagePembelian/PageLaporanFakturPembelian/Index";
import PageLaporanPenawaranPenjualan from "views/pages/Laporan/PagePenjualan/PageLaporanPenawaranPenjualan/Index";
import PageLaporanPengirimanSj from "views/pages/Laporan/PagePenjualan/PageLaporanPengirimanSJ/Index";
import PageLaporanFakturPenjualan from "views/pages/Laporan/PagePenjualan/PageLaporanFakturJual/Index";
import PageLaporanTransferCabang from "views/pages/Laporan/PageInventori/PageLaporanTransferCabang/Index";
import PageLaporanTransferSjCabang from "views/pages/Laporan/PageInventori/PageLaporanTransferSj/Index";
import PageLaporanBpbTransferCabang from "views/pages/Laporan/PageInventori/PageLaporanBPBTransferCabang/Index";
import PageLaporanTransferInternal from "views/pages/Laporan/PageInventori/PageLaporanTransferInternal/Index";
import PageLaporanReturBeli from "views/pages/Laporan/PagePembelian/PageLaporanReturBeli/Index";
import PageLaporanReturJual from "views/pages/Laporan/PagePenjualan/PageLaporanReturJual/Index";
import PageLaporanSupplier from "views/pages/Laporan/PageMaster/PageLaporanSupplier/Index";
import PageLaporanCustomer from "views/pages/Laporan/PageMaster/PageLaporanCustomer/Index";
import PageLaporanPlanogram from "views/pages/Laporan/PageMaster/PageLaporanPlanogram/Index";
import PageLaporanAbsensiKaryawan from "views/pages/Laporan/PageHrd/PageLaporanAbsensiKaryawan/Index";
import PageLaporanKasirNota from "views/pages/Laporan/PagePenjualan/PageLaporanKasirNota/Index";
import PageLaporanKasirSettlement from "views/pages/Laporan/PagePenjualan/PageLaporanKasirSettlement/Index";
import PageLaporanKasirSuratJalan from "views/pages/Laporan/PagePenjualan/PageLaporanKasirSuratJalan/Index";
import PageLaporanKasirRetur from "views/pages/Laporan/PagePenjualan/PageLaporanKasirRetur/Index";
import MigrasiData from "views/pages/MigrasiData/Index";
import PageLaporanMigrasiDataGambar from "views/pages/MigrasiData/MigrasiDataGambar/Index";
import EditBkkPembelian from "views/pages/financeBkk/financeBkk/Edit";
import HistoriCetakBkk from "views/pages/financeBkk/Cetak/Index";
import DetailCetakFinanceBkk from "views/pages/financeBkk/Cetak/Detail";
import EditFinanceBkm from "views/pages/financeBkm/financeBkm/Edit";
import HistoriCetakBkm from "views/pages/financeBkm/Cetak/Index";
import DetailCetakFinanceBkm from "views/pages/financeBkm/Cetak/Detail";
import EditFinanceBbk from "views/pages/Finance/BBK/BBK/Edit";
import HistoriCetakBbk from "views/pages/Finance/BBK/Cetak/Index";
import DetailBBK from "views/pages/Finance/BBK/Cetak/Detail";
import EditFinanceBbm from "views/pages/Finance/BBM/BBM/Edit";
import HistoriCetakBbm from "views/pages/Finance/BBM/Cetak/Index";
import DetailBBMFinance from "views/pages/Finance/BBM/Cetak/Detail";
import BMReturPage from "views/pages/BkmReturPo/IndexBM";
import KasBMReturPage from "views/pages/BkmReturPo/Kas/Index";
import CreateFinanceBkmRetur from "views/pages/BkmReturPo/Kas/BkmRetur/Create";
import BKMReturPage from "views/pages/BkmReturPo/Kas/BkmRetur/Index";
import EditBkmReturPembelian from "views/pages/BkmReturPo/Kas/BkmRetur/Edit";
import BkmReturValidasiKepalaFinancePembelianPage from "views/pages/BkmReturPo/Kas/ValidasiKepalaFinance/Index";
import ValidasiBkmReturKepalaFinance from "views/pages/BkmReturPo/Kas/ValidasiKepalaFinance/Edit";
import BkmReturValidasDirekturFinancePage from "views/pages/BkmReturPo/Kas/ValidasiDirektur/Index";
import BkmReturValidasiDirekturFinance from "views/pages/BkmReturPo/Kas/ValidasiDirektur/Edit";
import HistoriCetakBkmRetur from "views/pages/BkmReturPo/Kas/Cetak/Index";
import DetailBkmReturFinance from "views/pages/BkmReturPo/Kas/Cetak/Detail";
import BankBMReturPage from "views/pages/BkmReturPo/Bank/Index";
import BBMReturPembelianPage from "views/pages/BkmReturPo/Bank/BbmRetur/Index";
import CreateFinanceBbmRetur from "views/pages/BkmReturPo/Bank/BbmRetur/Create";
import EditFinanceBbmRetur from "views/pages/BkmReturPo/Bank/BbmRetur/Edit";
import ValidasiKepalaFinanceBBMReturPembelianPage from "views/pages/BkmReturPo/Bank/ValidasiKepalaFinance/Index";
import ValidasiBbmReturFinance from "views/pages/BkmReturPo/Bank/ValidasiKepalaFinance/Edit";
import ValidasDirekturBBMReturPembelianPage from "views/pages/BkmReturPo/Bank/ValidasiDirektur/Index";
import ValidasiDirekturFinanceBbmRetur from "views/pages/BkmReturPo/Bank/ValidasiDirektur/Edit";
import HistoriCetakBbmRetur from "views/pages/BkmReturPo/Bank/Cetak/Index";
import DetailFinanceBbmRetur from "views/pages/BkmReturPo/Bank/Cetak/Detail";
import BKReturPage from "views/pages/BkkReturSo/IndexBK";
import BKReturMenuKas from "views/pages/BkkReturSo/Kas/Index";
import BKKReturFinancePage from "views/pages/BkkReturSo/Kas/BKRetur/Index";
import CreateFinanceBkkRetur from "views/pages/BkkReturSo/Kas/BKRetur/Create";
import EditFinanceBkkRetur from "views/pages/BkkReturSo/Kas/BKRetur/Edit";
import ValidasiKepalaFinanceBkkReturFinancePage from "views/pages/BkkReturSo/Kas/ValidasiKepalaFinance/Index";
import ValidasiBkkReturFinance from "views/pages/BkkReturSo/Kas/ValidasiKepalaFinance/Edit";
import ValidasiDirekturBKkReturFinance from "views/pages/BkkReturSo/Kas/ValidasiDirektur/Edit";
import ValidasiDirekturBkkReturFinancePage from "views/pages/BkkReturSo/Kas/ValidasiDirektur/Index";
import HistoriCetakBkkRetur from "views/pages/BkkReturSo/Kas/Cetak/Index";
import DetailBKkReturFinance from "views/pages/BkkReturSo/Kas/Cetak/Detail";
import BKReturMenuBank from "views/pages/BkkReturSo/Bank/Index";
import CreateFinanceBBKRetur from "views/pages/BkkReturSo/Bank/BBKRetur/Create";
import BBKReturFinancePage from "views/pages/BkkReturSo/Bank/BBKRetur/Index";
import EditFinanceBbkRetur from "views/pages/BkkReturSo/Bank/BBKRetur/Edit";
import ValidasiBbkReturFinance from "views/pages/BkkReturSo/Bank/ValidasiKepalaFinance/Edit";
import ValidasiKepalaFinanceBbkReturFinancePage from "views/pages/BkkReturSo/Bank/ValidasiKepalaFinance/Index";
import ValidasiDirekturBbkReturFinancePage from "views/pages/BkkReturSo/Bank/ValidasiDirektur/Index";
import ValidasiDirekturBBkReturFinance from "views/pages/BkkReturSo/Bank/ValidasiDirektur/Edit";
import HistoriCetakBbkRetur from "views/pages/BkkReturSo/Bank/Cetak/Index";
import DetailBBkReturFinance from "views/pages/BkkReturSo/Bank/Cetak/Detail";
import DetailHrdIzin from "views/pages/Hrd/Izin/RiwayatIzin/Detail";
import DetailPOCabang from "views/pages/po/OrderPusat/Cetak/Detail";
import DetailInvocePOCUAKSSSS from "views/pages/po/InvoicePo/CetakInvoice/DetailInvoice";
import DetailReturPO from "views/pages/po/PoRetur/CetakPoRetur/Detail";
import DetailSalesOrder from "views/pages/so/CetakSo/Detail";
import DetailSuratJalanSOOOOO from "views/pages/so/SuratJalanSo/CetakSuratJalan/Detail";
import DetailInvoiceSOO from "views/pages/so/InvoiceSo/CetakInvoice/Detail";
import DetailReturSo from "views/pages/so/SoRetur/CetakReturSo/Detail";
import DetailRFQSO from "views/pages/so/PermintaanBarang/CetakPenawaranBarang/Detail";
import IndexDinasHrd from "views/pages/Hrd/Dinas/Index";
import ValidasiHrdIzinDinasValidasi from "views/pages/Hrd/Dinas/RiwayatDinas/Edit";
import DetailHrdIzinDinas from "views/pages/Hrd/Dinas/Histori/Detail";
import SalesOrderPenjualanBarangPusattttt from "views/pages/so/SalesOrderPusat/Index";
import CreateSalesOrderPusat from "views/pages/so/SalesOrderPusat/Create";
import EditSalesOrderPusat from "views/pages/so/SalesOrderPusat/Edit";
import DetailSalesOrderPusat from "views/pages/so/SalesOrderPusat/Detail";
import AccountFinance from "views/pages/Finance/Account/Index";
import DetailReturKasirCok from "views/pages/so/SoReturKasir/CetakReturKasir/Detail";
import DetailAdjusmentHistori from "views/pages/adjustment/CetakAdjustment/Detail";
import HistoryKasir from "views/pages/so/Kasir/history"
// import DetailTransferReceiving from "views/pages/transferstokwarehouse/ReceivingTransfer/CetakReceivingTransfer/Detail";
// import DetailSuratJalanTransferWid from "views/pages/transferstokwarehouse/SuratJalanTransfer/CetakSuratJalan/Detail";
import DetailTransferInternal from "views/pages/transferRak/Detail";
import DetailRFQCabang from "views/pages/po/PermintaanBarang/CetakPermintaanBarang/Detail";
import DetailJadwalOpname from "views/pages/Finance/JadwalOpname/RiwayatOpname/Detail";
import EditPasswordProfileUser from "views/pages/user/NewPassword";
import UpdateGambarCabang from "views/pages/warehousePusat/EditGambar";
import EditGambarRak from "views/pages/Rak/EditGambar";
import EditGambarKaryawan from "views/pages/karyawanbaru/EditGambar";
import EditGambarItem from "views/pages/item/itemMain/EditGambar";
import DetailOpnameHistori from "views/pages/opname/CetakOpname/Detail";
import CreateItemRakCok from "views/pages/Rak/CreateItemRak";
import CreatePermintaanbarangKasir from "views/pages/po/PermintaanBarang/PermintaanBarang/CreateRfqKasir";
import CekBarcode from "views/pages/so/CekBarcode/Index";
import UangMukaPembelian from "views/pages/po/InvoicePo/Uangmuka/Index";
import UangMukaPage from "views/pages/po/InvoicePo/Uangmuka/Uangmuka/Index";
import CreateUangMukaPembelian from "views/pages/po/InvoicePo/Uangmuka/Uangmuka/Create";
import EditUangMukaPembelian from "views/pages/po/InvoicePo/Uangmuka/Uangmuka/Edit";
import ValidasiUangMukaPage from "views/pages/po/InvoicePo/Uangmuka/ValidasiUangMuka/Index";
import ValidasiUangMukaPembelian from "views/pages/po/InvoicePo/Uangmuka/ValidasiUangMuka/Edit";
import UangMukaPenjualanPage from "views/pages/so/InvoiceSo/UangMukaPenjualan/Index";
import EditUangMukaPenjualan from "views/pages/so/InvoiceSo/UangMukaPenjualan/Edit";
import CreateUangMukaPenjualan from "views/pages/so/InvoiceSo/UangMukaPenjualan/Create";
import ValidasiUangMukaPenjualan from "views/pages/so/InvoiceSo/UangMukaPenjualan/Validasi";
// import BkkReturSo from "views/pages/BkkReturSo/Index";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
    hidden: true,
  },
  {
    icon: "ni ni-chart-bar-32 text-black",
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/admin",
    // hidden: true,
  },
  {
    collapse: true,
    name: "Master",
    icon: "ni ni-folder-17 text-yellow",
    state: "MasterCollapse",
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      {
        path: "/warehouse/gudang-toko/create",
        name: "Create Warehouse Pusat",
        miniName: "",
        component: CreateGudangToko,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/warehouse/toko/create",
        name: "Create Warehouse Pusat",
        miniName: "",
        component: CreateToko,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/warehouse/region/create",
        name: "Create Warehouse Pusat",
        miniName: "",
        component: CreateRegion,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/warehouse/pusat/create",
        name: "Create Warehouse Pusat",
        miniName: "",
        component: CreateWarehousePusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/warehouse/edit/gambar/:id",
        name: "Edit Warehouse Pusat",
        miniName: "",
        component: UpdateGambarCabang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/warehouse/edit/:id",
        name: "Edit Warehouse Pusat",
        miniName: "",
        component: EditWarehouseaAll,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/warehouse",
        name: "Cabang",
        miniName: "",
        component: WarehousePusat,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/tipe-rak/edit/:id",
        name: "Create Rak",
        miniName: "",
        component: EditTypeRak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/tipe-rak/create",
        name: "Create Rak",
        miniName: "",
        component: CreateTipeRak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/tipe-rak",
        name: "Tipe Rak",
        miniName: "",
        component: TipeRak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/rak/item-rak/:id",
        name: "RakItemCok",
        miniName: "",
        component: CreateItemRakCok,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/rak/edit/gambar/:id",
        name: "Edit Warehouse Pusat",
        miniName: "",
        component: EditGambarRak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/rak/edit/:id",
        name: "Create Rak",
        miniName: "",
        component: EditRak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/rak/create",
        name: "Create Rak",
        miniName: "",
        component: CreateRak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/rak",
        name: "Planogram",
        miniName: "",
        component: Rak,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/durasi-operasional/edit/:id",
        name: "Edit Durasi Operasional",
        miniName: "",
        component: EditDurasiOperasional,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/durasi-operasional/create",
        name: "Create Durasi Operasional",
        miniName: "",
        component: CreateDurasiOperasional,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/durasi-operasional",
        name: "Batas Operasional",
        miniName: "",
        component: DurasiOperasional,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/password-operasional/edit/:id",
        name: "Edit Durasi Operasional",
        miniName: "",
        component: EditPasswordOperasional,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/password-operasional",
        name: "Password Otoritas",
        miniName: "",
        component: PasswordOperasional,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pajak/edit/:id",
        name: "Edit Pajak",
        miniName: "",
        component: EditPajak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pajak/create",
        name: "Create Pajak",
        miniName: "",
        component: CreatePajak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pajak",
        name: "PPN",
        miniName: "",
        component: Pajak,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/jangka-waktu/update/:id",
        name: "Update Jangka Waktu",
        miniName: "",
        component: "",
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/jangka-waktu/edit/:id",
        name: "Edit Jangka Waktu",
        miniName: "",
        component: EditJangkaWaktu,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/jangka-waktu/create",
        name: "Create Jangka Waktu",
        miniName: "",
        component: CreateJangkaWaktu,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/jangka-waktu",
        name: "Jatuh Tempo",
        miniName: "",
        component: MainJangka,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },     
      {
        path: "/user/edit/:id",
        name: "Edit User",
        miniName: "",
        component: EditUser,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/privileges/edit",
        name: "Edit Privileges",
        miniName: "",
        component: EditPrivileges,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/user/new-password/:id",
        name: "Edit Password User",
        miniName: "",
        component: EditPasswordProfileUser,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/user/create",
        name: "Create User",
        miniName: "",
        component: CreateUser,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/user",
        name: "Akses User",
        miniName: "",
        component: Users,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/alamat/edit/:id",
        name: "Edit Alamat",
        miniName: "",
        component: EditAlamat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/alamat/create",
        name: "Create Address",
        miniName: "",
        component: CreateAlamat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/alamat",
        name: "Alamat",
        miniName: "",
        component: Alamat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/person/edit/:id",
        name: "Edit Person",
        miniName: "",
        component: EditPerson,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/person/create",
        name: "Create Person",
        miniName: "",
        component: CreatePerson,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/person",
        name: "Supplier",
        miniName: "",
                component: Person,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/customer/edit/:id",
        name: "Edit Customer",
        miniName: "",
        component: EditCustomer,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/customer/create",
        name: "Create Customer",
        miniName: "",
        component: CreateCustomer,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/customer",
        name: "Customer",
        miniName: "",
        component: Customer,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/satuan/edit/:id",
        name: "Edit Satuan",
        miniName: "",
        component: EditUom,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/satuan/create",
        name: "Create Satuan",
        miniName: "",
        component: CreateUom,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/satuan",
        name: "Satuan",
        miniName: "",
                component: Uom,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-grade/create",
        name: "Create Item Grade",
        miniName: "",
        component: CreateItemGrade,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-grade",
        name: "Item Grade",
        miniName: "",
        component: ItemGrade,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-sub-merek/create",
        name: "Create Item Sub Merek",
        miniName: "",
                component: CreateItemSubMerek,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-sub-merek",
        name: "Item Sub Merek",
        miniName: "",
        component: ItemSubMerek,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-merek/create",
        name: "Create Item Merek",
        miniName: "",
        component: CreateItemMerek,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-merek",
        name: "Item Merek",
        miniName: "",
        component: ItemMerek,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-sub-function/create",
        name: "Create Item Sub Function",
        miniName: "",
        component: CreateItemSubFunction,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-sub-function",
        name: "Item Sub Function",
        miniName: "",
        component: ItemSubFunction,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-function/create",
        name: "Create Item Function",
        miniName: "",
        component: CreateItemFunction,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-function",
        name: "Item Function",
        miniName: "",
        component: ItemFunction,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-sub-kategori/create",
        name: "Create Sub Kategori",
        miniName: "",
        component: CreateItemSubCategory,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-sub-kategori",
        name: "Item Sub Kategori",
        miniName: "",
        component: ItemSubCategory,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-kategori/edit/:id",
        name: "Edit Item Kategori",
        miniName: "",
        component: EditItemCategory,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-kategori/create",
        name: "Create Kategori",
        miniName: "",
        component: CreateItemCategory,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item-kategori",
        name: "Item Kategori",
        miniName: "",
        component: ItemCategory,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item/edit/gambar/:id",
        name: "Edit Item",
        miniName: "",
        component: EditGambarItem,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item/edit/:id",
        name: "Edit Item",
        miniName: "",
        component: EditItem,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item/details/:id",
        name: "Detail Item",
        miniName: "",
        component: DetailItems,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item/region-detail/edit-harga/itm/:id/cbg/:itemid",
        name: "EditHargaPerToko",
        miniName: "",
        component: EditHargaPerToko,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item/region-detail/kartu-stok/itm/:id/cbg/:itemid",
        name: "LogPriceKartuStok",
        miniName: "",
        component: LogPriceKartuStok,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item/region-detail/:id/Ab8jYc4ZbE0kaAGX0nHrJca8xH73eKcj09/:itemid",
        name: "Detail Item",
        miniName: "",
        component: DetailRegion,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/produksi-item/create",
        name: "Create Item Produksi",
        miniName: "",
        component: CreateItemProduksi,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item/create",
        name: "Create Item",
        miniName: "",
        component: CreateItem,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/item",
        name: "Item Pusat",
        miniName: "",
        component: ItemMain,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/region-item", 
        name: "Item Region",
        miniName: "",
        component: ItemCabang,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/toko-item",
        name: "Item Toko",
        miniName: "",
        component: ItemToko,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-grade/create",
        name: "Create Item Grade",
        miniName: "",
        component: CreateItemGrades,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-grade",
        name: "Item Grade",
        miniName: "",
        component: ItemGrades,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-sub-merek/create",
        name: "Create Item Sub Merek",
        miniName: "",
                component: CreateItemSubMereks,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-sub-merek",
        name: "Item Sub Merek",
        miniName: "",
        component: ItemSubMereks,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-merek/create",
        name: "Create Item Merek",
        miniName: "",
        component: CreateItemMereks,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-merek",
        name: "Item Merek",
        miniName: "",
        component: ItemMereks,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-sub-function/create",
        name: "Create Item Sub Function",
        miniName: "",
        component: CreateItemSubFunctions,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-sub-function",
        name: "Item Sub Function",
        miniName: "",
        component: ItemSubFunctions,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-function/create",
        name: "Create Item Function",
        miniName: "",
        component: CreateItemFunctions,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-function",
        name: "Item Function",
        miniName: "",
        component: ItemFunctions,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-sub-kategori/create",
        name: "Create Sub Kategori",
        miniName: "",
        component: CreateItemSubCategorys,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-sub-kategori",
        name: "Item Sub Kategori",
        miniName: "",
        component: ItemSubCategorys,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-kategori/edit/:id",
        name: "Edit Item Kategori",
        miniName: "",
        component: EditItemCategorys,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-kategori/create",
        name: "Create Kategori",
        miniName: "",
        component: CreateItemCategorys,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item-kategori",
        name: "Item Kategori",
        miniName: "",
        component: ItemCategorys,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item/edit/:id",
        name: "Edit Item",
        miniName: "",
        component: EditItem,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item/details/:id",
        name: "Detail Item",
        miniName: "",
        component: DetailItems,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog/item/create",
        name: "Create Item",
        miniName: "",
        component: CreateItem,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/katalog",
        name: "Katalog",
        miniName: "",
        component: ItemKatalog,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/aplikator/create",
        name: "Create Item",
        miniName: "",
        component: CreateAplikator,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/aplikator",
        name: "Aplikator",
        miniName: "",
        component: Aplikator,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pembulatan/edit/:id",
        name: "Detail Item",
        miniName: "",
        component: EditPembulatan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pembulatan",
        name: "Pembualatan",
        miniName: "",
        component: Pembulatan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/edit/:id",
        name: "Edit Promo",
        miniName: "",
        component: EditPromo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/create-transaksi",
        name: "Create Promo",
        miniName: "",
        component: CreatePromo2,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/create-kategori",
        name: "Create Promo",
        miniName: "",
        component: CreatePromo1,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/create",
        name: "Create Promo",
        miniName: "",
        component: CreatePromo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/promo-bundling/edit/:id",
        name: "Create Promo",
        miniName: "",
        component: EditPromoBundling,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/promo-bundling/create",
        name: "Create Promo",
        miniName: "",
        component: CreatePromoBundling,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/promo-bundling",
        name: "Create Promo",
        miniName: "",
        component: PromoIndexBundling,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/promo-produk/edit/:id",
        name: "Create Promo",
        miniName: "",
        component: EditPromoProduk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/promo-produk/create",
        name: "Create Promo",
        miniName: "",
        component: CreatePromoProduk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/promo-produk",
        name: "Create Promo",
        miniName: "",
        component: PromoIndexProduk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/promo-budget/edit/:id",
        name: "Create Promo",
        miniName: "",
        component: EditPromoPerorangan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/promo-budget/create",
        name: "Create Promo",
        miniName: "",
        component: CreatePromoPerorangan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/promo-budget",
        name: "Create Promo",
        miniName: "",
        component: PromoIndexBudget,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/promo-global/edit/:id",
        name: "Edit Promo Global",
        miniName: "",
        component: EditPromoGlobal,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/promo-global/create",
        name: "Create Promo",
        miniName: "",
        component: CreatePromoGlobal,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo/promo-global",
        name: "Create Promo",
        miniName: "",
        component: IndexPromoGlobal,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/promo",
        name: "Promo",
        miniName: "",
        component: Promo,
        layout: "/admin",
        // hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
       
      {
        path: "/type-asset/edit/:id",
        name: "Edit Type Asset",
        miniName: "",
        component: EditTypeAsset,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/type-asset/create",
        name: "Create Type Asset",
        miniName: "",
        component: CreateTypeAsset,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/type-asset",
        name: "Type Asset",
        miniName: "",
                component: TypeAsset,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/asset/edit/:id",
        name: "Edit Asset",
        miniName: "",
        component: EditAsset,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/asset/create",
        name: "Create Asset",
        miniName: "",
        component: CreateAsset,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/asset",
        name: "Asset",
        miniName: "",
                component: Asset,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bank/edit/:id",
        name: "Edit Bank",
        miniName: "",
        component: EditBank,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bank/create",
        name: "Create Bank",
        miniName: "",
        component: CreateBank,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bank",
        name: "Bank",
        miniName: "",
        component: Bank,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/coa/edit/:id",
        name: "Edit COA",
        miniName: "",
        component: EditCoa,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/coa/create",
        name: "Create COA",
        miniName: "",
        component: CreateCoa,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/coa",
        name: "Coa",
        miniName: "",
                component: Coa,
        layout: "/admin",
        hidden: true, //hidden dulu bosku
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/account/edit/:id",
        name: "Edit Account",
        miniName: "",
        component: EditAccount,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/account/create",
        name: "Create Account",
        miniName: "",
        component: CreateAccount,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/account",
        name: "Akun",
        miniName: "",
        component: Account,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Inventory",
    icon: "ni ni-archive-2 text-orange",
    state: "inventoryCollapse",
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      {
        path: "/stock-item/kartu-stok/:id",
        name: "Kartu Stok",
        miniName: "",
        component: DetailKartuStok,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-item/semua-cabang/:id",
        name: "Kartu Stok",
        miniName: "",
        component: DetailItemStok,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-item/create",
        name: "Kartu Stok",
        miniName: "",
        component: CreateItemStok,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-item",
        name: "Stok",
        miniName: "",
        component: ItemStock,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-adjustment/cetak/:id",
        name: "validasi Adjustment",
        miniName: "",
        component: CetakAdjustment,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-adjustment/detail/:id",
        name: "validasi Adjustment",
        miniName: "",
        component: DetailAdjusmentHistori,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-adjustment/histori",
        name: "Create Adjustment",
        miniName: "",
        component: CetakAdjustmentPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-adjustment/validasi-direktur/:id",
        name: "validasi Adjustment",
        miniName: "",
        component: ValidasiAdjustment,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-adjustment/validasi-direktur",
        name: "Create Adjustment",
        miniName: "",
        component: ValidasiAdjustmentPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-adjustment/edit/:id",
        name: "Edit Adjustment",
        miniName: "",
        component: EditAdjustment,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-adjustment/create",
        name: "Create Adjustment",
        miniName: "",
        component: CreateAdjustment,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-adjustment/page",
        name: "Create Adjustment",
        miniName: "",
        component: AdjustmentPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-adjustment",
        name: "Adjustment",
        miniName: "",
        component: Adjustment,
        layout: "/admin",
        // hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/transfer-rak/detail/:id",
        name: "Transfer Internal",
        miniName: "",
        component: DetailTransferInternal,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/transfer-rak/create",
        name: "Transfer Internal",
        miniName: "",
        component: CreateTransferRak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/transfer-rak",
        name: "Transfer Internal",
        miniName: "",
        component: TransferRak,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      // {
      //   path: "/transfer-warehouse-stock/cetak/:id",
      //   name: "Create Transfer Stock Warehouse",
      //   miniName: "",
      //   component: CetakTransferStokWarehouse,
      //   layout: "/cetak",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-warehouse-stock/detail/:id",
      //   name: "Create Transfer Stock Warehouse",
      //   miniName: "",
      //   component: DetailTransferStockWarehouse,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-warehouse-stock/history",
      //   name: "Create Transfer Stock Warehouse",
      //   miniName: "",
      //   component: CetakTransferStokWarehouse,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-warehouse-stock/validasi-direktur/validasi/:id",
      //   name: "Validasi Transfer Stok Warehouse Kepala Toko",
      //   miniName: "",
      //   component: ValidasiDirekturTSW,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-warehouse-stock/validasi-direktur",
      //   name: "Create Transfer Stock Warehouse",
      //   miniName: "",
      //   component: ValidasiDirekturTransferEksternal,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-warehouse-stock/validasi-kepala-toko/validasi/:id",
      //   name: "Validasi Transfer Stok Warehouse Kepala Toko",
      //   miniName: "",
      //   component: ValidasiKepalaTokoTSW,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-warehouse-stock/validasi-manager",
      //   name: "Create Transfer Stock Warehouse",
      //   miniName: "",
      //   component: ValidasiKepalaTokoTransferEksternal,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-warehouse-stock/edit/:id",
      //   name: "Create Transfer Stock Warehouse",
      //   miniName: "",
      //   component: EditTransferStockWarehouse,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-warehouse-stock/create",
      //   name: "Create Transfer Stock Warehouse",
      //   miniName: "",
      //   component: CreateTransferStockWarehouse,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-warehouse-stock/transfer-eksternal",
      //   name: "Create Transfer Stock Warehouse",
      //   miniName: "",
      //   component: TransferStokWarehouse,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // // hidden app
      // {
      //   path: "/transfer-warehouse-stock",
      //   name: "Transfer Eksternal",
      //   miniName: "",
      //   component: TransferStokWarehousePages,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/receiving-transfer/cetak/:id",
      //   name: "Create Receiving Transfer",
      //   miniName: "",
      //   component: CetakReceivingTransfer,
      //   layout: "/cetak",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/receiving-transfer/detail/:id",
      //   name: "Receiving TE",
      //   miniName: "",
      //   component: DetailTransferReceiving,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/receiving-transfer/histori",
      //   name: "Receiving TE",
      //   miniName: "",
      //   component: HistoriReceivingTransfer,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/receiving-transfer/validasi-kepala-gudang/validasi/:id",
      //   name: "Create Receiving Transfer",
      //   miniName: "",
      //   component: ValidasiKepalaGudangR,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/receiving-transfer/cetak-cetak/:id",
      //   name: "Create Receiving Transfer",
      //   miniName: "",
      //   component: CetakReceivingTransfers,
      //   layout: "/cetak",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/receiving-transfer/validasi-kepala-gudang",
      //   name: "Receiving TE",
      //   miniName: "",
      //   component: ValidasiKepalaGudangReceivingTe,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/receiving-transfer/edit/:id",
      //   name: "Create Receiving Transfer",
      //   miniName: "",
      //   component: EditReceivingTransfer,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/receiving-transfer/create",
      //   name: "Create Receiving Transfer",
      //   miniName: "",
      //   component: CreateReceivingTransfer,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/receiving-transfer/page",
      //   name: "Receiving TE",
      //   miniName: "",
      //   component: ReceivingTransferPage,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/receiving-transfer",
      //   name: "BPB Transfer Eksternal",
      //   miniName: "",
      //   component: ReceivingTransfer,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-surat-jalan/cetak/:id",
      //   name: "Transfer Surat Jalan",
      //   miniName: "",
      //   component: CetakSuratJalanTransfer,
      //   layout: "/cetak",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-surat-jalan/detail/:id",
      //   name: "Transfer Surat Jalan",
      //   miniName: "",
      //   component: DetailSuratJalanTransferWid,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-surat-jalan/histori",
      //   name: "Transfer Surat Jalan",
      //   miniName: "",
      //   component: CetakSuratJalanTransferEksternal,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-surat-jalan/validasi-kepala-gudang/validasi/:id",
      //   name: "Transfer Surat Jalan",
      //   miniName: "",
      //   component: ValidasiSuratJalanSuratJalanTransferStok,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-surat-jalan/validasi-kepala-gudang",
      //   name: "Transfer Surat Jalan",
      //   miniName: "",
      //   component: ValidasiKepalaGudangSuratJalanTransferEksternal,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-surat-jalan/edit/:id",
      //   name: "Transfer Surat Jalan",
      //   miniName: "",
      //   component: EditTransferSuratJalan,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-surat-jalan/create",
      //   name: "Transfer Surat Jalan",
      //   miniName: "",
      //   component: CreateTransferSuratJalan,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-surat-jalan/page",
      //   name: "Transfer Surat Jalan",
      //   miniName: "",
      //   component: SuratJalanTransferEksternal,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/transfer-surat-jalan",
      //   name: "Surat Jalan Transfer Eksternal",
      //   miniName: "",
      //   component: SuratJalanTransfer,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
     
    ],
  },
  {
    collapse: true,
    name: "Pembelian",
    icon: "ni ni-cart text-red",
    state: "POCollapse",
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      {
        path: "/rfq-pusat/detail/:id",
        name: "Detail Rfq Pusat",
        miniName: "",
        component: DetailRfqPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/rfq-pusat",
        name: "Permintaan Barang Cabang",
        miniName: "",
        component: RfqPusat,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/permintaan-barang/detail/:id",
        name: "Edit Validasi Permintaan Barang",
        miniName: "",
        component: DetailRFQCabang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/permintaan-barang/histori",
        name: "Penawaran",
        component: HistoriPermintaanBarang,
        layout: "/admin",
        hidden: true,
        miniName: "",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/validasi-permintaan-barang/edit/:id",
        name: "Edit Validasi Permintaan Barang",
        miniName: "",
        component: EditValidasiPermintaanBarang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/validasi-permintaan-barang",
        name: "Validasi Permintaan Barang",
        miniName: "",
        component: ValidasiPermintaanBarang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/permintaan-barang/validasi-kepala-purchasing",
        name: "Penawaran",
        component: ValidasiKepalaPurchasingPermintaanBarang,
        layout: "/admin",
        hidden: true,
        miniName: "",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/permintaan-barang/create-kasir",
        name: "Create Permintaan Barang",
        miniName: "",
        component: CreatePermintaanbarangKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/permintaan-barang/create",
        name: "Create Permintaan Barang",
        miniName: "",
        component: CreatePermintaanBarang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/permintaan-barang/edit/:id",
        name: "Permintaan Barang Edit",
        miniName: "",
        component: EditPermintaanBarang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/permintaan-barang/page",
        name: "Penawaran",
        component: PermintaanBarangPage,
        layout: "/admin",
        hidden: true,
        miniName: "",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/permintaan-barang",
        name: "Permintaan Barang",
        miniName: "",
        component: PermintaanBarangPoCabang,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/purchase-order/detail/:id",
        name: "Create Penawaran Po",
        miniName: "",
            component: DetailPOCabang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/purchase-order/histori",
        name: "Create Penawaran Po",
        miniName: "",
            component: HistoriPoPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/purchase-order/validasi-pimpinan-po/edit/:id",
        name: "Validasi Pimpinan Edit",
        miniName: "",
        component: EditValidatorPoPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/purchase-order/validasi-direktur",
        name: "Create Penawaran Po",
        miniName: "",
            component: ValidasiDirekturPoPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/purchase-order/validasi-admin-approve-po/edit/:id",
        name: "Validasi Penawaran Po",
        miniName: "",
        component: EditAdminPoPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/purchase-order/validasi-kepala-purchasing",
        name: "Create Penawaran Po",
        miniName: "",
            component: ValidasiKepalaPurchasingPoPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/purchase-order/validasi-penawaran-po/edit/:id",
        name: "Validasi Penawaran Po",
        miniName: "",
        component: EditValidasiPoPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/purchase-order/validasi-admin-purchasing",
        name: "Create Penawaran Po",
        miniName: "",
            component: ValidasiAdminPoPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/purchase-order/edit/:id",
        name: "Edit Penawaran Po",
        miniName: "",
            component: EditPenawaranPoPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/purchase-order/create",
        name: "Create Penawaran Po",
        miniName: "",
            component: CreatePenawaranPoPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/purchase-order/page",
        name: "Create Penawaran Po",
        miniName: "",
            component: PermintaanBarangPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/purchase-order",
        name: "Pembelian Barang Cabang",
        miniName: "",
        component: OrderPusat,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/purchase-order/detail-po/:id",
        name: "Cetak So",
        miniName: "",
        component: DetailPO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/purchase-order/cetak/:id",
        name: "Cetak So",
        miniName: "",
        component: CetakPO,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/purchase-order/validasi-pimpinan-po/edit/:id",
        name: "Validasi Pimpinan Edit",
        miniName: "",
        component: EditValidatorPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/purchase-order/validasi-pimpinan-po",
        name: "Validasi Pimpinan",
        miniName: "",
        component: ValidatorPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/purchase-order/validasi-admin-approve-po/edit/:id",
        name: "Validasi Penawaran Po",
        miniName: "",
        component: EditAdminApprove,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/purchase-order/validasi-admin-approve-po",
        name: "Admin Approve",
        miniName: "",
          component: AdminApprove,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/purchase-order/validasi-penawaran-po/edit/:id",
        name: "Validasi Penawaran Po",
        miniName: "",
            component: EditValidasiPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/purchase-order/validasi-penawaran-po",
        name: "Validasi Penawaran Po",
        miniName: "",
          component: ValidasiPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/purchase-order/detail/:id",
        name: "Edit Penawaran Po",
        miniName: "",
            component: DetailPenawaranPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/purchase-order/edit/:id",
        name: "Edit Penawaran Po",
        miniName: "",
            component: EditPenawaranPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/purchase-order/create",
        name: "Create Penawaran Po",
        miniName: "",
            component: CreatePenawaranPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/purchase-order",
        name: "Pembelian Barang",
        miniName: "",
          component: PurchaseOrderCabang,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/memo-kurir-po/detail-memo/:id",
        name: "Detail Penawaran Po",
        miniName: "",
            component: DetailMemoCetak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/memo-kurir-po/cetak/:id",
        name: "Detail Penawaran Po",
        miniName: "",
            component: CetakMemoPo,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/memo-kurir-po/validasi-admin-po/:id",
        name: "Detail Penawaran Po",
            component: ValidasiAdminPoo,
            miniName: "",
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/memo-kurir-po/detail/:id",
        name: "Detail Penawaran Po",
            component: DetailMemoKurirPO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/memo-kurir-po/create",
        name: "Create Penawaran Po",
            component: CreateMemoKurirPO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/memo-kurir-po",
        name: "Memo Kurir",
            component: MemoKurirPO,
            miniName: "",
        layout: "/admin",
        hidden : true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/receiving-po/detail-receving/:id",
        name: "Validasi Receiving Po",
            component: DetailCetakReceivingPO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/receiving-po/cetak/:id",
        name: "Validasi Receiving Po",
            component: CetakReceivingPo,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/receiving-po/histori/detail/:id",
        name: "Create Receiving Po",
        component: DetailCetakReceivingPO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/receiving-po/histori",
        name: "Create Receiving Po",
        component: HistoriReceivingPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/receiving-po/validasi/:id",
        name: "Validasi Receiving Po",
            component: ValidasiReceivingPO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/receiving-po/validasi-kepala-gudang",
        name: "Create Receiving Po",
        component: ValidasiKepalaGudangReceivingPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/receiving-po/edit/:id",
        name: "Edit Receiving Po",
            component: EditReceivingPO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/receiving-po/cetak-receiving/:id",
        name: "Edit Receiving Po",
            component: CetakCetakReceivingPO,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/receiving-po/create",
        name: "Create Receiving Po",
            component: CreateReceivingPO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/receiving-po/page",
        name: "Create Receiving Po",
        component: ReceivingPoPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/receiving-po",
        name: "BPB Pembelian",
          component: ReceivingPO,
        layout: "/admin",
        miniName: "",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/uang-muka/pembelian/validasi-kepala-finance/validasi/:id",
        name: "Validasi uang muka page",
        component: ValidasiUangMukaPembelian,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/uang-muka/pembelian/validasi-kepala-finance",
        name: "Validasi uang muka page",
        component: ValidasiUangMukaPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/uang-muka/pembelian/create",
        name: "Create Uang Muka",
            component: CreateUangMukaPembelian,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/uang-muka/pembelian/edit/:id",
        name: "uang muka page",
        component: EditUangMukaPembelian,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/uang-muka/pembelian/page",
        name: "uang muka page",
        component: UangMukaPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/uang-muka/pembelian",
        name: "uang muka page",
        component: UangMukaPembelian,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po/bkk/:id",
        name: "Validasi Receiving Po",
        component: BkkInvoicePo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po/histori/detail/:id",
        name: "Edit Receiving Po",
        component: DetailInvocePOCUAKSSSS,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po/cetak/:id",
        name: "Edit Receiving Po",
        component: CetakInvoicePO,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po/histori",
        name: "Create Invoice Po",
            component: HistoryInvoicePo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po/validasi-direktur/validasi/:id",
        name: "Validasi Direktur Receiving Po",
        component: ValidasiPimpinan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po/validasi-direktur",
        name: "Create Invoice Po",
        component: ValidasiDirekturInvoicePO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po/validasi-admin-finance/validasi/:id",
        name: "Validasi Admin Finance Receiving Po",
        component: ValidasiAdminFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po/validasi-kepala-finance",
        name: "Create Invoice Po",
            component: ValidasiKepalaFinanceInvoicePO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po/detail/:id",
        name: "Edit Receiving Po",
            component: DetailInvoicePO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po/edit/:id",
        name: "Edit Receiving Po",
            component: EditInvoicePO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po/create",
        name: "Create Invoice Po",
            component: CreateInvoicePO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po/page",
        name: "Create Invoice Po",
            component: InvoicePOPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-po",
        name: "Faktur Pembelian",
        miniName: "",
          component: InvoicePO,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-keluar/detail-bkk/:id",
        name: "Validasi Direktur",
            component: DetailBkk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-keluar/cetak/:id",
        name: "Validasi Direktur",
            component: CetakBuktiKasKeluar,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-keluar/validasi-direktur/validasi/:id",
        name: "Validasi Direktur",
            component: ValidasiDirekturBkk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-keluar/validasi-kepala-finance/validasi/:id",
        name: "Validasi kepala finance",
            component: ValidasiAdminFinanceBkk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-keluar/detail/:id",
        name: "Detail Bukti kas Keluar",
        component: DetailBuktiKasKeluar,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-keluar/create",
        name: "Create bukti kas keluar",
            component: CreateBuktiKasKeluar,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-keluar",
        name: "Bukti Kas Keluar",
        miniName: "",
        component: BuktiKasKeluar,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/payment-bkm/:id",
        name: "Create Po Retur",
        component: CreatePoReturBuktiKasMasuk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/detail-po-retur/:id",
        name: "Create Po Retur",
        component: DetailPoReturCetak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/cetak/:id",
        name: "Create Po Retur",
        component: CetakReturPo,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/histori/detail/:id",
        name: "Create Po Retur",
        component: DetailReturPO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/histori",
        name: "Create Po Retur",
        component: HistoriReturPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/validasi-direktur-po/:id",
        name: "Create Po Retur",
        component: ValidasiDirekturPoRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/validasi-manajer-office",
        name: "Create Po Retur",
        component: ValidasiDirekturReturPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/validasi-manajer-po/:id",
        name: "Create Po Retur",
        component: ValidasiAdminTokoReturPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/validasi-kepala-purchasing",
        name: "Create Po Retur",
        component: ValidasiKepalaPurchasingReturPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/detail/:id",
        name: "Create Po Retur",
            component: DetailPoRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/edit/:id",
        name: "Create Po Retur",
        component: EditPoRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/create",
        name: "Create Po Retur",
        component: CreatePoRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur/page",
        name: "Po Retur",
        component: PoReturPoPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/po-retur",
        name: "Retur Pembelian",
        component: PoRetur,
        miniName: "",
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Penjualan",
    icon: "ni ni-cart text-red",
    // icon: "ni ni-archive-2 text-orange",
    state: "SOCollapse",
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      {
        path: "/pos/detail",
        name: "Detail Kasir Settlement",
          component: DetailKasirSettlement,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      // {
      //   path: "/pos/cetak",
      //   name: "Cetak",
      //     //   component: CetakSoKasir,
      //   layout: "/cetak",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      {
        path: "/pos/history/detail/:id",
        name: "History Kasir",
        component: DetailHistoryKasir,
        miniName: "",
        layout: "/admin",
        hidden : true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pos/history",
        name: "History Kasir",
        component: HistoryKasir,
        miniName: "",
        layout: "/admin",
        hidden : true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pos/so-kasir",
        name: "Kasir",
            component: SoKasir,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pos/modal",
        name: "Kasir Settlement",
          component: CreateKasirSettlement,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pos/",
        name: "Kasir",
          component: KasirSettlement,
          miniName: "",
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      
      {
        path: "/cek-barcode",
        name: "Cek Barcode",
        component: CekBarcode,
        hidden: true,
        miniName: "",
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/closing-kasir/detail/:id",
        name: "Create Po Retur",
            component: DetailClosingKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/closing-kasir",
        name: "Closing Kasir",
        component: ClosingKasir,
        miniName: "",
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pelunasan/edit/:id",
        name: "Create Po Retur",
            component: PelunasanEdit,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pelunasan",
        name: "Pelunasan Kasir",
          component: Pelunasan,
          miniName: "",
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-kasir/achiever/:id",
        name: "Edit Penawaran So",
        component: PenerimaBarang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-kasir/detail/:id",
        name: "Edit Penawaran So",
            component: SuratJalanDetailCetak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-kasir/cetak/:id",
        name: "Edit Penawaran So",
            component: CetakSuratJalanKasir,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-kasir/histori",
        name: "Create Penawaran So",
            component: HistoriSuratJalanKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/validasi-surat-jalan-kasir/validasi/:id",
        name: "Edit Penawaran So",
        component: ValidasiSuratJalanKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-kasir/validasi-kepala-gudang",
        name: "Create Penawaran So",
            component: ValidasiKepalaGudangSuratJalanKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-kasir/detail/:id",
        name: "Detail Penawaran So",
            component: DetailSuratJalanKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-kasir/cetak-sj/:id",
        name: "Edit Penawaran So",
            component: CetakCetakSuratJalanKasir,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-kasir/edit/:id",
        name: "Edit Penawaran So",
            component: EditSuratJalanKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-kasir/create",
        name: "Create Penawaran So",
            component: CreateSuratJalanKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-kasir/page",
        name: "Create Penawaran So",
            component: SuratJalanKasirPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-kasir",
        name: "Surat Jalan Kasir",
          component: SuratJalanKasir,
          miniName: "",
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur/payment/:id",
        name: "Validasi Retur Kepala Toko",
        component: CetakReturKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur/detail/:id",
        name: "Create Po Retur",
        component: DetailReturKasirCok,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur/histori",
        name: "Create Po Retur",
        component: HistoriReturKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur/validasi-direktur/:id",
        name: "Validasi Retur Kepala Toko",
        component: ValidasiDirekturReturKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur/validasi-direktur",
        name: "Create Po Retur",
        component: ValidasiDirekturReturKasir1,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur/validasi-office-manager/:id",
        name: "Validasi Retur Kepala Toko",
        component: ValidasiOfficeManager,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur/validasi-manajer-office",
        name: "Create Po Retur",
        component: ValidasiOfficeManagerReturKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur/validasi-kepala-toko/:id",
        name: "Validasi Retur Kepala Toko",
        component: ValidasiReturSoKepalaTokoBaru,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur/validasi-manajer-toko",
        name: "Create Po Retur",
        component: ValidasiKepalaTokoReturKasir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur/edit/:id",
        name: "Validasi Retur Kepala Toko",
        component: EditSoReturKasirBaru,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur/create",
        name: "Create Po Retur",
        component: CreateSoReturKasirBaru,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur/page",
        name: "Create Po Retur",
        component: SoReturKasirPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kasir-retur",
        name: "Retur Kasir",
        hidden: true,
        miniName: "",
        component: SoReturKasir,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penawaran-barang/cetak/:id",
        name: "Edit Validasi Pimpinan So",
        component: CetakPenawaranBarang,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },{
        path: "/penawaran-barang/histori/detail/:id",
        name: "Penawaran Barang",
        component: DetailRFQSO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penawaran-barang/histori",
        name: "Penawaran Barang",
        component: HistoriPenawaranBarangProyek,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penawaran-barang/so-validasi-penawaran-barang/edit/:id",
        name: "Edit Validasi Pimpinan So",
        component: EditValidasiPermintaanBarangSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penawaran-barang/so-validasi-penawaran-barang",
        name: " Validasi Pimpinan So",
        component: ValidasiPermintaanBarangSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penawaran-barang/validasi-manajer-toko",
        name: "Penawaran Barang",
        component: ValidasiManajerTokoPenawaranbarangProyek,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penawaran-barang/edit/:id",
        name: "Edit Validasi Pimpinan So",
        component: EditPermintaanBarangSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penawaran-barang/detail/:id",
        name: "Detail Penawaran Barang",
        component: DetailPermintaanBarangSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penawaran-barang/create",
        name: "Penawaran Barang",
        component: CreatePermintaanBarangSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penawaran-barang/page",
        name: "Penawaran Barang",
        component: PenawaranBarangProyekPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penawaran-barang",
        name: "Penawaran",
        component: PermintaanBarangSo,
        layout: "/admin",
        miniName: "",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/sales-order/detail/:id",
        name: "Edit Penawaran So",
        component: DetailSalesOrderPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/sales-order/edit/:id",
        name: "Edit Sales Order",

        component: EditSalesOrderPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      
      {
        path: "/pusat/sales-order/create",
        name: "Create Penawaran So",
        component: CreateSalesOrderPusat,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pusat/sales-order/page",
        name: "Penjualan Barang Pusat",
        component: SalesOrderPenjualanBarangPusattttt,
        layout: "/admin",
        miniName: "",
        // hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/histori/detail/:id",
        name: "Create Penawaran So",
        component: DetailSalesOrder,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/histori",
        name: "Create Penawaran So",
        component: HistoriSalesOrder,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/validasi-pimpinan-so/edit/:id",
        name: "Edit Validasi Pimpinan So",
              component: EditPimpinanSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/validas-pimpinan-so",
        name: "Validasi Pimpinan SO",
            component: PimpinanSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/validasi-admin-so/edit/:id",
        name: "Edit Validasi Admin So",
              component: EditAdminApproveSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/validasi-admin-so",
        name: "Validasi Admin SO",
            component: AdminApproveSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/validasi-penawaran-so/edit/:id",
        name: "Validasi Penawaran So",
            component: EditValidasiSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/validasi-penawaran-so",
        name: "Validasi Penawaran So",
            component: ValidasiSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/validasi-kepala-purchasing",
        name: "Create Penawaran So",
        component: ValidasiKepalaPurchasingSalesOrder,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/detail/:id",
        name: "Edit Sales Order",
            component: EditSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/edit/:id",
        name: "Edit Sales Order",
            component: EditSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/create",
        name: "Create Sales Order",
            component: CreateSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/so-penawaran/detail/:id",
        name: "Edit Penawaran So",
        component: DetailPenawaranSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/so-penawaran/edit/:id",
        name: "Edit Sales Order",

        component: EditPenawaranSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      
      {
        path: "/sales-order/so-penawaran/create",
        name: "Create Penawaran So",
        component: CreatePenawaranSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order/page",
        name: "Create Penawaran So",
        component: SalesOrderPenjualanBarang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-order",
        name: "Penjualan Barang",
        miniName: "",
        component: So,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-so/cetak/:id",
        name: "Edit Penawaran So",

        component: CetakSuratJalan,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-so/histori/detail/:id",
        name: "Create Penawaran So",
        component: DetailSuratJalanSOOOOO,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-so/histori",
        name: "Create Penawaran So",
        component: HistoriSuratJalanSalesOrder,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/validasi-surat-jalan-so/edit/:id",
        name: "Edit Penawaran So",

        component: ValidasiSuratJalanSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-so/validasi-kepala-gudang",
        name: "Create Penawaran So",
        component: ValidasiKepalaGudangSuratJalanSalesOrder,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-so/detail/:id",
        name: "Detail Penawaran So",

        component: DetailSuratJalanSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-so/cetak-sj/:id",
        name: "Edit Penawaran So",

        component: CetakCetakSuratJalanSo,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-so/edit/:id",
        name: "Edit Penawaran So",

        component: EditSuratJalanSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-so/create",
        name: "Create Penawaran So",

        component: CreateSuratJalanSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-so/page",
        name: "Create Penawaran So",
        component: SuratJalanSalesOrderPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-so",
        name: "Surat Jalan Penjualan",
        miniName: "",
        component: SuratJalanSo,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-so/bkm/:id",
        name: "Validasi Receiving Po",
        component: BkmInvoiceSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-so/cetak/:id",
        name: "Create Invoice So",

        component: CetakInvoiceSO,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-so/detail-invoice/:id",
        name: "Create Invoice So",

        component: DetailInvoiceSoS,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-so/histori/detail/:id",
        name: "Invoice",
        miniName: "",
        hidden: true,
        component: DetailInvoiceSOO,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-so/histori",
        name: "Invoice",
        miniName: "",
        hidden: true,
        component: HistoriInvoiceSalesOrder,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/validasi-direktur/edit/:id",
        name: "Create Invoice So",

        component: ValidasiDirektur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-so/validasi-direktur",
        name: "Invoice",
        miniName: "",
        component: ValidasiDirekturInvoiceSalesOrder,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/validasi-invoice-so/edit/:id",
        name: "Create Invoice So",

        component: Validasifinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-so/validasi-kepala-finance",
        name: "Invoice",
        miniName: "",
        component: ValidasiKepalafinanceInvoiceSalesOrder,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-so/detail/:id",
        name: "Create Invoice So",

        component: DetailInvoiceSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-so/edit/:id",
        name: "Create Invoice So",

        component: EditInvoiceSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-so/create",
        name: "Create Invoice So",

        component: CreateInvoiceSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-so/page",
        name: "Invoice",
        miniName: "",
        component: InvoiceSalesOrderPage,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/uang-muka/penjualan/validasi-kepala-finance/validasi/:id",
        name: "Validasi uang muka page",
        component: ValidasiUangMukaPenjualan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/uang-muka/penjualan/create",
        name: "Create Uang Muka",
            component: CreateUangMukaPenjualan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/uang-muka/penjualan/edit/:id",
        name: "uang muka page",
        component: EditUangMukaPenjualan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/uang-muka/penjualan/page",
        name: "uang muka page",
        component: UangMukaPenjualanPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-so",
        name: "Faktur Penjualan",
        miniName: "",
        component: InvoiceSo,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-masuk/cetak/:id",
        name: "Validasi Direktur",

        component: CetakBuktiKasMasuk,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-masuk/validasi-direktur/validasi/:id",
        name: "Validasi Direktur",

        component: ValidasiDirekturBkm,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-masuk/validasi-kepala-finance/validasi/:id",
        name: "Validasi kepala finance",

        component: ValidasiAdminFinanceBkm,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-masuk/detail/:id",
        name: "Detail Bukti kas Masuk",

        component: DetailBuktiKasMasuk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-masuk/create",
        name: "Create bukti kas keluar",
        component: CreateBuktiKasMasuk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-kas-masuk",
        name: "BKM Penjualan",
        miniName: "",
        component: BuktiKasMasuk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/payment/:id",
        name: "Validasi Retur Kepala Toko",
        component: ValidasiCetakRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/histori/detail/:id",
        name: "Create Po Retur",
        component: DetailReturSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/histori",
        name: "Create Po Retur",
        component: HistoriSoRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-direktur/:id",
        name: "Validasi Retur Kepala Toko",
        component: ValidasiDirekturSoRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-direktur",
        name: "Create Po Retur",
        component: ValidasiDirekturSoRetur1,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-manager-office/:id",
        name: "Validasi Retur Kepala Toko",
        component: SoReturValidasiManajerOffice,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-manajer-office",
        name: "Create Po Retur",
        component: ValidasiSoReturManajerOffice,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-kepala-toko/:id",
        name: "Validasi Retur Kepala Toko",
        component: ValidasiReturSoKepalaToko,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/edit/:id",
        name: "Validasi Retur Kepala Toko",
        component: EditSoKasurProyek,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/proyek/create",
        name: "Create Po Retur",
        component: CreateSoReturProyek,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/page",
        name: "Create Po Retur",
        component: SoReturProyekPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur",
        name: "Retur Penjualan",
        miniName: "",
        component: SoRetur,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      }
    ],
  },
  {
    collapse: true,
    name: "Tracking",
    icon: "ni ni-square-pin text-blue",
    state: "TrackingCollapse",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      {
        path: "/history-sales-canvasing",
        name: "Dokument Canvaser",
        miniName: "",
        component: HistorysalesCanvasing,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/sales-canvasing",
        name: "Canvaser",
        component: salesCanvasing,
        layout: "/admin",
        miniName: "",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      // {
      //   path: "/sales-tracking",
      //   name: "Kurir",
      //   component: SalesTarcking,
      //   layout: "/admin",
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
    ],
  },
  {
    collapse: true,
    name: "HRD",
    icon: "ni ni-circle-08 text-green",
    state: "HRDCollapse",
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      {
        path: "/karyawan/edit/gambar/:id",
        name: "Edit Warehouse Pusat",
        miniName: "",
        component: EditGambarKaryawan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/karyawan/edit/:id",
        name: "Edit Durasi Operasional",
        miniName: "",
        component: EditKaryawan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/karyawan/create",
        name: "Create karyawan",
        miniName: "",
        component: CreateKaryawanBaru,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/karyawan",
        name: "Daftar Karyawan ",
        miniName: "",
        component: karyawanbaru,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      // {
      //   path: "/karyawan/edit/:id",
      //   name: "Edit User",
      
      //   component: EditKaryawan,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/karyawan/create",
      //   name: "Create Karyawan",
      //   
      //   component: CreateKaryawan,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/karyawan",
      //   name: "Karyawan",
      //         //   component: Karyawan,
      //   layout: "/admin",
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      {
        path: "/absen",
        name: "Daftar Absen",
        miniName: "",
        component: IndexAbsensiKaryawan,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/employee/izin/detail/:id",
        name: "Edit User",
        component: DetailHrdIzin,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/employee/izin/validasi/:id",
        name: "Edit User",
        component: ValidasiHrdIzinValidasi,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      }, 
      {
        path: "/employee/izin",
        name: "Daftar Izin",
        miniName: "",
        component: IndexIzinHrd,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/hrd/dinas/detail/:id",
        name: "Edit User",
        component: DetailHrdIzinDinas,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/hrd/dinas/validasi/:id",
        name: "Edit User",
        component: ValidasiHrdIzinDinasValidasi,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/hrd/dinas",
        name: "Daftar Dinas",
        miniName: "",
        component: IndexDinasHrd,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Finance",
    icon: "ni ni-money-coins text-yellow",
    state: "FinanceCollapse",
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      // {
      //   path: "/komisi",
      //   name: "Komisi",
      //   
      //   component: Komisi,
      //   layout: "/admin",
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      {
        path: "/jadwal-stock-opname/detail/:id",
        name: "Create Promo",
        component: DetailJadwalOpname,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/jadwal-stock-opname/histori",
        name: "Create Promo",
        component: HistoriJadwalOpname,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/jadwal-stock-opname/edit/:id",
        name: "Edit Stock Opname",
        miniName: "",
        component: EditJadwalOpname,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/jadwal-stock-opname/create",
        name: "Create Promo",
        component: CreateJadwalOpname,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/jadwal-stock-opname/page",
        name: "Create Promo",
        component: JadwalOpnamePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/jadwal-stock-opname",
        name: "Jadwal Opname",
        miniName: "",
        component: JadwalOpnameIndex,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-opname/cetak/:id",
        name: "Edit Stock Opname",
        miniName: "",
        component: CetakStokOpname,
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-opname/detail/:id",
        name: "Create Promo",
        component: DetailOpnameHistori,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-opname/histori",
        name: "Create Promo",
        component: HistoriOpname,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-opname/validasi-direktur/:id",
        name: "Edit Stock Opname",
        miniName: "",
        component: ValidasiDirekturOpname,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-opname/validasi-direktur",
        name: "Create Promo",
        component: ValidasiDirekturOpname1,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-opname/edit/:id",
        name: "Edit Stock Opname",
        
        component: EditOpname,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-opname/create",
        name: "Create Promo",
        component: CreateOpname,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-opname/page",
        name: "Create Promo",
        component: OpnamePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/stock-opname",
        name: "Stok Opname",
        miniName: "",
                component: Opname,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/biaya",
        name: "Biaya Lain Lain",
        miniName: "",
        component: CreateBiaya,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pemasukan",
        name: "Pemasukan Lain Lain",
        miniName: "",
        component: CreatePemasukan,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/uang-muka/page",
        name: "Uang Muka",
        miniName: "",
        component: UangMukaPenjualanPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbk-finance/detail/:id",
        name: "",
        component: DetailBBK,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbk-finance/histori",
        name: "",
        component: HistoriCetakBbk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbk-finance/validasi-direktur/:id",
        name: "Edit Stock Opname",
        component: ValidasiDirekturFinanceBbk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbk-finance/validasi-direktur",
        name: "",
        component: ValidasDirekturBBKPembelianPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbk-finance/validasi-kepala-finance/:id",
        name: "",
        component: ValidasiBbkFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbk-finance/validasi-kepala-finance",
        name: "",
        component: ValidasiKepalaFinanceBBKPembelianPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbk-finance/edit/:id",
        name: "",
        component: EditFinanceBbk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbk-finance/create",
        name: "",
        component: CreateFinanceBbk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbk-finance/page",
        name: "",
        component: BBKPembelianPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbk-finance",
        name: "BBK Pembelian",
        miniName: "",
        component: BbkFinance,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbm-finance/detail/:id",
        name: "Edit Stock Opname",
        component: DetailBBMFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbm-finance/histori",
        name: "Edit Stock Opname",
        component: HistoriCetakBbm,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbm-finance/validasi-direktur/:id",
        name: "Edit Stock Opname",
        component: ValidasiDirekturBBMFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbm-finance/validasi-direktur",
        name: "",
        component: ValidasiDirekturBbmFinancePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbm-finance/validasi-kepala-finance/:id",
        name: "",
        component: ValidasiBbmFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbm-finance/validasi-kepala-finance",
        name: "",
        component: ValidasiKepalaFinanceBbmPenjualan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbm-finance/edit/:id",
        name: "",
        component: EditFinanceBbm,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbm-finance/create",
        name: "",
        component: CreateFinanceBbm,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbm-finance/page",
        name: "",
        component: BBMPenjualanPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bbm-finance",
        name: "BBM Penjualan",
        miniName: "",
        component: BbmFinance,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkk-finance/detail/:id",
        name: "",
        component: DetailCetakFinanceBkk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkk-finance/histori",
        name: "",
        component: HistoriCetakBkk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkk-finance/validasi-direktur/:id",
        name: "Edit Stock Opname",
        component: ValidasDirekturBkkFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkk-finance/validasi-direktur",
        name: "",
        component: ValidasiDirekturFinanceBkk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkk-finance/validasi-kepala-toko/:id",
        name: "Edit Stock Opname",
        component: ValidasiKepalaTokoBKK,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkk-finance/validasi-kepala-finance",
        name: "",
        component: ValidasiKepalaFinanceBkkPembelianPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkk-finance/edit/:id",
        name: "",
        component: EditBkkPembelian,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkk-finance/create",
        name: "",
        component: CreateFinanceBkk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkk-finance/page",
        name: "",
        component: BKKFinancePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkk-finance",
        name: "BKK Pembelian",
        miniName: "",
        component: BKKFINANCE,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkm-finance/detail/:id",
        name: "",
        component: DetailCetakFinanceBkm,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkm-finance/histori",
        name: "",
        component: HistoriCetakBkm,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkm-finance/validasi-direktur/:id",
        name: "Edit Stock Opname",
        component: ValidasiDirekturBKMFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkm-finance/validasi-direktur",
        name: "Create Promo",
        component: ValidasiDirekturBkmFinancePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkm-finance/validasi-kepala-finance/:id",
        name: "Edit Stock Opname",
        component: ValidasiBkmFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkm-finance/validasi-kepala-finance",
        name: "Create Promo",
        component: ValidasiKepalaFinanceBkmFinancePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkm-finance/edit/:id",
        name: "",
        component: EditFinanceBkm,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkm-finance/create",
        name: "Create Promo",
        component: CreateFinanceBkm,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkm-finance/page",
        name: "Create Promo",
        component: BKMFinancePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bkm-finance",
        name: "BKM Penjualan",
        miniName: "",
        component: BKMFINANCE,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/retur-bkm-pembelian/validasi-direktur/:id",
        name: "Edit Stock Opname",
        component: ValidasiBkmDirekturReturPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/retur-bkm-pembelian/validasi-direktur",
        name: "Create Promo",
        component: ValidasiDirekturBMReturBeli,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/retur-bkm-pembelian/validasi-kepala-finance/:id",
        name: "Edit Stock Opname",
        component: ValidasiBkmKepalaFinanceReturPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/retur-bkm-pembelian/validasi-kepala-finance",
        name: "Create Promo",
        component: ValidasiKepalaFinanceBMReturBeli,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/retur-bkm-pembelian/create",
        name: "Create Promo",
        component: "",
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/retur-bkm-pembelian/page",
        name: "Create Promo",
        component: BMReturBeliPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/benk/histori/detail/:id",
        name: "BM Retur Beli",
        miniName: "",
        component: DetailFinanceBbmRetur,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/benk/histori",
        name: "BM Retur Beli",
        miniName: "",
        component: HistoriCetakBbmRetur,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/benk/validasi-direktur/validasi/:id",
        name: "BM Retur Beli",
        miniName: "",
        component: ValidasiDirekturFinanceBbmRetur,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/benk/validasi-direktur",
        name: "BM Retur Beli",
        miniName: "",
        component: ValidasDirekturBBMReturPembelianPage,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/benk/validasi-kepala-finance/validasi/:id",
        name: "BM Retur Beli",
        miniName: "",
        component: ValidasiBbmReturFinance,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/benk/validasi-kepala-finance",
        name: "BM Retur Beli",
        miniName: "",
        component: ValidasiKepalaFinanceBBMReturPembelianPage,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/benk/edit/:id",
        name: "BM Retur Beli",
        miniName: "",
        component: EditFinanceBbmRetur,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/benk/create",
        name: "BM Retur Beli",
        miniName: "",
        component: CreateFinanceBbmRetur,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/benk/page",
        name: "BM Retur Beli",
        miniName: "",
        component: BBMReturPembelianPage,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/benk",
        name: "BM Retur Beli",
        miniName: "",
        component: BankBMReturPage,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/kas/detail/:id",
        name: "Create Promo",
        component: DetailBkmReturFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/kas/histori",
        name: "Create Promo",
        component: HistoriCetakBkmRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/kas/validasi-direktur/validasi/:id",
        name: "Create Promo",
        component: BkmReturValidasiDirekturFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/kas/validasi-direktur",
        name: "Create Promo",
        component: BkmReturValidasDirekturFinancePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/kas/validasi-kepala-finance/validasi/:id",
        name: "Create Promo",
        component: ValidasiBkmReturKepalaFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/kas/validasi-kepala-finance",
        name: "Create Promo",
        component: BkmReturValidasiKepalaFinancePembelianPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/kas/edit/:id",
        name: "Create Promo",
        component: EditBkmReturPembelian,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/kas/create",
        name: "Create Promo",
        component: CreateFinanceBkmRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/kas/page",
        name: "Create Promo",
        component: BKMReturPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur/kas",
        name: "BM Retur Beli",
        miniName: "",
        component: KasBMReturPage,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bm-retur",
        name: "BM Retur",
        miniName: "",
        component: BMReturPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/retur-bkk-penjualan/validasi-direktur/:id",
        name: "Edit Stock Opname",
        component: ValidasiBkmDirekturReturPo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/retur-bkk-penjualan/validasi-direktur",
        name: "BK Retur Jual",
        miniName: "",
        component: ValidasiDirekturBkReturJual,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/retur-bkk-penjualan/validasi-kepala-finance/:id",
        name: "Edit Stock Opname",
        component: ValidasiBkkKepalaFinanceReturSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/retur-bkk-penjualan/validasi-kepala-finance",
        name: "BK Retur Jual",
        miniName: "",
        component: ValidasiKepalaFinanceBkReturJual,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/retur-bkk-penjualan/page",
        name: "BK Retur Jual",
        miniName: "",
        component: BkReturJualPage,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/benk/histori/detail/:id",
        name: "Create Promo",
        component: DetailBBkReturFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/benk/histori",
        name: "Create Promo",
        component: HistoriCetakBbkRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/benk/validasi-direktur/validasi/:id",
        name: "Create Promo",
        component: ValidasiDirekturBBkReturFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/benk/validasi-direktur",
        name: "Create Promo",
        component: ValidasiDirekturBbkReturFinancePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/benk/validasi-kepala-finance/validasi/:id",
        name: "Create Promo",
        component: ValidasiBbkReturFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/benk/validasi-kepala-finance",
        name: "Create Promo",
        component: ValidasiKepalaFinanceBbkReturFinancePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/benk/edit/:id",
        name: "Create Promo",
        component: EditFinanceBbkRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/benk/create",
        name: "Create Promo",
        component: CreateFinanceBBKRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/benk/page",
        name: "Create Promo",
        component: BBKReturFinancePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/benk",
        name: "BM Retur Beli",
        miniName: "",
        component: BKReturMenuBank,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/kas/detail/:id",
        name: "Create Promo",
        component: DetailBKkReturFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/kas/histori",
        name: "Create Promo",
        component: HistoriCetakBkkRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/kas/validasi-direktur/validasi/:id",
        name: "Create Promo",
        component: ValidasiDirekturBKkReturFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/kas/validasi-direktur",
        name: "Create Promo",
        component: ValidasiDirekturBkkReturFinancePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/kas/validasi-kepala-finance/validasi/:id",
        name: "Create Promo",
        component: ValidasiBkkReturFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/kas/validasi-kepala-finance",
        name: "Create Promo",
        component: ValidasiKepalaFinanceBkkReturFinancePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/kas/edit/:id",
        name: "Create Promo",
        component: EditFinanceBkkRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/kas/create",
        name: "Create Promo",
        component: CreateFinanceBkkRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/kas/page",
        name: "Create Promo",
        component: BKKReturFinancePage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur/kas",
        name: "BM Retur Beli",
        miniName: "",
        component: BKReturMenuKas,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bk-retur",
        name: "BK Retur",
        miniName: "",
        component: BKReturPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/rekonsiliasi/create",
        name: "Create Rekonsiliasi",
        component: CreateRekonsiliasi,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/rekonsiliasi",
        name: "Rekonsiliasi Bank",
        miniName: "",
        component: Rekonsiliasi,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/account-finance",
        name: "Akun Cabang",
        miniName: "",
        component: AccountFinance,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      // {
      //   path: "/jurnal/create",
      //   name: "Create Jurnal",
      //   
      //   component: CreateJurnal,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/jurnal",
      //   name: "Jurnal",
      //         //   component: Jurnal,
      //   layout: "/admin",
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/pendapatan",
      //   name: "Pendapatan",
      //         //   component: Jurnal,
      //   layout: "/admin",
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/biaya",
      //   name: "Biaya",
      //   miniName: "",
      //   component: "",
      //   layout: "/admin",
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
    ],
  },
  {
    collapse: true,
    name: "E-Commerce",
    icon: "ni ni-shop text-red",
    state: "AdminEcomCollapse",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      {
        path: "/image/banner/update/:id",
        name: "Udate Image",
        miniName: "",
        component: UpdateBanner,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/image/item/update/:id",
        name: "Update Item Image",
        
        component: UpdateImageItem,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/image/update/:id",
        name: "Udate Image",
        
        component: UpdateImage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/banner/upload",
        name: "Upload Banner",
        
        component: UploadBanner,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/image/upload",
        name: "Upload Gambar",
        
        component: UploadImage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/image/list/",
        name: "Upload Gambar",
        miniName: "",
        component: MainList,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/list-kurir/kurir-by-warehouse/create",
        name: "Kurir",
        
        component: KurirbyWarehouse,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/list-kurir/produk-grup/create",
        name: "Kurir",
        
        component: CreateProdukGrup,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/list-kurir/durasi-kurir/create",
        name: "Kurir",
        
        component: CreateDurasiKurir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/list-kurir/create",
        name: "Kurir",
        
        component: CreateListKurir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/list-kurir",
        name: "Kurir",
        miniName: "",
        component: KurirEcommerce,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/ecommerce-users/detail/:id",
        name: "Udate Image",
        
        component: DetailUsersEcommerce,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/ecommerce-users/create",
        name: "Create ecommerce users",
        
        component: CreateUsersEcommerce,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/ecommerce-users",
        name: "Members",
        miniName: "",
                component: UsersEcommerce,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/transaction/validate/:id",
        name: "Udate Image",
        
        component: EditTransactionEcommerce,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/transaction",
        name: "Transaksi",
        miniName: "",
                component: TransactionEcommerce,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/ecom-bank",
        name: "Transfer",
        miniName: "",
                component: BankEcommerce,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      // {
      //   path: "/tokopedia",
      //   name: "Tokopedia",
      //         //   component: "",
      //   layout: "/admin",
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
    ],
  },
  // {
  //   path: "/laporan/laporan-pembelian",
  //   name: "",
  //   component: PageLaporanPembelian,
  //   layout: "/admin",
  //   hidden: true,
  //   roles: [
  //     "ROLE_SUPERADMIN",
  //     "ROLE_ADMIN",
  //     "ROLE_KARYAWAN",
  //     "ROLE_USER",
  //     "ROLE_OWNER",
  //   ],
  // },
  {
    path: "/laporan/e-commerce/laporan-kurir",
    name: "",
    component: PageLaporanKurirEcommerce,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/e-commerce",
    name: "",
    component: PageEcommerce,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/finance/laporan-bukti-kas-masuk",
    name: "",
    component: PageLaporanBuktiKasMasuk,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/finance",
    name: "",
    component: PageFinance,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/sdm/laporan-absen",
    name: "",
    component: PageLaporanAbsen,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/sdm",
    name: "",
    component: Pagekaryawan,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/hrd/staff-absensi",
    name: "",
    component: PageLaporanAbsensiKaryawan,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/hrd/staff-karyawan",
    name: "",
    component: PageLaporanKaryawanHrd,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/hrd",
    name: "",
    component: PageHrd,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/tracking/laporan-canvaser",
    name: "",
    component: PageLaporanCanvaser,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/tracking",
    name: "",
    component: PageTracking,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/penjualan/laporan-kasir-retur",
    name: "",
    component: PageLaporanKasirRetur,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/penjualan/laporan-kasir-surat-jalan",
    name: "",
    component: PageLaporanKasirSuratJalan,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/penjualan/laporan-kasir-settlement",
    name: "",
    component: PageLaporanKasirSettlement,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/penjualan/laporan-kasir-nota",
    name: "",
    component: PageLaporanKasirNota,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/penjualan/laporan-retur-jual",
    name: "",
    component: PageLaporanReturJual,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/penjualan/laporan-faktur-jual",
    name: "",
    component: PageLaporanFakturPenjualan,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/penjualan/laporan-pengiriman",
    name: "",
    component: PageLaporanPengirimanSj,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/penjualan/laporan-penjualan",
    name: "",
    component: PageLaporanPenjualan,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/penjualan/laporan-rfq-penjualan",
    name: "",
    component: PageLaporanPenawaranPenjualan,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/penjualan",
    name: "",
    component: PagePenjualan,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/pembelian/laporan-retur-pembelian",
    name: "",
    component: PageLaporanReturBeli,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/pembelian/laporan-faktur-pembelian",
    name: "",
    component: PageLaporanFakturPembelian,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/pembelian/laporan-receiving",
    name: "",
    component: PageLaporanBPBPembelian,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/pembelian/laporan-rfq",
    name: "",
    component: PageLaporanRfqPembelian,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/pembelian/laporan-pembelian",
    name: "",
    component: PageLaporanPembelian,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/pembelian",
    name: "",
    component: PagePembelian,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/inventori/laporan-transfer-bpb-cabang",
    name: "",
    component: PageLaporanBpbTransferCabang,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/inventori/laporan-transfer-sj-cabang",
    name: "",
    component: PageLaporanTransferSjCabang,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/inventori/laporan-transfer-cabang",
    name: "",
    component: PageLaporanTransferCabang,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/inventori/laporan-transfer-internal",
    name: "",
    component: PageLaporanTransferInternal,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/inventori/laporan-stok",
    name: "",
    component: PageLaporanStok,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/inventori",
    name: "",
    component: PageInventori,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/master/laporan-customer",
    name: "",
    component: PageLaporanCustomer,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/master/laporan-supplier",
    name: "",
    component: PageLaporanSupplier,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/master/laporan-planogram",
    name: "",
    component: PageLaporanPlanogram,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/master/laporan-cabang",
    name: "",
    component: PageLaporanCabang,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/master",
    name: "",
    component: PageMaster,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan",
    name: "Laporan",
    miniName: "",
    icon: "ni ni-folder-17 text-red",
    component: LaporanMenu,
    layout: "/admin",
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/laporan/master",
    name: "",
    component: PageMaster,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/migrasi-data/gambar",
    name: "Laporan",
    miniName: "",
    component: PageLaporanMigrasiDataGambar,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/migrasi-data",
    name: "Migrasi Data",
    miniName: "",
    icon: "ni ni-folder-17 text-red",
    component: MigrasiData,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  // {
  //   collapse: true,
  //   path: "/report",
  //   name: "Report",
  //   icon: "ni ni-folder-17 text-red",
  //   state: "ReportCollapse",
  //   layout: "/admin",
  //   roles: [
  //     "ROLE_SUPERADMIN",
  //     "ROLE_ADMIN",
  //     "ROLE_KARYAWAN",
  //     "ROLE_USER",
  //     "ROLE_OWNER",
  //   ],
  //   views: [
  //     // {
  //     //   collapse: true,
  //     //   name: "Laporan Skuy",
  //     //   icon: "ni ni-folder-17 text-red",
  //     //   state: "ReportCollapseSkuy",
  //     //   roles: [
  //     //     "ROLE_SUPERADMIN",
  //     //     "ROLE_ADMIN",
  //     //     "ROLE_KARYAWAN",
  //     //     "ROLE_USER",
  //     //     "ROLE_OWNER",
  //     //   ],
  //     //   views: [
  //     //     {
  //     //       path: "/laporan-po",
  //     //       name: "Laporan PO",
  //     //       component: LaporanPo,
  //     //       miniName: "",
  //     //       layout: "/admin",
  //     //       roles: [
  //     //         "ROLE_SUPERADMIN",
  //     //         "ROLE_ADMIN",
  //     //         "ROLE_KARYAWAN",
  //     //         "ROLE_USER",
  //     //         "ROLE_OWNER",
  //     //       ],
  //     //     },
  //     //   ]
  //     // },
  //     // {
  //     //   collapse: false,
  //     //   name: "Laporan Master",
  //     //   component: LaporanPo,
  //     //   // icon: "ni ni-folder-17 text-red",
  //     //   miniName: "",
  //     //   // state: "ReportMasterCollapse",
  //     //   roles: [
  //     //     "ROLE_SUPERADMIN",
  //     //     "ROLE_ADMIN",
  //     //     "ROLE_KARYAWAN",
  //     //     "ROLE_USER",
  //     //     "ROLE_OWNER",
  //     //   ],
  //     // },
  //     // {
  //     //   collapse: false,
  //     //   name: "Laporan Penjualan",
  //     //   component: LaporanPo,
  //     //   // icon: "ni ni-folder-17 text-red",
  //     //   miniName: "",
  //     //   // state: "ReportMasterCollapse",
  //     //   roles: [
  //     //     "ROLE_SUPERADMIN",
  //     //     "ROLE_ADMIN",
  //     //     "ROLE_KARYAWAN",
  //     //     "ROLE_USER",
  //     //     "ROLE_OWNER",
  //     //   ],
  //     // },
  //     // {
  //     //   path: "/laporan-stok",
  //     //   name: "Laporan Stok",
  //     //   component: LaporanStok,
  //     //   miniName: "",
  //     //   layout: "/admin",
  //     //   roles: [
  //     //     "ROLE_SUPERADMIN",
  //     //     "ROLE_ADMIN",
  //     //     "ROLE_KARYAWAN",
  //     //     "ROLE_USER",
  //     //     "ROLE_OWNER",
  //     //   ],
  //     // },
  //     {
  //       path: "/laporan-pembelian",
  //       name: "Laporan Pembelian",
  //       component: LaporanPembelian,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-penjualan",
  //       name: "Laporan Penjualan",
  //       component: LaporanPenjualan,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-po",
  //       name: "Laporan PO",
  //       component: LaporanPo,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-receiving",
  //       name: "Laporan Receiving PO",
  //       component: LaporanReceivingPo,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-invoice-po",
  //       name: "Laporan Invoice PO",
  //       component: LaporanInvoicePo,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-keluar-bukti-kas",
  //       name: "Laporan Bukti Kas Keluar",
  //       component: LaporanBuktiKasKeluar,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     // {
  //     //   collapse: false,
  //     //   name: "Laporan Pembelian",
  //     //   component: LaporanPo,
  //     //   // icon: "ni ni-folder-17 text-red",
  //     //   miniName: "",
  //     //   // state: "ReportMasterCollapse",
  //     //   roles: [
  //     //     "ROLE_SUPERADMIN",
  //     //     "ROLE_ADMIN",
  //     //     "ROLE_KARYAWAN",
  //     //     "ROLE_USER",
  //     //     "ROLE_OWNER",
  //     //   ],
  //     // },
  //     {
  //       path: "/laporan-so",
  //       name: "Laporan SO Proyek",
  //       component: LaporanSo,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-kasir",
  //       name: "Laporan SO Kasir",
  //       component: LaporanSokasir,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-proyek-surat-jalan",
  //       name: "Laporan Surat Jalan Proyek",
  //       component: LaporanSuratJalanProyek,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-surat-jalan-kasir",
  //       name: "Laporan Surat Jalan Kasir",
  //       component: LaporanSuratJalanKasir,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-proyek-invoice",
  //       name: "Laporan Invoice SO Proyek",
  //       component: LaporanInvoiceSo,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-oso-kasir-invoice",
  //       name: "Laporan Invoice SO Kasir",
  //       component: LaporanInvoiceSoKasir,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-masuk-bukti-kas",
  //       name: "Laporan Bukti Kas Masuk",
  //       component: LaporanBuktiKasMasuk,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     // {
  //     //   collapse: false,
  //     //   name: "Laporan Gudang",
  //     //   component: LaporanPo,
  //     //   // icon: "ni ni-folder-17 text-red",
  //     //   miniName: "",
  //     //   // state: "ReportMasterCollapse",
  //     //   roles: [
  //     //     "ROLE_SUPERADMIN",
  //     //     "ROLE_ADMIN",
  //     //     "ROLE_KARYAWAN",
  //     //     "ROLE_USER",
  //     //     "ROLE_OWNER",
  //     //   ],
  //     // },
  //     {
  //       path: "/laporan-transfer-receiving",
  //       name: "Laporan Receiving Transfer",
  //       component: LaporanReceivingTransfer,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     }, 
  //     {
  //       path: "/laporan-transfer-surat-jalan",
  //       name: "Laporan Surat Jalan Transfer",
  //       component: LaporanSuratJalanTransfer,
  //       miniName: "",
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-jurnal/jurnal-buku-besar",
  //       name: "Laporan Jurnal",
  //       component: LaporanJurnalBukuBesar,
  //       layout: "/admin",
  //       hidden: true,
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-jurnal/jurnal-penyesuaian",
  //       name: "Laporan Jurnal",
  //       component: LaporanJurnalPenyesuaian,
  //       layout: "/admin",
  //       hidden: true,
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
        {
          path: "/laporan-jurnal/jurnal-umum/detail/:id",
          name: "Detail Jurnal Umum",
          component: DetailJurnalUmum,
          layout: "/admin",
          hidden: true,
          roles: [
            "ROLE_SUPERADMIN",
            "ROLE_ADMIN",
            "ROLE_KARYAWAN",
            "ROLE_USER",
            "ROLE_OWNER",
          ],
        },
      {
        path: "/laporan-jurnal/jurnal-umum",
        name: "Laporan Jurnal",
        component: LaporanJurnalUmum,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
  //     {
  //       path: "/laporan-jurnal/jurnal-laba-rugi",
  //       name: "Laporan Jurnal",
  //       component: LaporanJurnalLabaRugi,
  //       layout: "/admin",
  //       hidden: true,
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-jurnal/jurnal-neraca",
  //       name: "Laporan Jurnal",
        
  //       component: LaporanJurnalNeraca,
  //       layout: "/admin",
  //       hidden: true,
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-calk/jurnal-calk",
  //       name: "Laporan Jurnal",
        
  //       component: LaporanCalk,
  //       layout: "/admin",
  //       hidden: true,
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-perubahan-modal/jurnal-perubahan-modal",
  //       name: "Laporan Jurnal",
  //       component: LaporanPerubahanModal,
  //       layout: "/admin",
  //       hidden: true,
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-arus-kas/jurnal-arus-kas",
  //       name: "Laporan Jurnal",
  //       component: LaporanArusKas,
  //       layout: "/admin",
  //       hidden: true,
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //     {
  //       path: "/laporan-jurnal",
  //       name: "Laporan Jurnal",
  //       miniName: "",
  //       component: LaporanJurnal,
  //       layout: "/admin",
  //       roles: [
  //         "ROLE_SUPERADMIN",
  //         "ROLE_ADMIN",
  //         "ROLE_KARYAWAN",
  //         "ROLE_USER",
  //         "ROLE_OWNER",
  //       ],
  //     },
  //   ],
  // },
];

export default routes;
