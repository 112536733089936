import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
// import * as FileSaver from "file-saver";
// import SweetAlert from "react-bootstrap-sweetalert";
// import styles from '../../../../../assets/scss/Print.scss'
import moment from "moment";
import IdBank from "views/components/IdBank";

const ModalCetakInvoiceSo = ({ open, toggle, data }) => {
    const token = localStorage.token;
    const username = localStorage.username;
    const [creator,setCreator] = useState("")
    const [validator1,setValidator1] = useState("")
    const [validator2,setValidator2] = useState("")
    const [savedItems, setSavedItems] = useState([]);
    const [download, setDownload] = useState("")
    // const [alert, setAlert] = React.useState(null);
    const [codepo, setCodePo] = useState("");
    const [dp, setDp] = useState(0);
    const [payment, setPayment] = useState("")
    // const [payment_method,setPaymentMethod] = useState([]);
    const [keterangan, setKeterangan] = useState("");
    const [supplier, setSupplier] = useState("");
    const [jangkaWaktu,setJangkaWaktu] = useState(0);
    const [warehouserfq, setWarehouseRfq] = useState("");
    // const [coderfq, setCodeRfq] = useState([]);
    const [ongkir, setOngkir] = useState(0);
    // const [lainnya, setLainnya] = useState(0);
    // const [listItem,setListItem] = useState([]);
    const [waktu,setWaktu] = useState([]);
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [npwp, setNpwp] = useState("")
    // const [bank, setBank] = useState("")
    // const [rekening, setRekening] = useState("")
    const [pricereal, setPriceReal]= useState("")
    const [diskontotal, setDiskonTotal] = useState("")
    const [pajak, setPajak] = useState("");
    // const [sales,setSales] = useState("")
    // const [alamat,setAlamat] = useState("")
    const [item, setItem] = useState("")
    const [ppn, setPPN] = useState("")
    const [hargatotal, setHargaTotal] = useState(0);
    const [words, setWords] = useState('');

  const convertToWords = (hargatotal) => {
    const ones = ['', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan'];
    const teens = ['', 'Sebelas', 'Dua Belas', 'Tiga Belas', 'Empat Belas', 'Lima Belas', 'Enam Belas', 'Tujuh Belas', 'Delapan Belas', 'Sembilan Belas'];
    const tens = ['', 'Sepuluh', 'Dua Puluh', 'Tiga Puluh', 'Empat Puluh', 'Lima Puluh', 'Enam Puluh', 'Tujuh Puluh', 'Delapan Puluh', 'Sembilan Puluh'];

    if (hargatotal === 0) return 'Kosong';

    let words = '';

    if (hargatotal >= 1000000000) {
      words += convertToWords(Math.floor(hargatotal / 1000000000)) + ' Miliar ';
      hargatotal %= 1000000000;
    }

    if (hargatotal >= 1000000) {
      words += convertToWords(Math.floor(hargatotal / 1000000)) + ' Juta ';
      hargatotal %= 1000000;
    }

    if (hargatotal >= 1000) {
      words += convertToWords(Math.floor(hargatotal / 1000)) + ' Ribu ';
      hargatotal %= 1000;
    }

    if (hargatotal >= 100) {
      words += ones[Math.floor(hargatotal / 100)] + ' Ratus ';
      hargatotal %= 100;
    }

    if (hargatotal >= 20) {
      words += tens[Math.floor(hargatotal / 10)] + ' ';
      hargatotal %= 10;
    }

    if (hargatotal >= 11 && hargatotal <= 19) {
      words += teens[hargatotal - 10] + ' ';
      hargatotal = 0;
    }

    if (hargatotal >= 1 && hargatotal <= 10) {
      words += ones[hargatotal] + ' ';
    }

    return words.trim();
  };

  

  const handleAmountChange = useCallback(() => {
    const words = convertToWords(hargatotal);
    setWords(words);
  },[hargatotal, words]);


  

  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/invoice-so/cetak/${data.id}`,
        { headers }
      )
      .then((data) => {  
        // setCodeRfq(data.data.response.invoiceso.code_rfq);
        setAddress(data.data.response.invoiceso.address)
        setNpwp(data.data.response.invoiceso.npwp)
        setPhone(data.data.response.invoiceso.phone)
        setSupplier(data.data.response.invoiceso.person);
        setPayment(data.data.response.invoiceso.payment_method);
        // setPaymentMethod(data.data.response.invoiceso.payment_method);
        setJangkaWaktu(data.data.response.invoiceso.jangka_waktu);
        setDownload(data.data.response.invoiceso.counter);
        setWaktu(data.data.response.invoiceso.created)
        setWarehouseRfq(data.data.response.invoiceso.warehouse);
        setDiskonTotal(data.data.response.invoiceso.diskon_total);
        setPajak(data.data.response.invoiceso.nominal_pajak);
        setHargaTotal(data.data.response.invoiceso.payment_total);
        // const words = convertToWords(hargatotal);
        // handleAmountChange(data.data.response.invoiceso.payment_total)
        // setWords(words);
        setPriceReal(data.data.response.invoiceso.price_total);
        setKeterangan(data.data.response.invoiceso.keterangan);
        setOngkir(data.data.response.invoiceso.ongkir);
        setDp(data.data.response.invoiceso.um_nominal)
        setCodePo(data.data.response.invoiceso.invoice_code);
        // setLainnya(data.data.response.invoiceso.lainnya);
        setCreator(data.data.response.invoiceso.creator);
        setValidator1(data.data.response.invoiceso.validator1);
        setValidator2(data.data.response.invoiceso.validator2);
        // setListItem(data.data.response.list);
        // setBank(data.data.response.invoiceso.bank_account1);
        // setRekening(data.data.response.invoiceso.bank_number1);
        setSupplier(data.data.response.invoiceso.customer);
        // setAlamat(data.data.response.invoiceso.address);
        // setSales(data.data.response.invoiceso.sales);
        setWaktu(data.data.response.invoiceso.created)
        setWarehouseRfq(data.data.response.invoiceso.warehouse);
        setKeterangan(data.data.response.invoiceso.keterangan);
        setOngkir(data.data.response.invoiceso.ongkir);
        // setListItem(data.data.response.list);
        setPPN(data.data.response.invoiceso.persen_pajak);
        setItem(data.data.response.list.length);
        // getItemDataSaved(data.data.response.invoiceso.id);
        // setCodeRfq(data.data.response.invoiceso.so_code);
        // setCodePo(data.data.response.po.po_code);
        // setLainnya(data.data.response.po.lainnya);
      })
      .catch(function (error) {
        console.log(error);
      });
  },[token, data.id]);

  const HitCounter = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/invoice-so/hit-counter/${data.id}`,
        { headers }
      )
      .then((data) => {  
        console.log("suskes Cuk");
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/invoice-so/cetak/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                harga: data.harga,
                satuan: data.satuan,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                sub_total : data.sub_total,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token, data.id]);

useEffect(() => {
  getById();
  getItemDataSaved();
  handleAmountChange();
}, [getById,getItemDataSaved,handleAmountChange]);

// const downloadExcel = async ()=> {
//     const J = "Sales-Order"
//     const fileName = J;
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     axios
//       .get(`${process.env.REACT_APP_API_BASE_URL}/invoice-so/cetak/${data.id}`,{headers})
//       .then((data) => {
//         // setCodeRfq(data.data.response.invoiceso.code_rfq);
//         setAddress(data.data.response.invoiceso.address)
//         // setNpwp(data.data.response.invoiceso.npwp)
//         setPhone(data.data.response.invoiceso.phone)
//         setSupplier(data.data.response.invoiceso.person);
//         // setPaymentMethod(data.data.response.invoiceso.payment_method);
//         // setJangkaWaktu(data.data.response.invoiceso.jangka_waktu);
//         setWaktu(data.data.response.invoiceso.created)
//         setWarehouseRfq(data.data.response.invoiceso.warehouse);
//         setDiskonTotal(data.data.response.invoiceso.diskon_total);
//         setPajak(data.data.response.invoiceso.nominal_pajak);
//         setHargaTotal(data.data.response.invoiceso.payment_total);
//         setPriceReal(data.data.response.invoiceso.price_total);
//         setKeterangan(data.data.response.invoiceso.keterangan);
//         setOngkir(data.data.response.invoiceso.ongkir);
//         setCodePo(data.data.response.invoiceso.invoice_code);
//         setCreator(data.data.response.invoiceso.creator);
//         setValidator1(data.data.response.invoiceso.validator1);
//         setValidator2(data.data.response.invoiceso.validator2);
//         // setLainnya(data.data.response.invoiceso.lainnya);
//         // setListItem(data.data.response.list);
//         // setBank(data.data.response.invoiceso.bank_account1);
//         // setRekening(data.data.response.invoiceso.bank_number1);
//         setItem(data.data.response.list.length);

//         var apiPo = data.data.response.list;
//         // var tglcetak = data.data.response.download_date;
//         var tableToExcel = (function() {
//           // var uri = 'data:application/vnd.ms-excel;base64,'
//           var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
//             // base64 = function(s) {
//             //   return window.btoa(unescape(encodeURIComponent(s)))
//             // },
//             format = function(s, c) {
//               return s.replace(/{(\w+)}/g, function(m, p) {
//                 return c[p];
//               })
//             }
//           return function(table, name) {
//             var heading = 'Invoice-SO' ;
//             var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
//             var po = '';
//             var a ;
//             for(a=0; a < apiPo.length; a++){
//               po += `
//               <tr style="border: 1px solid black">
//                   <td>${apiPo[a].item_name}</td>
//                   <td>${apiPo[a].item_code}</td>
//                   <td>${formatRupiah(apiPo[a].harga)}</td>
//                   <td>${apiPo[a].qty}</td>
//                   <td>${apiPo[a].satuan}</td>
//                   <td>${apiPo[a].diskon_persen}</td>
//                   <td>${formatRupiah(apiPo[a].diskon_nominal)}</td>
//                   <td>${formatRupiah(apiPo[a].sub_total)}</td>
//               </tr>
//               `
//             }
//             var table = `
//             <table id="account_table">
//                 <tbody>
//                 <tr>
//                     <td><img src="https://i.ibb.co/jkFYDqg/150x75.png"/> </td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>${warehouserfq}</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Jl Menganti Karangan No.562</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Wiyung surabaya barat - Jawa Timur </td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Telp: 081 217 85 3300 </td>
//                 </tr>
//                 <tr>
//                     <td colspan="8"><center><b><h1>INVOICE SO</h1></b><center></td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                 <tr>
//                     <td>Tanggal</td>
//                     <td>: ${waktu}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Customer</td>
//                     <td>: ${supplier}</td>
//                 </tr>
//                 <tr>
//                     <td>Kode</td>
//                     <td>: ${codepo}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Alamat</td>
//                     <td>: ${address}</td>
//                 <tr>
//                 <tr>
//                     <td>Ket</td>
//                     <td rowspan="2">: ${keterangan}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Phone </td>
//                     <td>: ${phone}</td>
//                 <tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                   <tr style="border: 1px solid black">
//                       <th>Nama Item</th>
//                       <th>Kode Item</th>
//                       <th>Harga</th>
//                       <th>Qty</th>
//                       <th>Satuan</th>
//                       <th>Disc %</th>
//                       <th>Disc N</th>
//                       <th>Sub Total</th>
//                     </tr>
//                         ${po}
//                 </tbody>
//             </table>
//             `;
//             var ctx = {
//                 worksheet: name || 'WorkBook',
//                 imgsrc1: imgsrc1,
//                 heading: heading,
//                 table: table
//             }
//             var blob = new Blob([format(template, ctx)]);
//             return blob;
//             }
//         })()
//         var blobURL = tableToExcel('account_table', 'Sales Order');
//         FileSaver.saveAs(blobURL, fileName+'.xls');
        
//         })
//       .catch(function (error) {
//             setGagalAlert();
//       });
//   }
  
  // const setGagalAlert = () => {
  //   setAlert(
  //     <SweetAlert
  //       danger
  //       showConfirm
  //       confirmBtnText="Ok"
  //       title="Gagal Download"
  //       onCancel={hideAlert}
  //       onConfirm={hideAlert}
  //     />
  //   );
  // };
  
  // const hideAlert = () => {
  //   setAlert(null);
  // };

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }
    
  // console.log("cek pdf cok");

  // function print() {
  //   var printContents = document.getElementById("targetContent").innerHTML;
  //   var originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // }

  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    // console.log(item)
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }
  
  function clickItem(e) {
    setItem(e.target.value)
  }
  
  return (
    <>
    {/* {alert} */}
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <div style={{ textAlign: 'left' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            Set Item
                        </Label>
                        <Input type="number" pattern="[0-9]*" value={item} min="1" max={savedItems.length} onChange={clickItem} style={{width: '70px',marginRight:'50px'}}/>
                    </div> */}
                    <div style={{ textAlign: 'right' }}>
                    <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                        <Button color="info" onClick={() => {print();HitCounter();setTimeout(() => (window.location.reload("/admin/invoice-so/histori")), 1000)}}>Print</Button>
                        {/* <Button color="success" onClick={()=> (downloadExcel(),HitCounter())}>Excel</Button> */}
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
      <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
        {/* <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          <div className="col">
            <KopSurat 
            warehouseName={warehouserfq}
            // user={username}
             />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
                        <Row md="12">
                            <Col md="12">
                                <h3><b><center>Faktur</center></b></h3>
                            </Col>
                        </Row>
                        <div className="w-100" style={{ border: "1px solid black" }}></div>
                        <br></br>
                        <Row md="12">
                            <Col md="5">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Tanggal</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-4 ">
                                    <b>{waktu}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Kode</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{codepo}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Keterangan</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{keterangan}</b>
                                </div>
                            </div>
                            </Col>
                            <Col md="1">
                            </Col>
                            <Col md="6">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Customer</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{supplier}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Alamat</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{address}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Phone</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{phone}</b>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    <CardBody>
                      <br></br>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th>
                            <b>Nama Item</b>
                            </th>
                            <th>
                            <b>Kode Item</b>
                            </th>
                            <th>
                            <b>Harga</b>
                            </th>
                            <th>
                            <b>Qty</b>
                            </th>
                            <th>
                            <b>Satuan</b>
                            </th>
                            <th>
                            <b>Disc %</b>
                            </th>
                            <th>
                            <b>Disc N</b>
                            </th>
                            <th>
                            <b>Sub Total</b>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.data.item_name}</td>
                                        <td>{savedItem.item_code}</td>
                                        <td>{formatRupiah(savedItem.harga)}</td>
                                        <td>{savedItem.qty}</td>
                                        <td>{savedItem.satuan}</td>
                                        <td>{savedItem.diskon_persen}</td>
                                        <td>{savedItem.diskon_nominal}</td>
                                        <td>{formatRupiah(savedItem.sub_total)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                      <div className="w-100" style={{ border: "1px solid black" }}></div>
                        <Row md="12">
                            <Col md="3">
                            </Col>
                            <Col md="3">
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                                <div className="row align-items-center mb-1">
                                    <div className="col-5 text-right">Total</div>
                                    <div className="col-2 text-center">:</div>
                                    <div className="col-5 ">
                                        <b>{formatRupiah(pricereal)}</b>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-1">
                                    <div className="col-5 text-right">Diskon</div>
                                    <div className="col-2 text-center">:</div>
                                    <div className="col-5 ">
                                        <b>{formatRupiah(diskontotal)}</b>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-1">
                                    <div className="col-5 text-right">PPN</div>
                                    <div className="col-2 text-center">:</div>
                                    <div className="col-5 ">
                                        <b>{formatRupiah(pajak)}</b>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-1">
                                    <div className="col-5 text-right">Ongkir</div>
                                    <div className="col-2 text-center">:</div>
                                    <div className="col-5 ">
                                        <b>{formatRupiah(ongkir)}</b>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-1">
                                    <div className="col-5 text-right">Grand Total</div>
                                    <div className="col-2 text-center">:</div>
                                    <div className="col-5 ">
                                        <b>{formatRupiah(hargatotal)}</b>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <div className="w-100" style={{ border: "0.5px solid black" }}></div>
                        <center>Terms of Price, delivery & shipping required</center>
                        <br></br>
                        <Row md="12">
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Finance</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{creator}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Kepala Finance</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{validator1}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Direktur</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{validator2}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                                <div className="w-100" style={{ border: "0.5px solid black" }}></div>
                      {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download</i> {download} Kali Oleh {username}</div>
                    </div>
                      )
                    }
        </CardBody> */}
      </ModalBody>
    </Modal>
    </>
    
  );

  function renderPDF(){
    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []  
    // for (let index = 0; index < sheet; index++) {
    //   let start = 0
    //   let end = 0
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(sheet > 1 && index < (sheet - 1)){
    //       end = item
    //     }else{
    //       end = length % item
    //     }
    //   }

    // let sheet = 1;
    // let length = savedItems.length
    // if (item !== length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []
    // for (let index = 0; index < sheet; index++) {
    //   let start = (item * index)
    //   let end = length
    //   let sisa = (length % item)
    //   if(sheet === 1){
    //     end = length
    //   }else{
    //     if(index < (sheet - 1) && sheet === 2){
    //       end = item
    //     }else{
    //       if (index === (sheet - 1)) {
    //         end = length - sisa + (index * item)
    //       }else{
    //         if (index === 0) {
    //           end = item
    //         }else{
    //           end = (index+1) * item
    //         }
    //       }
    //     }
    //   } 
    //   let data = savedItems.slice(start,end)
    //   // console.log(data.length)
    //   let usernamecok = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
    const itemsPerPage = 25; // Maksimal 7 item per halaman
    const totalItems = savedItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Hitung total halaman
  
    const sheets = [];
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      // Hitung indeks awal dan akhir untuk slice item per halaman
      const start = pageIndex * itemsPerPage;
      const end = start + itemsPerPage;
  
      // Ambil item untuk halaman saat ini
      const currentItems = savedItems.slice(start, end);
      // console.log(data.length)
      // let sub_total = 0;
      // let diskon = 0;
      // let pajak = 0;
      let usernamecok = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
      sheets.push(
        <div className='pagebreak'>
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <Row md="12">
              <Col md="12">
                  <h3 className="" style={{ color:"black" }} ><b><center>FAKTUR PENJUALAN</center></b></h3>
              </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          
          <Row md="12">
            <Col style={{marginLeft:"2%",width:"40%",flexBasis: "unset"}}>
             
              <div className="row align-items-center ">
                  <div style={{width:"18%",color:"black",fontSize:"14px"}}  className="text-table">Tanggal</div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}}  className="text-center text-table">:</div>
                  <div style={{width:"60%",color:"black",fontSize:"14px"}}  className="text-table">
                      <b>{waktu}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"18%",color:"black",fontSize:"14px"}} className="text-table">No Faktur</div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}} className="text-center text-table">:</div>
                  <div style={{width:"60%",color:"black",fontSize:"14px"}} className="text-table">
                      <b>{codepo}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"18%",color:"black",fontSize:"14px"}} className="text-table">Pembayaran</div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}} className="text-center text-table">:</div>
                  <div style={{width:"60%",color:"black",fontSize:"14px"}} className="text-table">
                    {payment === 1 ? 
                    <b>Lunas</b>
                    : payment === 2 ? 
                    <b>Tempo</b>
                    :
                    <b>Dp</b>
                  }
                  </div>
              </div>
              {jangkaWaktu === 0 ? 
              "" :
              <div className="row align-items-center ">
                  <div style={{width:"18%",color:"black",fontSize:"14px"}}  className="text-table">Termin </div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}}  className="text-center text-table">:</div>
                  <div style={{width:"60%",color:"black",fontSize:"14px"}}  className="text-table">
                      <b>{jangkaWaktu} Hari</b>
                  </div>
              </div> 
              }
              
              <div className="row align-items-center ">
                  <div style={{width:"18%",color:"black",fontSize:"14px"}}  className="text-table">keterangan </div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}}  className="text-center text-table">:</div>
                  <div style={{width:"60%",color:"black",fontSize:"14px"}}  className="text-table">
                      <b>{keterangan}</b>
                  </div>
              </div>
            </Col>
            <Col style={{width:"50%",flexBasis: "unset"}}>
              <div className="row align-items-center ">
                  <div style={{width:"15%",color:"black",fontSize:"14px"}} className="text-table">Supplier</div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"70%",color:"black",fontSize:"14px"}} className="text-table">
                      <b>{supplier}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"15%",color:"black",fontSize:"14px"}} className="text-table">Alamat</div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"70%",color:"black",fontSize:"14px"}} className="text-table">
                      <b>{address}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"15%",color:"black",fontSize:"14px"}} className="text-table">Kontak</div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"70%",color:"black",fontSize:"14px"}} className="text-table">
                      <b>{phone === "NULL" ? "-" : phone}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"15%",color:"black",fontSize:"14px"}} className="text-table">Npwp</div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"70%",color:"black",fontSize:"14px"}} className="text-table">
                      <b>{npwp === "NULL" ? "-" : npwp}</b>
                  </div>
              </div>
            </Col>
          </Row>
            <br></br>
              <Table style={{width:"100%"}} responsive>
                <thead>
                <tr>
                    <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black"  }}>
                    <b>No</b>
                    </th>
                    <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black"  }}>
                    <b>Kode</b>
                    </th>
                    <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black"  }}>
                    <b>Nama Barang</b>
                    </th>
                    {/* <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black"  }}>
                    <b>Brand</b>
                    </th> */}
                    <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black"  }}>
                    <b>Harga</b>
                    </th>
                    <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black"  }}>
                    <b>Qty</b>
                    </th>
                    <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black"  }}>
                    <b>Sat</b>
                    </th>
                    <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black"  }}>
                    <b>Disc %</b>
                    </th>
                    <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black"  }}>
                    <b>Disc N</b>
                    </th>
                    <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black"  }}>
                    <b>Total</b>
                    </th>
                    <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black"  }}>
                    <b>Keterangan</b>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    currentItems.map((temp,index, key) => {
                        let item_name =  temp.data.item_name
                        // sub_total = sub_total + temp.sub_total
                        return (
                            <tr>
                                <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{index+1}</td>
                                <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{temp.item_code}</td>
                                <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{item_name}</td>
                                {/* <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>-</td> */}
                                <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{formatRupiah(temp.harga / (ppn/100 + 100/100))}</td>
                                {/* <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{formatRupiah(temp.harga)}</td> */}
                                <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{temp.qty}</td>
                                <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{temp.satuan}</td>
                                <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{temp.diskon_persen}</td>
                                <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{temp.diskon_nominal}</td>
                                <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>
                                  {/* {formatRupiah(temp.sub_total)} */}
                                  {formatRupiah(((temp.harga - temp.diskon_nominal) * temp.qty) / (ppn/100 + 100/100))}
                                </td>
                                <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}></td>
                            </tr>
                        );
                    })
                }
                  <tr className="w-100 text-table" style={{ border: "1px solid black" }}></tr>
                  <tr className="no-border">
                    <td colspan="6" className="border-non text-table" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}></td>
                    <td colspan="2" className="text-right text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>Sub Total
                    </td>
                    <td className="text-left text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>
                          <b>{formatRupiah(pricereal)}</b>
                    </td>
                    <td className="border-non text-table" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}></td>
                  </tr>
                  <tr className="no-border">
                    <td colspan="6" className="border-non text-table" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}></td>
                    <td colspan="2" className="text-right text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>Diskon
                    </td>
                    <td className="text-left text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>
                          <b>{formatRupiah(diskontotal)}</b>
                    </td>
                    <td className="border-non text-table" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}></td>
                  </tr>
                  <tr className="no-border">
                    <td colspan="6" className="border-non text-table" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}></td>
                    <td colspan="2" className="text-right text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>PPN
                    </td>
                    <td className="text-left text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>
                          <b>{formatRupiah(pajak)}</b>
                    </td>
                    <td className="border-non text-table" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}></td>
                  </tr>
                  <tr className="no-border">
                    <td colspan="6" className="border-non text-table" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}></td>
                    <td colspan="2" className="text-right text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>Ongkir
                    </td>
                    <td className="text-left text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>
                          <b>{formatRupiah(ongkir)}</b>
                    </td>
                    <td className="border-non text-table" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}></td>
                  </tr>
                  {dp === 0 ? 
                    ""
                    :
                    <tr className="no-border">
                      <td colspan="6" className="border-non text-table" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}></td>
                      <td colspan="2" className="text-right text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>Uang Muka
                      </td>
                      <td className="text-left text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>
                            <b>{formatRupiah(dp)}</b>
                      </td>
                      <td className="border-non text-table" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}></td>
                    </tr>
                  }
                  <tr className="no-border">
                    <td colspan="1" className="border-non text-table" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>Terbilang</td>
                    <td colspan="5" className="text-left text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>
                          <b>{words}</b>
                    </td>
                    <td colspan="2" className="text-right text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>Grand Total
                    </td>
                    <td className="text-left text-table border-non" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}>
                          <b>{formatRupiah(hargatotal)}</b>
                    </td>
                    <td className="border-non text-table" style={{color:"black",border: "none",whiteSpace: "nowrap", padding: "4px 8px", fontSize:"14px"}}></td>
                  </tr>
                </tbody>
              </Table>
              <div ></div>
              <Row md="12">
              </Row>
          <div className="w-100 text-table" style={{ border: "0.5px solid black" }}></div>
          <center className="text-table" style={{color:"black",border: "none"}}>Terms of Price, delivery & shipping required</center>
          <Row md="20">
            <Col style={{ marginLeft:"2%",width:"33%" }}>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  {/* <div className="col-5 text-table">Di Buat</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-6 text-table">
                      <b>{creator}</b>
                  </div> */}
                  <div style={{width:"20%",color:"black",fontSize:"14px"}} className="text-table">Di Buat</div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"70%",color:"black",fontSize:"14px"}} className="text-table">
                    <b>{creator}</b>
                  </div>
              </div>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  {/* <div className="col-5 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-6 text-table">
                  </div> */}
                  <div style={{width:"20%",color:"black",fontSize:"14px"}} className="text-table">Signature</div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"70%",color:"black",fontSize:"14px"}} className="text-table">
                  </div>
              </div>
            </Col>
            <Col style={{ width:"33%" }}>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  {/* <div className="col-5 text-table">Kepala Finance</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-7 text-table">
                      <b>{validator1}</b>
                  </div> */}
                  <div style={{width:"40%",color:"black",fontSize:"14px"}} className="text-table">Kepala Finance</div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"60%",color:"black",fontSize:"14px"}} className="text-table">
                    <b>{validator1}</b>
                  </div>
              </div>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  <div style={{width:"40%",color:"black",fontSize:"14px"}} className="text-table">Signature</div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"60%",color:"black",fontSize:"14px"}} className="text-table">
                  </div>
              </div>
            </Col>
            <Col style={{ width:"33%" }}>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  <div className="col-3 text-table">Direktur</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{validator2}</b>
                  </div>
              </div>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      {/* <b>------------------</b> */}
                  </div>
              </div>
            </Col>
          </Row>
          {/* <Row md="20">
            <Col style={{ marginLeft:"2%",width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Project Merchandise</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{merchandise}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
            <Col style={{ width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Delivery Place</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{deliveryPlace}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Shipping & Packing</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{shippingPacking}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Validity of Offer</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{validiyOffer}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Availability</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-5 text-table">
                      <b>{availability}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Quote Validity</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{quoteAvailability}</b>
                  </div>
              </div>
            </Col>
            <Col style={{ width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Supplier Name :</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{supplier}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
          </Row> */}
          <div className="w-100" style={{ border: "0.5px solid black" }}></div>
                      {/* {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download {download} Kali Oleh {username}</i></div>
                    </div>
                      ) 
                    } */}
          <div className="col">
            <IdBank />
          </div>
          {/* <div class="divFooter">{usernamecok}</div> */}
          <div className="divFooter" style={{ fontSize:"12px" }}><i>{usernamecok}</i>, {download === 0 ? (
                        // <div className="row align-items-center mb-3">
                        <i>,Download Original</i>
                          // </div>
                        ):(
                        // <div className="row align-items-center mb-3">
                        <i>,Di Download {download} Kali</i>
                      // </div>
                      )
                    }</div>
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }

};

export default ModalCetakInvoiceSo;
