import React, { useCallback, useEffect, useState } from "react";
import {Button,Row,Col,CardBody,Table,Modal,ModalHeader,ModalBody,Label} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
// import * as FileSaver from "file-saver";
import ExcelJS from 'exceljs';
import { saveAs } from "file-saver";
// import * as XLSX from "xlsx";

const ModalCetakPo = ({ open, toggle, data }) => {
    const [alert, setAlert] = React.useState(null);
    const token = localStorage.token;
    const username = localStorage.username;
    const [address,setAddress] = useState("");
    const [counter,setCounter] = useState("");
    const [creator,setCreator] = useState("");
    const [keterangan,setKeterangan] = useState("");
    const [codereceiving,setCodeReceiving] = useState("");
    const [supplier,setSupplier] = useState("");
    const [transactiondate,setTransactionDate] = useState("");
    const [validator1,setValidator1] = useState("");
    const [warehouse,setWarehouse] = useState("");
    const [savedItems, setSavedItems] = useState([]);
    // const [item, setItem] = useState("")
    
    


  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-checker/${data.id}`,
        { headers }
      )
      .then( async data => {  
        setAddress(data.data.response.receivingpo.address)
        setCounter(data.data.response.receivingpo.counter)
        setCreator(data.data.response.receivingpo.creator)
        setKeterangan(data.data.response.receivingpo.keterangan)
        setCodeReceiving(data.data.response.receivingpo.receiving_code)
        setSupplier(data.data.response.receivingpo.supplier)
        setTransactionDate(data.data.response.receivingpo.transaction_date)
        setValidator1(data.data.response.receivingpo.validator1)
        setWarehouse(data.data.response.receivingpo.warehouse)
        // setItem(data.data.response.list.length);

      })
      .catch(function (error) {
        console.log(error);
      });
  },[token, data.id]);

  const HitCounter = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/receiving-po/hit-counter-checker/${data.id}`,
        { headers }
      )
      .then((data) => {  
        console.log("suskes Cuk");
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-checker/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                satuan: data.satuan,
                data: {
                    item_id: data.item_id,
                    item_name:data.item_name,
                    item_code:data.item_code,
                    qty: data.qty,
                    satuan: data.satuan,
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token, data.id])

useEffect(() => {
  getById();
  getItemDataSaved();
}, [getById,getItemDataSaved]);

const handleDownload = async () => {
  try {
    // Mengambil data dari API
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-checker/${data.id}`);
    const responseData = response.data.response;
    const { address } = responseData.receivingpo; // Mengambil address dari receivingpo
    const { supplier } = responseData.receivingpo; // Mengambil address dari receivingpo
    const list = responseData.list; // Mengambil data list dari response

    // Membaca file Excel yang diunggah
    const fileResponse = await fetch("https://api-erp.hokkybangunan.com/bpb.xlsx");
    const arrayBuffer = await fileResponse.arrayBuffer();

    // Load workbook
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(arrayBuffer);

    const worksheet = workbook.getWorksheet(1); // Mengambil worksheet pertama

    // Menulis alamat ke sel I12
    worksheet.getCell('I12').value = address;
    worksheet.getCell('I11').value = supplier;

    // Menulis data dari list ke dalam worksheet
    list.forEach((item, index) => {
      const rowNumber = 23 + index; // Mulai dari baris ke-15
      worksheet.getCell(`D${rowNumber}`).value = item.item_code;  // Kolom A untuk item_code
      worksheet.getCell(`B${rowNumber}`).value = item.item_name;  // Kolom B untuk item_name
      worksheet.getCell(`G${rowNumber}`).value = item.qty;        // Kolom C untuk qty
      worksheet.getCell(`H${rowNumber}`).value = item.satuan;     // Kolom D untuk satuan
    });

    // Menyimpan workbook ke buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Membuat blob dari buffer dan mengunduh file
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, "BPBCOK.xlsx");

    // Tampilkan alert sukses
    setAlert(
      <SweetAlert
        success
        title="Berhasil"
        onConfirm={() => setAlert(null)}
      >
        File Excel berhasil diunduh!
      </SweetAlert>
    );
  } catch (error) {
    console.error("Error", error);
    setAlert(
      <SweetAlert
        danger
        title="Error"
        onConfirm={() => setAlert(null)}
      >
        Terjadi kesalahan. Silakan coba lagi.
      </SweetAlert>
    );
  }
};

// const handleDownload = () => {
//   // Mengambil data dari API
//   axios
//       .get(
//           `${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-checker/${data.id}`,
//           { headers: { /* tambahkan header jika diperlukan */ } }
//       )
//       .then((response) => {
//           // Ekstrak data dari response API
//           const responseData = response.data.response.receivingpo;
//           const {
//               address,
//           } = responseData;

//           // Membaca file Excel yang diunggah
//           fetch("http://localhost:3000/bpb.xls")
//               .then(response => response.arrayBuffer())
//               .then(arrayBuffer => {
//                   const originalWorkbook = XLSX.read(arrayBuffer, { type: "array" });
                  
//                   // Buat salinan dari workbook asli
//                   const workbookCopy = JSON.parse(JSON.stringify(originalWorkbook));
//                   const worksheet = workbookCopy.Sheets[workbookCopy.SheetNames[0]];

//                   // Menulis data ke workbook salinan
//                   worksheet['I12'] = { v: address }; // Misalnya memasukkan address ke sel I12

//                   // Konversi workbook yang dimodifikasi ke file Excel dan unduh
//                   const excelBuffer = XLSX.write(workbookCopy, {
//                       bookType: "xls",
//                       type: "array",
//                   });
//                   const file = new Blob([excelBuffer], {
//                       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
//                   });

//                   saveAs(file, "BPBCOK.xls"); // Unduh file yang diperbarui

//                   // Tampilkan alert sukses
//                   setAlert(
//                       <SweetAlert
//                           success
//                           title="Berhasil"
//                           onConfirm={() => setAlert(null)}
//                       >
//                           File Excel berhasil diunduh!
//                       </SweetAlert>
//                   );
//               })
//               .catch(error => {
//                   console.error("Error reading the file", error);
//                   setAlert(
//                       <SweetAlert
//                           danger
//                           title="Error"
//                           onConfirm={() => setAlert(null)}
//                       >
//                           Gagal membaca file Excel. Silakan coba lagi.
//                       </SweetAlert>
//                   );
//               });
//       })
//       .catch((error) => {
//           console.error("Error fetching data", error);
//           setAlert(
//               <SweetAlert
//                   danger
//                   title="Error"
//                   onConfirm={() => setAlert(null)}
//               >
//                   Gagal mengambil data dari server. Silakan coba lagi.
//               </SweetAlert>
//           );
//       });
// };

// const handleDownload = () => {
//   axios
//     .get(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-checker/${data.id}`)
//     .then((response) => {
//       const responseData = response.data.response.receivingpo;
//       const { address } = responseData;

//       fetch("http://localhost:3000/bpb.xls")
//         .then(response => response.arrayBuffer())
//         .then(arrayBuffer => {
//           const workbook = XLSX.read(arrayBuffer, { type: "array" });
//           const worksheet = workbook.Sheets[workbook.SheetNames[0]];

//           // Menjaga format sel
//           if (worksheet['I12']) {
//             worksheet['I12'].v = address; // Mempertahankan format dan hanya mengubah nilai
//           } else {
//             worksheet['I12'] = { v: address, t: 's' }; // 't' adalah tipe sel ('s' untuk string)
//           }

//           const excelBuffer = XLSX.write(workbook, {
//             bookType: "xls",
//             type: "array",
//           });
//           const file = new Blob([excelBuffer], {
//             type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
//           });

//           saveAs(file, "BPBCOK.xls");

//           setAlert(
//             <SweetAlert
//               success
//               title="Berhasil"
//               onConfirm={() => setAlert(null)}
//             >
//               File Excel berhasil diunduh!
//             </SweetAlert>
//           );
//         })
//         .catch(error => {
//           console.error("Error reading the file", error);
//           setAlert(
//             <SweetAlert
//               danger
//               title="Error"
//               onConfirm={() => setAlert(null)}
//             >
//               Gagal membaca file Excel. Silakan coba lagi.
//             </SweetAlert>
//           );
//         });
//     })
//     .catch((error) => {
//       console.error("Error fetching data", error);
//       setAlert(
//         <SweetAlert
//           danger
//           title="Error"
//           onConfirm={() => setAlert(null)}
//         >
//           Gagal mengambil data dari server. Silakan coba lagi.
//         </SweetAlert>
//       );
//     });
// };

// const handleDownload = async () => {
//   try {
//     const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-checker/${data.id}`);
//     const responseData = response.data.response.receivingpo;
//     const { address } = responseData;
//     const { supplier } = responseData;

//     const fileResponse = await fetch("http://localhost:3000/bpb.xlsx");
//     const arrayBuffer = await fileResponse.arrayBuffer();

//     const workbook = new ExcelJS.Workbook();
//     await workbook.xlsx.load(arrayBuffer);

//     const worksheet = workbook.getWorksheet(1); // Ambil worksheet pertama

//     // Menulis data ke sel
//     const cell = worksheet.getCell('I12');
//     const suppliercok = worksheet.getCell('I11');
//     cell.value = address;
//     suppliercok.value = supplier;

//     // Simpan workbook ke buffer
//     const buffer = await workbook.xlsx.writeBuffer();

//     // Buat blob dari buffer dan unduh file
//     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
//     saveAs(blob, "BPBCOK.xlsx");

//     // Tampilkan alert sukses
//     setAlert(
//       <SweetAlert
//         success
//         title="Berhasil"
//         onConfirm={() => setAlert(null)}
//       >
//         File Excel berhasil diunduh!
//       </SweetAlert>
//     );
//   } catch (error) {
//     console.error("Error", error);
//     setAlert(
//       <SweetAlert
//         danger
//         title="Error"
//         onConfirm={() => setAlert(null)}
//       >
//         Terjadi kesalahan. Silakan coba lagi.
//       </SweetAlert>
//     );
//   }
// };




// const handleDownload = () => {
//   // Mengambil data dari API
//   axios
//       .get(
//           `${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-checker/${data.id}`,
//           { headers: { /* tambahkan header jika diperlukan */ } }
//       )
//       .then((response) => {
//           // Ekstrak data dari response API
//           const responseData = response.data.response.receivingpo;
//           const {
//               address,
//           } = responseData;

//           // Membaca file Excel yang diunggah

//           // const MODEL_URL = process.env.PUBLIC_URL + "/models";
//           fetch("http://localhost:3000/bpb.xls")
//               .then(response => response.arrayBuffer())
//               .then(arrayBuffer => {
//                   const workbook = XLSX.read(arrayBuffer, { type: "array" });
//                   const worksheet = workbook.Sheets[workbook.SheetNames[0]];

//                   // Menulis data ke file Excel yang sudah ada
//                   worksheet['I12'] = { v: address }; // Misalnya memasukkan address ke sel A1
//                   // worksheet['A2'] = { v: counter }; // Memasukkan counter ke sel A2
//                   // worksheet['A3'] = { v: creator }; // Dan seterusnya
//                   // worksheet['A4'] = { v: keterangan };
//                   // worksheet['A5'] = { v: receiving_code };
//                   // worksheet['A6'] = { v: supplier };
//                   // worksheet['A7'] = { v: transaction_date };
//                   // worksheet['A8'] = { v: validator1 };
//                   // worksheet['A9'] = { v: warehouse };

//                   // Konversi workbook ke file Excel dan unduh
//                   const excelBuffer = XLSX.write(workbook, {
//                       bookType: "xlsx",
//                       type: "array",
//                   });
//                   const file = new Blob([excelBuffer], {
//                       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
//                   });

//                   saveAs(file, "BPBCOK.xls"); // Unduh file yang diperbarui

//                   // Tampilkan alert sukses
//                   setAlert(
//                       <SweetAlert
//                           success
//                           title="Berhasil"
//                           onConfirm={() => setAlert(null)}
//                       >
//                           File Excel berhasil diunduh!
//                       </SweetAlert>
//                   );
//               })
//               .catch(error => {
//                   console.error("Error reading the file", error);
//                   setAlert(
//                       <SweetAlert
//                           danger
//                           title="Error"
//                           onConfirm={() => setAlert(null)}
//                       >
//                           Gagal membaca file Excel. Silakan coba lagi.
//                       </SweetAlert>
//                   );
//               });
//       })
//       .catch((error) => {
//           console.error("Error fetching data", error);
//           setAlert(
//               <SweetAlert
//                   danger
//                   title="Error"
//                   onConfirm={() => setAlert(null)}
//               >
//                   Gagal mengambil data dari server. Silakan coba lagi.
//               </SweetAlert>
//           );
//       });
// };

// const downloadExcel = async ()=> {
//   const J = "BPB-COK"
//   const fileName = J;
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`,
//   };
//   axios
//     .get(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-checker/${data.id}`,{headers})
//     .then((data) => {
//         setAddress(data.data.response.receivingpo.address)
//         setCounter(data.data.response.receivingpo.counter)
//         setCreator(data.data.response.receivingpo.creator)
//         setKeterangan(data.data.response.receivingpo.keterangan)
//         setCodeReceiving(data.data.response.receivingpo.receiving_code)
//         setSupplier(data.data.response.receivingpo.supplier)
//         setTransactionDate(data.data.response.receivingpo.transaction_date)
//         setValidator1(data.data.response.receivingpo.validator1)
//         setWarehouse(data.data.response.receivingpo.warehouse)
//       var apiPo = data.data.response.list;

//       // var tglcetak = data.data.response.download_date;
//       var tableToExcel = (function() {
//           var uri = 'data:application/vnd.ms-excel; base64,',
//           template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
//           base64 = function(s) {
//             return window.btoa(unescape(encodeURIComponent(s)))
//           },
//           format = function(s, c) {
//             return s.replace(/{(\w+)}/g, function(m, p) {
//               return c[p];
//             })
//           }
//         return function(table, name) {
//           var heading = 'BPB CHECKER' ;
//           var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
//           var po = '';
//           var a ;
//           for(a=0; a < apiPo.length; a++){
//             po += `
//             <tr style="border: 1px solid black">
//                 <td colspan="2">${apiPo[a].item_name}</td>
//                 <td colspan="2">${apiPo[a].item_code}</td>
//                 <td colspan="2"></td>
//                 <td colspan="2">${apiPo[a].satuan}</td>
//             </tr>
//             `
//           }
//           var table = `
//           <table id="account_table">
//               <tbody>
//               <tr>
//                   <td><img src="https://i.ibb.co/jkFYDqg/150x75.png"/> </td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>Hokky Bangunan</td>
//               </tr>
//               <tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>Jl Menganti Karangan No.562</td>
//               </tr>
//               <tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>Wiyung surabaya barat - Jawa Timur </td>
//               </tr>
//               <tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>Telp: 081 217 85 3300 </td>
//               </tr>
//               <tr>
//                   <td colspan="8"><center><b><h1>BPB CHECKER</h1></b><center></td>
//               </tr>
//               <tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//               </tr>
//               <tr>
//                   <td>Tanggal</td>
//                   <td>: ${transactiondate}</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>Customer</td>
//                   <td>: ${supplier}</td>
//               </tr>
//               <tr>
//                   <td>Kode BPB</td>
//                   <td>: ${codereceiving}</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>Alamat</td>
//                   <td>: ${address}</td>
//               <tr>
//               <tr>
//                   <td>Ket</td>
//                   <td rowspan="2">: ${keterangan}</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//               <tr>
//               <tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//               </tr>
//                 <tr style="border: 1px solid black">
//                     <th colspan="2">Nama Item</th>
//                     <th colspan="2">Kode Item</th>
//                     <th colspan="2">Qty</th>
//                     <th colspan="2">Satuan</th>
//                   </tr>
//                       ${po}
//               </tbody>
//           </table>
//           `;
//           var ctx = {
//               worksheet: name || 'WorkBook',
//               imgsrc1: imgsrc1,
//               heading: heading,
//               table: table
//           }
//           var blob = new Blob([format(template, ctx)]);
//           return blob;
//           }
//       })()
//       var blobURL = tableToExcel('account_table', 'BPB CHECKER');
//       FileSaver.saveAs(blobURL, fileName+'.xls');
      
//       })
//     .catch(function (error) {
//       // if(error.response.data.status == 404){
//           setGagalAlert();
//       // }
//     });
// }

// const downloadExcel = async () => {
//   const fileName = "BPB-CHECKER";
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`,
//   };

//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-checker/${data.id}`,
//       { headers }
//     );

//     const receivingPo = response.data.response.receivingpo;
//     const items = response.data.response.list;

//     const tableHeaders = `
//       <tr style="border-bottom: 2px solid black;">
//         <th style="text-align:left; padding: 5px;">NO</th>
//         <th style="text-align:left; padding: 5px;">KODE</th>
//         <th style="text-align:left; padding: 5px;">NAMA BARANG</th>
//         <th style="text-align:left; padding: 5px;">BRAND</th>
//         <th style="text-align:right; padding: 5px;">QTY</th>
//         <th style="text-align:left; padding: 5px;">SAT</th>
//         <th style="text-align:left; padding: 5px;">KETERANGAN</th>
//       </tr>`;

//     const tableRows = items.map((item, index) => `
//       <tr style="border-bottom: 1px solid black;">
//         <td style="padding: 5px;">${index + 1}</td>
//         <td style="padding: 5px;">${item.item_code}</td>
//         <td style="padding: 5px;">${item.item_name}</td>
//         <td style="padding: 5px;">${item.brand || "-"}</td>
//         <td style="padding: 5px; text-align: right;">${item.qty}</td>
//         <td style="padding: 5px;">${item.satuan}</td>
//         <td style="padding: 5px;">${item.keterangan || ""}</td>
//       </tr>`).join("");

//     const tableContent = `
//       <table style="width: 100%; border-collapse: collapse;">
//         <tr>
//                   <td><img src="https://i.ibb.co/jkFYDqg/150x75.png"/> </td>
//                   <td colspan="5"></td>
//                   <td colspan="1">Hokky Bangunan</td>
//               </tr>
//               <tr>
//                   <td colspan="6"></td>
//                   <td colspan="1">Jl Menganti Karangan No.562</td>
//               </tr>
//               <tr>
//                   <td colspan="6"></td>
//                   <td colspan="1">Wiyung surabaya barat - Jawa Timur </td>
//               </tr>
//               <tr>
//                   <td colspan="6"></td>
//                   <td colspan="1">Telp: 081 217 85 3300 </td>
//               </tr>
//               <tr>
//                   <td colspan="7"><center><b><h1>BPB CHECKER</h1></b><center></td>
//               </tr>
//                       <tr><td colspan="7">&nbsp;</td></tr>

//         <tr>
//           <td colspan="4" style="text-align:left;">Tanggal : ${receivingPo.transaction_date}</td>
//           <td colspan="3" style="text-align:right;">Supplier : ${receivingPo.supplier}</td>
//         </tr>
//         <tr>
//           <td colspan="4" style="text-align:left;">No BPB : ${receivingPo.receiving_code}</td>
//           <td colspan="3" style="text-align:right;">Alamat : ${receivingPo.address}</td>
//         </tr>
//         <tr>
//           <td colspan="4" style="text-align:left;">Keterangan : ${receivingPo.keterangan || ""}</td>
//           <td colspan="3" style="text-align:right;">&nbsp;</td>
//         </tr>
//         ${tableHeaders}
//         ${tableRows}
//         <tr><td colspan="7">&nbsp;</td></tr>
//         <tr>
//           <td colspan="2" style="text-align:left;">Di Buat : ${receivingPo.created_by}</td>
//           <td colspan="5" style="text-align:right;">Signature : ---------------</td>
//         </tr>
//       </table>`;

//     const worksheetName = "BPB CHECKER";
//     const template = `
//       <html xmlns:o="urn:schemas-microsoft-com:office:office"
//             xmlns:x="urn:schemas-microsoft-com:office:excel"
//             xmlns="http://www.w3.org/TR/REC-html40">
//       <head>
//         <meta charset="UTF-8">
//         <style>
//           @page {
//             size: A4;
//             margin: 1cm;
//           }
//           body { font-family: Arial, sans-serif; }
//           table { border-spacing: 0; }
//           th, td { padding: 8px 12px; }
//           th { border-bottom: 2px solid #000; }
//         </style>
//       </head>
//       <body>
//         ${tableContent}
//       </body>
//       </html>`;

//     const blob = new Blob([template], { type: "application/vnd.ms-excel" });
//     FileSaver.saveAs(blob, `${fileName}.xls`);
//   } catch (error) {
//     console.log(error);
//     setGagalAlert();
//   }
// };


// const setGagalAlert = (id) => {
//   setAlert(
//     <SweetAlert
//       danger
//       showConfirm
//       confirmBtnText="Ok"
//       title="Gagal Cok"
//       onCancel={hideAlert}
//       onConfirm={hideAlert}
//     />
//   );
// };

// const hideAlert = () => {
//   setAlert(null);
// };

  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    // console.log(item)
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }

  // function clickItem(e) {
  //   setItem(e.target.value)
  // }
  
  return (
    <>
    {/* {alert} */}
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <div style={  */}
                    <div style={{ textAlign: 'right' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                        <Button color="info" onClick={() => {print();HitCounter();setTimeout(() => (window.location.reload("/admin/receiving-po/page")), 1000)}}>Print</Button>
                        <Button color="success" onClick={()=> {handleDownload();HitCounter()}}>Excel</Button>
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
      <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
      </ModalBody>
    </Modal>
    </>
  );

  function renderPDF(){
    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []
    // for (let index = 0; index < sheet; index++) {
    //   let start = (item * index)
    //   let end = length
    //   let sisa = (length % item)
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(index < (sheet - 1) && sheet == 2){
    //       end = item
    //     }else{
    //       if (index == (sheet - 1)) {
    //         end = length - sisa + (index * item)
    //       }else{
    //         if (index == 0) {
    //           end = item
    //         }else{
    //           end = (index+1) * item
    //         }
    //       }
    //     }
    //   } 
    //   let data = savedItems.slice(start,end)
    const itemsPerPage = 25; // Maksimal 7 item per halaman
    const totalItems = savedItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Hitung total halaman
  
    const sheets = [];
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      // Hitung indeks awal dan akhir untuk slice item per halaman
      const start = pageIndex * itemsPerPage;
      const end = start + itemsPerPage;
  
      // Ambil item untuk halaman saat ini
      const currentItems = savedItems.slice(start, end);
      // console.log(data.length)
      // let sub_total = 0;
      // let diskon = 0;
      // let pajak = 0;
      let usernamecok = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
      sheets.push(
        <div className='pagebreak'>
          {alert}
          <div className="col">
            <KopSurat warehouseName={warehouse} />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <Row md="12">
              <Col md="12">
                  <h3 style={{ color: "black" }}><b><center>CHECK BPB</center></b></h3>
              </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          
          <Row md="12">
            <Col style={{marginLeft:"2%",width:"50%",flexBasis: "unset"}}>
             
              <div className="row align-items-center ">
                  <div style={{width:"17%",color:"black",fontSize:"14px"}}  className="text-table">Tanggal</div>
                  <div style={{width:"2%",color:"black",fontSize:"14px"}}  className="text-center text-table">:</div>
                  <div style={{width:"60%",color:"black",fontSize:"14px"}}  className="text-table">
                      <b>{transactiondate}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"17%",color:"black",fontSize:"14px"}} className="text-table">No BPB</div>
                  <div style={{width:"2%",color:"black",fontSize:"14px"}} className="text-center text-table">:</div>
                  <div style={{width:"60%",color:"black",fontSize:"14px"}} className="text-table">
                      <b>{codereceiving}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"17%",color:"black",fontSize:"14px"}}  className="text-table">keterangan </div>
                  <div style={{width:"2%",color:"black",fontSize:"14px"}}  className="text-center text-table">:</div>
                  <div style={{width:"60%",color:"black",fontSize:"14px"}}  className="text-table">
                      <b>{keterangan}</b>
                  </div>
              </div>
            </Col>
            <Col style={{width:"30%",flexBasis: "unset"}}>
              <div className="row align-items-center ">
                  <div style={{width:"30%",color:"black",fontSize:"14px"}} className="text-table">Supplier</div>
                  <div style={{width:"5%",color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"65%" ,color:"black",fontSize:"14px"}} className="text-table" >
                      <b>{supplier}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"30%" ,color:"black",fontSize:"14px"}} className="text-table">Alamat</div>
                  <div style={{width:"5%" ,color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"65%" ,color:"black",fontSize:"14px"}} className="text-table">
                      <b>{address}</b>
                  </div>
              </div>
            </Col>
          </Row>
          <br></br>
              <Table size="sm" style={{width:"100%"}} responsive>
                <thead>
                <tr style={{ border: "1px solid black" }}>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>No</b>
                    </th>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Kode</b>
                    </th>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Nama Barang</b>
                    </th>
                    {/* <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Brand</b>
                    </th> */}
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Qty</b>
                    </th>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Sat</b>
                    </th>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Keterangan</b>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    currentItems.map((temp,index,key) => {
                        // let item_name =  temp.data.item_name
                        // sub_total = sub_total + temp.sub_total
                        // diskon = diskon + temp.diskon_nominal
                        return (
                            <tr style={{ border: "1px solid black" }}>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{index+1}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.item_code}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.item_name}</td>
                                {/* <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>-</td> */}
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.qty}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.satuan}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.note}</td>
                            </tr>
                        );
                    })
                }
                </tbody>
              </Table>
              <div ></div>
              <Row md="12" />
          <div className="w-100 text-table" style={{ border: "0.5px solid black" }}></div>
          <center className="text-table" style={{color:"black",fontSize:"14px"}}>Terms of Price, delivery & shipping required</center>
          <Row md="20">
            <Col style={{ marginLeft:"2%",width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table" style={{color:"black",fontSize:"14px"}}>Di Buat</div>
                  <div className="col-1 text-center text-table" style={{color:"black",fontSize:"14px"}}>:</div>
                  <div className="col-8 text-table" style={{color:"black",fontSize:"14px"}}>
                      <b>{creator}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table" style={{color:"black",fontSize:"14px"}}>Signature</div>
                  <div className="col-1 text-center text-table" style={{color:"black",fontSize:"14px"}}>:</div>
                  <div className="col-8 text-table" style={{color:"black",fontSize:"14px"}}>
                      <b>------------------</b>
                  </div>
              </div>
            </Col> 
            <Col style={{ width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table" style={{color:"black",fontSize:"14px"}}>Kepala Gudang</div>
                  <div className="col-1 text-center text-table" style={{color:"black",fontSize:"14px"}}>:</div>
                  <div className="col-8 text-table" style={{color:"black",fontSize:"14px"}}>
                      <b>{validator1}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table" style={{color:"black",fontSize:"14px"}}>Signature</div>
                  <div className="col-1 text-center text-table" style={{color:"black",fontSize:"14px"}}>:</div>
                  <div className="col-8 text-table" style={{color:"black",fontSize:"14px"}}>
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
          </Row>
          <div className="w-100" style={{ border: "0.5px solid black" }}></div>
                      {/* {counter === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download {counter} Kali Oleh {username}</i></div>
                    </div>
                      )
                      
                      } */}
          {/* <div className="divFooter">{usernamecok}</div> */}
          <div className="divFooter" style={{ fontSize:"12px" }}><i>{usernamecok}</i>, {counter === 0 ? (
                        // <div className="row align-items-center mb-3">
                        <i>,Download Original</i>
                          // </div>
                        ):(
                        // <div className="row align-items-center mb-3">
                        <i>,Di Download {counter} Kali</i>
                      // </div>
                      )
                    }</div>
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }
};

export default ModalCetakPo;
