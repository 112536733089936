import React, { useCallback,useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
import moment from "moment";

const ModalCetakPo = ({ open, toggle, data }) => {
    const token = localStorage.token;
    const username = localStorage.username;
    const [savedItems, setSavedItems] = useState([]);
    const [download, setDownload] = useState("")
    const [codepo, setCodePo] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [supplier, setSupplier] = useState("");
    const [warehouserfq, setWarehouseRfq] = useState("");
    const [ongkir, setOngkir] = useState(0);
    const [waktu,setWaktu] = useState([]);
    const [ppn, setPPN] = useState("")
    const [hargatotal, setHargaTotal] = useState([]);
    const [validator, setValidator] = useState("");
    const [validator1, setValidator1] = useState("");
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [bankname, setBankName] = useState("")
    const [payment, setPayment] = useState("")
    const [rekening, setRekening] = useState("")
    const [pricereal, setPriceReal]= useState("")
    const [diskontotal, setDiskonTotal] = useState("")
    const [pajak, setPajak] = useState("");
    const [creator, setCreator] = useState("")
    const [dp, setDp] = useState(0);
    
    const [codeRFQ, setCodeRFQ] = useState("")

    // const [item, setItem] = useState("")

  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/purchase-order/cetak/${data.id}`,
        { headers }
      )
      .then((data) => {  
        setAddress(data.data.response.po.address)
        setBankName(data.data.response.po.bank_name1)
        setPhone(data.data.response.po.phone)
        setSupplier(data.data.response.po.person);
        setDownload(data.data.response.po.counter_pusat);
        setWaktu(data.data.response.po.transaction_date)
        setWarehouseRfq(data.data.response.po.warehouse);
        setDiskonTotal(data.data.response.po.diskon_total);
        setPajak(data.data.response.po.nominal_pajak);
        setHargaTotal(data.data.response.po.payment_total);
        setPriceReal(data.data.response.po.price_total);
        setKeterangan(data.data.response.po.keterangan);
        setOngkir(data.data.response.po.ongkir);
        setDp(data.data.response.po.um_nominal);
        setPayment(data.data.response.po.payment_method)
        setCodePo(data.data.response.po.po_code);
        setValidator(data.data.response.po.validator1);
        setValidator1(data.data.response.po.validator2);
        setRekening(data.data.response.po.bank_number1);
        setCreator(data.data.response.po.creator);
        setPPN(data.data.response.po.persen_pajak)
        setCodeRFQ(data.data.response.po.code_rfq);
        // getItemDataSaved(data.data.response.po.id)
        // setItem(data.data.response.list.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  },[token,data.id]);

  const HitCounter = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/purchase-order/hit-counter-pusat/${data.id}`,
        { headers }
      )
      .then((data) => {  
        console.log("suskes Cuk");
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/cetak/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                harga: data.harga,
                satuan: data.satuan,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                sub_total : data.sub_total,
                note : data.note,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token,data.id])

useEffect(() => {
  getById();
  getItemDataSaved();
}, [getById, getItemDataSaved]);

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }
    

  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    // console.log(item)
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }
  
  // function clickItem(e) {
  //   setItem(e.target.value)
  // }
  
  return (
    <div>
      {/* {alert} */}
       <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <div style={{ textAlign: 'left' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            Set Item
                        </Label>
                        <Input type="number" pattern="[0-9]*" value={item} min="1" max={savedItems.length} onChange={clickItem} style={{width: '70px',marginRight:'50px'}}/>
                    </div> */}
                    <div style={{ textAlign: 'right' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                        <Button color="info" onClick={() => {
                          print();
                          HitCounter();
                          setTimeout(() => (window.location.reload("/admin/pusat/purchase-order/histori")), 1000)}
                        }>Print</Button>
                        {/* <Button color="success" onClick={()=> (downloadExcel(),HitCounter())}>Excel</Button> */}
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
        <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
      </ModalBody>
    </Modal>
    </div>
  );

  function renderPDF(){
    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []
    // for (let index = 0; index < sheet; index++) {
    //   let start = (item * index)
    //   let end = length
    //   let sisa = (length % item)
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(index < (sheet - 1) && sheet == 2){
    //       end = item
    //     }else{
    //       if (index == (sheet - 1)) {
    //         end = length - sisa + (index * item)
    //       }else{
    //         if (index == 0) {
    //           end = item
    //         }else{
    //           end = (index+1) * item
    //         }
    //       }
    //     }
    //   } 
    const itemsPerPage = 10; // Maksimal 7 item per halaman
    const totalItems = savedItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Hitung total halaman
  
    const sheets = [];
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      // Hitung indeks awal dan akhir untuk slice item per halaman
      const start = pageIndex * itemsPerPage;
      const end = start + itemsPerPage;
  
      // Ambil item untuk halaman saat ini
      const currentItems = savedItems.slice(start, end);
      // let data = savedItems.slice(start,end)
      let usernamecok = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
      sheets.push(
        <div className='pagebreak'>
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid black", color:"black" }}></div>
          <Row md="12">
              <Col md="12">
                  <h3 style={{ color: "black" }}><b><center>PURCHASE ORDER</center></b></h3>
              </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid black", color:"black" }}></div>
          
          <Row md="12">
            <Col style={{marginLeft:"2%",width:"40%",flexBasis: "unset"}}>
             
              <div className="row align-items-center ">
                  <div style={{width:"18%", color:"black",fontSize:"14px"}}  className="text-table">Tanggal</div>
                  <div style={{width:"2%", color:"black",fontSize:"14px"}}  className="text-center text-table">:</div>
                  <div style={{width:"50%", color:"black",fontSize:"14px"}}  className="text-table">
                      <b>{waktu}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"18%", color:"black",fontSize:"14px"}} className="text-table">No PO</div>
                  <div style={{width:"2%", color:"black",fontSize:"14px"}} className="text-center text-table">:</div>
                  <div style={{width:"50%", color:"black",fontSize:"14px"}} className="text-table">
                      <b>{codepo}</b>
                  </div>
              </div>
              {codeRFQ === null ? 
              ''
              :
              <div className="row align-items-center ">
                  <div style={{width:"18%", color:"black",fontSize:"14px"}}  className="text-table">No RFQ</div>
                  <div style={{width:"2%", color:"black",fontSize:"14px"}}  className="text-center text-table">:</div>
                  <div style={{width:"50%", color:"black",fontSize:"14px"}}  className="text-table">
                      <b>{codeRFQ}</b>
                  </div>
              </div>
              }
              <div className="row align-items-center ">
                  <div style={{width:"18%", color:"black",fontSize:"14px"}} className="text-table">Pembayaran</div>
                  <div style={{width:"2%", color:"black",fontSize:"14px"}} className="text-center text-table">:</div>
                  <div style={{width:"50%", color:"black",fontSize:"14px"}} className="text-table">
                    {payment === 1 ? 
                    <b>Lunas</b>
                    : payment === 2 ? 
                    <b>Tempo</b>
                    :
                    <b>Dp</b>
                  }
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"18%", color:"black",fontSize:"14px"}}  className="text-table">keterangan </div>
                  <div style={{width:"2%", color:"black",fontSize:"14px"}}  className="text-center text-table">:</div>
                  <div style={{width:"50%", color:"black",fontSize:"14px"}}  className="text-table">
                      <b>{keterangan}</b>
                  </div>
              </div>
            </Col>
            <Col style={{width:"40%",flexBasis: "unset"}}>
              <div className="row align-items-center ">
                  <div style={{width:"17%", color:"black",fontSize:"14px"}} className="text-table">Supplier</div>
                  <div style={{width:"5%", color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"65%", color:"black",fontSize:"14px"}} className="text-table">
                      <b>{supplier}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"17%", color:"black",fontSize:"14px"}} className="text-table">Alamat</div>
                  <div style={{width:"5%", color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"65%", color:"black",fontSize:"14px"}} className="text-table">
                      <b>{address}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"17%", color:"black",fontSize:"14px"}} className="text-table">Kontak</div>
                  <div style={{width:"5%", color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"65%" , color:"black",fontSize:"14px"}} className="text-table">
                      <b>{phone}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"17%" , color:"black",fontSize:"14px"}} className="text-table">Rekening</div>
                  <div style={{width:"5%", color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"65%", color:"black",fontSize:"14px"}} className="text-table">
                      <b>{bankname} {rekening}</b>
                  </div>
              </div>
            </Col>
          </Row>
          <br></br>
              <Table size="sm" style={{width:"100%"}} responsive>
                <thead>
                  <tr style={{ border: "1px solid black" }}>
                      <th className="text-table" style={{color:"black",border: "1px solid black"  }}>
                      <b>No</b>
                      </th>
                      <th className="text-table" style={{color:"black",border: "1px solid black"  }}>
                      <b>Kode</b>
                      </th>
                      <th className="text-table" style={{color:"black",border: "1px solid black"  }}>
                      <b>Nama Barang</b>
                      </th>
                      {/* <th className="text-table" style={{color:"black",border: "1px solid black"  }}>
                      <b>Brand</b>
                      </th> */}
                      <th className="text-table" style={{color:"black",border: "1px solid black"  }}>
                      <b>Harga</b>
                      </th>
                      <th className="text-table" style={{color:"black",border: "1px solid black"  }}>
                      <b>Qty</b>
                      </th>
                      <th className="text-table" style={{color:"black",border: "1px solid black"  }}>
                      <b>Sat</b>
                      </th>
                      <th className="text-table" style={{color:"black",border: "1px solid black"  }}>
                      <b>Disc %</b>
                      </th>
                      <th className="text-table" style={{color:"black",border: "1px solid black"  }}>
                      <b>Disc N</b>
                      </th>
                      <th className="text-table" style={{color:"black",border: "1px solid black"  }}>
                      <b>Total</b>
                      </th>
                      <th className="text-table" style={{color:"black",border: "1px solid black"  }}>
                      <b>Note</b>
                      </th>
                      {/* <th className="text-table" style={{color:"black"}}>
                      <b>Keterangan</b>
                      </th> */}
                  </tr>
                </thead>
                <tbody>
                {currentItems.map((temp,index, key) => {
                        return (
                            <tr style={{ border: "1px solid black" }}>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{index+1}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.item_code}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.item_name}</td>
                                {/* <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>-</td> */}
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{formatRupiah(temp.harga / (ppn/100 + 100/100))}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.qty}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.satuan}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.diskon_persen}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{formatRupiah(temp.diskon_nominal)}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>
                                  {/* {formatRupiah(temp.sub_total)} */}
                                  {formatRupiah(((temp.harga - temp.diskon_nominal) * temp.qty) / (ppn/100 + 100/100))}
                                </td>
                                <td className="text-table" style={{color:"black",border: "1px solid black"}}>{temp.note}</td>

                                {/* <td className="text-table" style={{color:"black"}}></td> */}
                            </tr>
                        );
                    })
                }
                  <tr className="w-100 text-table" style={{ border: "1px solid black" }}>
                    {/* <td colSpan="10" style={{color:"black",border: "none"}}></td> */}
                  </tr>
                  <tr className="no-border">
                    <td colspan="6" className="border-nons text-table" style={{color:"black",border: "none"}}></td>
                    <td colspan="2" className="text-right text-table border-nons" style={{color:"black",border: "none"}}>Sub Total
                    </td>
                    <td className="text-left text-table border-nons" style={{color:"black",border: "none"}}>
                          <b>{formatRupiah(pricereal)}</b>
                    </td>
                    <td className="border-non text-table" style={{color:"black",border: "none"}}></td>
                  </tr>
                  <tr>
                    <td colspan="6" className="border-nons text-table" style={{color:"black",border: "none"}}></td>
                    <td colspan="2" className="text-right text-table border-nons" style={{color:"black",border: "none"}}>Diskon
                    </td>
                    <td className="text-left text-table border-nons" style={{color:"black",border: "none"}}>
                          <b>{formatRupiah(diskontotal)}</b>
                    </td>
                    <td className="border-non text-table" style={{color:"black",border: "none"}}></td>
                  </tr>
                  <tr>
                    <td colspan="6" className="border-non text-table" style={{color:"black",border: "none"}}></td>
                    <td colspan="2" className="text-right text-table border-non" style={{color:"black",border: "none"}}>PPN
                    </td>
                    <td className="text-left text-table border-non" style={{color:"black",border: "none"}}>
                          <b>{formatRupiah(pajak)}</b>
                    </td>
                    <td className="border-non text-table" style={{color:"black",border: "none"}}></td>
                  </tr>
                  <tr>
                    <td colspan="6" className="border-non text-table" style={{color:"black",border: "none"}}></td>
                    <td colspan="2" className="text-right text-table border-non" style={{color:"black",border: "none"}}>Ongkir
                    </td>
                    <td className="text-left text-table border-non" style={{color:"black",border: "none"}}>
                          <b>{formatRupiah(ongkir)}</b>
                    </td>
                    <td className="border-non text-table" style={{color:"black",border: "none"}}></td>
                  </tr>
                  {dp === 0 ? 
                    ""
                    :
                    <tr>
                      <td colspan="6" className="border-non text-table" style={{color:"black",border: "none"}}></td>
                      <td colspan="2" className="text-right text-table border-non" style={{color:"black",border: "none"}}>Uang Muka
                      </td>
                      <td className="text-left text-table border-non" style={{color:"black",border: "none"}}>
                            <b>{formatRupiah(dp)}</b>
                      </td>
                      <td className="border-non text-table" style={{color:"black",border: "none"}}></td>
                    </tr>
                  }
                  <tr>
                    <td colspan="6" className="border-non text-table" style={{color:"black",border: "none"}}></td>
                    <td colspan="2" className="text-right text-table border-non" style={{color:"black",border: "none"}}>Grand Total
                    </td>
                    <td className="text-left text-table border-non" style={{color:"black",border: "none"}}>
                          <b>{formatRupiah(hargatotal)}</b>
                    </td>
                    <td className="border-non text-table" style={{color:"black",border: "none"}}></td>
                  </tr>
                </tbody>
              </Table>
              <div ></div>
              <Row md="12">
              </Row>
          <div className="w-100 text-table" style={{ border: "0.5px solid #b3b3b3" }}></div>
          <center className="text-table" style={{color:"black"}}>Terms of Price, delivery & shipping required</center>
          <Row md="20">
            <Col style={{ marginLeft:"2%",width:"25%" }}>
              <div className="row align-items-center " style={{color:"black",fontSize:"14px"}}>
                  <div className="col-3 text-table" style={{color:"black",fontSize:"14px"}}>Di Buat</div>
                  <div className="col-1 text-center text-table" style={{color:"black",fontSize:"14px"}}>:</div>
                  <div className="col-2 text-table" style={{color:"black",fontSize:"14px"}}>
                      <b>{creator}</b>
                  </div>
              </div>
              <div className="row align-items-center " style={{color:"black",fontSize:"14px"}}>
                  <div className="col-3 text-table" style={{color:"black",fontSize:"14px"}}>Signature</div>
                  <div className="col-1 text-center text-table" style={{color:"black",fontSize:"14px"}}>:</div>
                  <div className="col-2 text-table" style={{color:"black",fontSize:"14px"}}>
                      <b></b>
                  </div>
              </div>
            </Col>
            <Col style={{ width:"45%" }}>
              <div className="row align-items-center " style={{color:"black",fontSize:"14px"}}>
                  <div className="col-6 text-table" style={{color:"black",fontSize:"14px"}}>Head Purchasing</div>
                  <div className="col-1 text-center text-table" style={{color:"black",fontSize:"14px"}}>:</div>
                  <div className="col-5 text-table" style={{color:"black",fontSize:"14px"}}>
                      <b>{validator}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-6 text-table" style={{color:"black",fontSize:"14px"}}>Signature</div>
                  <div className="col-1 text-center text-table" style={{color:"black",fontSize:"14px"}}>:</div>
                  <div className="col-2 text-table" style={{color:"black",fontSize:"14px"}}>
                      <b></b>
                  </div>
              </div>
            </Col>
            <Col style={{ width:"40%" }}>
              <div className="row align-items-center " style={{color:"black",fontSize:"14px"}}>
                  <div className="col-3 text-table" style={{color:"black",fontSize:"14px"}}>Direktur</div>
                  <div className="col-1 text-center text-table" style={{color:"black",fontSize:"14px"}}>:</div>
                  <div className="col-8 text-table" style={{color:"black",fontSize:"14px"}}>
                      <b>{validator1}</b>
                  </div>
              </div>
              <div className="row align-items-center " style={{color:"black",fontSize:"14px"}}>
                  <div className="col-3 text-table" style={{color:"black",fontSize:"14px"}}>Signature</div>
                  <div className="col-1 text-center text-table" style={{color:"black",fontSize:"14px"}}>:</div>
                  <div className="col-8 text-table" style={{color:"black",fontSize:"14px"}}>
                      <b></b>
                  </div>
              </div>
            </Col>
          </Row>
          
          <div className="w-100" style={{ border: "0.5px solid #b3b3b3",fontSize:"14px" }}></div>
                      {/* {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download {download} Kali Oleh {username}</i></div>
                    </div>
                      )
                    } */}
                    <div className="divFooter" style={{ fontSize:"12px" }}><i>{usernamecok}</i>, {download === 0 ? (
                        // <div className="row align-items-center mb-3">
                        <i>,Download Original</i>
                          // </div>
                        ):(
                        // <div className="row align-items-center mb-3">
                        <i>,Di Download {download} Kali</i>
                      // </div>
                      )
                    }</div>
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }
  
};

export default ModalCetakPo;
