/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Badge,
    CardBody, 
    CardHeader, 
    // ButtonGroup,
    Form,
    Label,
    Col,
    Input,
    FormGroup,
    Container,
    Modal, ModalHeader, ModalBody,
    // DropdownItem 
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from 'views/404';
import Select2 from "react-select2-wrapper";

const HistoryKasir = () => {
    const [alert, setAlert] = useState(null);
    const [datalog, setDataLog] = useState([])
    const [rowIndex, setRowIndex] = useState(0);
    const toggle2 = () => setModal1(!modal1);
    const [modal1, setModal1] = useState(false);
    const token = localStorage.token;
    const [tanggal, setTanggal] = useState("")
    const [persons, setPersons] = useState([])
    const [uomCode, setUomCode] = useState("")
    const [description, setDescription] = useState("")
    const username = localStorage.username;
    const warehouse = parseInt(localStorage.warehouse);
    const redirectPrefix = `/admin/pos/history/detail/`;
//   const redirectPrefix1 = `/admin/sales-order/so-penawaran/detail/`
    const [allHistoryPos, setAllHistoryPos] = useState([]);
    const [status, setStatus] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerpage] = useState(10);
    const [totalItem, setTotalItem] = useState(0);
    const [currentSort, setCurrentSort] = useState("");
    const allInfo = JSON.parse(localStorage.allinfo);
    const Detail = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Pelunasan Cashier").map((p) => p.read_access));
    const buttonpelunasan = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Pelunasan Cashier").map((p) => p.update_access));
  
  useEffect(() => {
    getPerson();
    getPosHistory(page, perPage, currentSort);
  }, []);

  const getPerson = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/customer/list`, { headers
      })
      .then(data => {
        setPersons(data.data.response);
      })
        .catch(function (error) {
          console.log(error)
        })
    }


  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, tanggal, uomCode, description);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort, tanggal, uomCode, description)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, tanggal, uomCode, description) => {
    getPosHistory(page, perPage, sort, tanggal, uomCode, description);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal)
    setUomCode(uomCode)
    setDescription(description);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, tanggal, uomCode, description)
    }
  }


  // fungsi dari ambil data
  const getPosHistory = async (page, perPage, currentSort,transaction_date = null, nota_code = null, customer_name = null) => {
    
    let filter = {
      page: page, 
      per_page: perPage,
    //   active_flag : 3,
      warehouse_id : parseInt(warehouse),
      username : username,
    //   is_settle : 3,
    };
    if (transaction_date !== null) {
      filter = Object.assign(filter, {transaction_date: transaction_date})
    }
    if (nota_code !== null) {
      filter = Object.assign(filter, { nota_code: nota_code })
  }
    if (customer_name !== null) {
      filter = Object.assign(filter, { customer_name: customer_name })
    }
    
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/pos-cashier/page`, data, {
        headers,
      })
      .then((data) => {
        setAllHistoryPos(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllHistoryPos(error.response.data.response);
  });
}

const getDataLog = (id) => {
  let filter = { 
    transaction : id,
    features : "JUAL2", 
    warehouse_id : warehouse
  }
  const data = filter;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
    }
  axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
  })
  .then(data => {
    setDataLog(data.data.response);
          setModal1(!modal1);

  })
    .catch(function (error) {
    setGagalAlert(error.response.data.message);
    })
  }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
    );
  };
  
  const hideAlert = () => {
    setAlert(null);
  };

  const reset = () => {
    setTanggal("")
    setUomCode("");
    setDescription("");
    updateDataTable(1, perPage, currentSort, "", "");
  }


  return (
    <div>
      {alert}
      {Detail && Detail === "1" ? (
        <div>
        <SimpleHeader name="History Kasir" parentName="SO" />
          <Container className="mt--6" fluid>
          <Row>
              <div className="col">
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                    </div>
                  </CardHeader>
                  <CardBody>
                  <Form>
                          <Row md="12">
                          <Col md="3">
                            <FormGroup>
                              <Label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect3"
                                  >
                                Tanggal 
                              </Label>
                              <Input
                              className="form-control-alternative"
                                type="date"
                                placeholder="Masukan Tanggal"
                                value={tanggal}
                                onChange={(e) =>
                                  updateDataTable(1,perPage,currentSort, e.target.value, uomCode , description )
                                }
                              />
                            </FormGroup>
                          </Col>
                              <Col md="3">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >Kode Nota</Label>
                                <Input
                                className="form-control-alternative"
                                  type="text"
                                  placeholder="Masukan KodeF Nota"
                                  value={uomCode}
                                  onChange={e => updateDataTable(1, perPage, currentSort,tanggal, e.target.value, description)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >Customer</Label>
                                <Select2
                                        className="form-control-alternative"
                                        defaultValue="0"
                                        value={description}
                                          onChange={e => updateDataTable(1, perPage, currentSort,tanggal, uomCode, e.target.value)}
                                        options={{
                                          placeholder: "Pilih Customer"
                                        }}
                                        data={persons.map((person, key) => {
                                          return (
                                          { id: person.name, text: person.name, key:key}
                                          );
                                        })}
                                      />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button type="button" onClick={reset} color="secondary">Reset</Button>
                            </Col>
                          </Row>
                        </Form>
                  {allHistoryPos === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Nota",
                                    sort: true,
                                  },
                                  {
                                      dataField: "",
                                      text: "Customer",
                                      sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Status",
                                    sort: true,
                                },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                        <ToolkitProvider
                                rowNumber={rowIndex}
                                data={allHistoryPos}
                                keyField="id"
                                columns={[
                                {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "transaction_date",
                                  text: "Tanggal",
                                  sort: true,
                                },
                                {
                                    dataField: "nota_code",
                                    text: "Nota",
                                    sort: true,
                                    formatter: (cell, row) => {
                                      return row.nota_code === ""
                                        ? <h2>
                                          <Badge color="warning" >
                                            {row.nota_code}
                                          </Badge>
                                          </h2>
                                        : 
                                        <h2>
                                        <Badge color="warning" >
                                            {row.nota_code}
                                          </Badge>
                                          </h2>
                                    },
                                },
                                {
                                    dataField: "customer_name",
                                    text: "Customer",
                                    sort: true,
                                },
                                {
                                    dataField: "", text: "", formatter: (cell, row, index) => {
                                    return (
                                      <>
                                          {buttonpelunasan && buttonpelunasan === "1" && (
                                            <Button>
                                                <Link
                                                to={redirectPrefix + row.id}
                                                id={"tooltip_" + row.id}
                                                >
                                                <i className="fas fa-book" /> Detail
                                                </Link>
                                            </Button>
                                          )}
                                            <Button>
                                            <Link  onClick={() => getDataLog(row.id)}>
                                            <i className="fa fa-info-circle" aria-hidden="true"></i> <span>Info</span>
                                                </Link>
                                            </Button>
                                            </>
                                      )
                                    }
                                  },
                                ]}
                            >
                                {(props) => (
                                <div className="py-4 table-responsive">
                                    <BootstrapTable
                                    remote
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                    hover={true}
                                    pagination={paginationFactory({ ...paginationOption })}
                                    onTableChange={handleTableChange}
                                    />
                                </div>
                              )}
                        </ToolkitProvider>
                        )}
                  </CardBody>
                </Card>
              </div>
          </Row>
          </Container>
          <Modal isOpen={modal1} toggle={toggle2} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle2} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle2}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
      </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default HistoryKasir;