/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col, CardBody, CardHeader, Container,Badge,Form, FormGroup, Label, Input ,DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle,Modal, ModalHeader, ModalBody} from 'reactstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import Select2 from "react-select2-wrapper";
import paginationFactory from "react-bootstrap-table2-paginator";
import ModalCetakPo from './ModalCetakPo';
import ModalCetakMemo from '../../MemoKurirPo/CetakMemo/ModalCetakMemo';
import SimpleHeader from "components/Headers/SimpleHeader.js"
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from 'views/404';

const HistoriPoPusat = () => {
  const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle2 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const token = localStorage.token;
  const [warehouse, setWarehouse] = useState("")
  const [warehouses, setWarehouses] = useState([])
  const redirectPrefix = `/admin/pusat/purchase-order/detail/`;
  const [allPenawaranPo, setAllPenawaranPo] = useState([]);
  const [codepo, setCodePo] = useState("")
  const [description, setDescription] = useState("");
  const [adminpurchasing,setAdminPurchasing]= useState("")
  const [kepalapurchasing,setKepalaPurchasing]= useState("")
  const [direktur,setDirektur]= useState("")
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [person, setPerson] = useState("")
  const [persons, setPersons] = useState([]);
  const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({ id: 0,});
  const toggle = () => setOpenModalCetak(!openModalCetak);
  const [openModalCetak1, setOpenModalCetak1] = useState(false);
  const [dataModalCetak1, setDataModalCetak1] = useState({ id: 0,});
  const toggle1 = () => setOpenModalCetak1(!openModalCetak1);
  const allInfo = JSON.parse(localStorage.allinfo);
  const penawaranPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub PO Pusat").map((p) => p.read_access));
  const memoKurirPO = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Memo PO").map((p) => p.read_access));
  const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak PO Pusat").map((p) => p.read_access));
  
  const displayModalCetak = (id) => {setDataModalCetak({id: id,}); setOpenModalCetak(true);};
  const displayModalCetak1 = (id) => {setDataModalCetak1({id: id,}); setOpenModalCetak1(true);};
  
  useEffect(() => {
    getPerson();
    getWarehouse();

  }, []);

  const getPerson = () => {
      const headers = {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/person/list`, { headers})
      .then(data => {setPersons(data.data.response);})
      .catch(function (error) {console.log(error)})
    }

  const getWarehouse = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`,
        { headers }
      )
      .then((data) => {
        setWarehouses(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, tanggal, codepo, person, description, adminpurchasing, kepalapurchasing, direktur,warehouse);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label
        className="form-control-label"
        >
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, tanggal, codepo, person, description,adminpurchasing, kepalapurchasing, direktur,warehouse) => {
    getPenawaranPo(page, perPage, sort, tanggal, codepo, person, description, adminpurchasing, kepalapurchasing, direktur,warehouse);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal);
    setCodePo(codepo);
    setPerson(person);
    setDescription(description);
    setAdminPurchasing(adminpurchasing);
    setKepalaPurchasing(kepalapurchasing);
    setDirektur(direktur);
    setWarehouse(warehouse)
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, tanggal, codepo, person, description, adminpurchasing, kepalapurchasing, direktur,warehouse)
    }
  }

  
  useEffect(() => {
    getPenawaranPo(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getPenawaranPo = (page, perPage, currentSort, transaction_date="", po_code = "", person_name = "",keterangan="", approve="", status_ap="", status_d="", warehouse_id="") => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage,
      
    };
    if (transaction_date !== "") {
      filter = Object.assign(filter, { transaction_date: transaction_date });
    }
    if (po_code !== "") {
      filter = Object.assign(filter, { po_code: po_code });
    }
    if (person_name !== "") {
      filter = Object.assign(filter, { person_name: person_name });
    }
    if (keterangan !== "") {
      filter = Object.assign(filter, { keterangan: keterangan });
    }
    if (approve !== "") {
      filter = Object.assign(filter, { approve: approve });
    }
    if (status_ap !== "") {
      filter = Object.assign(filter, { status_ap: status_ap });
    }
    if (status_d !== "") {
      filter = Object.assign(filter, { status_d: status_d });
    }
    if (warehouse_id !== "") {
      filter = Object.assign(filter, { warehouse_id: warehouse_id });
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, data, {
        headers,
      })
      .then((data) => {
        setAllPenawaranPo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllPenawaranPo(error.response.data.response);
  });
}


  const reset = () => {
    setTanggal("");
    setCodePo("")
    setPerson("")
    setDescription("");
    setAdminPurchasing("");
    setKepalaPurchasing("");
    setDirektur("")
    setWarehouse("")
    updateDataTable(1, perPage, currentSort, "", "","","","","","");
  }

  const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "BELI3",
			warehouse_id : warehouse
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
            setModal1(!modal1);

		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }

	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert danger showConfirm confirmBtnText="Ok" title={id} onCancel={hideAlert}onConfirm={hideAlert}/>
		  );
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

  return (
    <div>
      {alert}
      {penawaranPOs && penawaranPOs === "1" ? (
			<div>
        <SimpleHeader name="Histori" parentName="" />
        <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/pusat/purchase-order"><i className="fa fa-arrow-circle-left fa-2x" /></Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                  <Form>
                    <Row md="12">
                      <Col md="3">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="exampleFormControlSelect3">Tanggal Transaksi</Label>
                          <Input className="form-control-alternative" type="date" placeholder="Masukan Tanggal" value={tanggal} onChange={(e) => updateDataTable(1,perPage,currentSort, e.target.value, codepo , person, description, adminpurchasing, kepalapurchasing, direktur,warehouse )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                        <Label className="form-control-label" htmlFor="exampleFormControlSelect3">Cabang</Label>
                        <Input name="account" type="select" value={warehouse} onChange={(e) => updateDataTable(1,  perPage, currentSort, tanggal, codepo, person, description, adminpurchasing, kepalapurchasing, direktur, e.target.value)}>
                            <option value="">Pilih Cabang</option>
                            {warehouses.map((dep, key) => {
                            return (
                                <option key={key} value={dep.id}>{dep.name}</option>
                            );
                            })}
                        </Input>
                        </FormGroup>
                    </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label className="form-control-label"htmlFor="exampleFormControlSelect3">Kode PO</Label>
                          <Input className="form-control-alternative" type="text" placeholder="Masukan Kode PO" value={codepo} onChange={e => updateDataTable(1, perPage, currentSort, tanggal, e.target.value, person, description, adminpurchasing, kepalapurchasing, direktur,warehouse)}/>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                      <FormGroup>
                              <Label className="form-control-label" htmlFor="exampleFormControlSelect3">Supplier</Label>
                                      <Select2
                                        className="form-control-alternative"
                                        defaultValue="0"
                                        value={person}
                                        onChange={(e) =>
                                          updateDataTable(1, perPage, currentSort, tanggal, codepo, e.target.value, description, adminpurchasing, kepalapurchasing, direktur,warehouse)
                                          }
                                        options={{
                                          placeholder: "Pilih Supplier"
                                        }}
                                        data={persons.map((person, key) => {
                                          return (
                                          { id: person.person_name, text: person.person_name, key:key}
                                          );
                                        })}
                                      />
                              </FormGroup>
                      </Col>
                     
                    </Row>
                    <Row md="12">
                        <Col md="3">
                              <FormGroup>
                                <Label className="form-control-label" htmlFor="exampleFormControlSelect3">Admin Purchasing</Label>
                                <Input type="select" placeholder="Masukan Kode" value={adminpurchasing} onChange={e => updateDataTable(1, perPage, currentSort, tanggal,codepo, person, description, e.target.value,  kepalapurchasing, direktur,warehouse)}>
                                  <option value={''}>Pilih Status </option>
                                  <option value={3}>Proses</option>
                                  <option value={4}>Tolak</option>
                                  <option value={5}>Setuju</option>
                                </Input>
                              </FormGroup>
                        </Col>
                        <Col md="3">
                              <FormGroup>
                                <Label className="form-control-label"htmlFor="exampleFormControlSelect3">Kepala Purchasing</Label>
                                <Input type="select" placeholder="Masukan Kode" value={kepalapurchasing} onChange={e => updateDataTable(1, perPage, currentSort,tanggal, codepo, person, description, adminpurchasing, e.target.value, direktur,warehouse)}>
                                  <option value={''}>Pilih Status </option>
                                  <option value={3}>Proses</option>
                                  <option value={4}>Tolak</option>
                                  <option value={5}>Setuju</option>
                                </Input>
                              </FormGroup>
                        </Col>
                        <Col md="3">
                              <FormGroup>
                                <Label className="form-control-label" htmlFor="exampleFormControlSelect3">Direktur</Label>
                                <Input
                                  type="select"
                                  placeholder="Masukan Kode"
                                  value={direktur}
                                  onChange={e => updateDataTable(1, perPage, currentSort, tanggal, codepo, person, description, adminpurchasing, kepalapurchasing, e.target.value,warehouse)}
                                >
                                  <option value={''}>Pilih Status </option>
                                  <option value={3}>Proses</option>
                                  <option value={4}>Tolak</option>
                                  <option value={5}>Setuju</option>
                                </Input>
                              </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <Label className="form-control-label"htmlFor="exampleFormControlSelect3">Keterangan</Label>
                            <Input className="form-control-alternative" type="text" placeholder="Masukan Keterangan" value={description} onChange={e => updateDataTable(1, perPage, currentSort, tanggal,codepo ,person, e.target.value, adminpurchasing, kepalapurchasing, direktur,warehouse)}/>
                          </FormGroup>
                        </Col>
                       <Col md="" sm="3">
                          <Label>&nbsp;</Label>
                          <br></br>
                          <Button type="button" onClick={reset} color="secondary">
                            Reset
                          </Button>
                      </Col>
                    
                    </Row>
                  </Form>
                  {allPenawaranPo === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                              },
                              {
                                  dataField: "",
                                  text: "Kode PO",
                                  sort: true,
                              },
                              {
                                  dataField: "",
                                  text: "Supplier",
                                  sort: true,
                              },
                              {
                                dataField: "",
                                text: "Admin Purchasing",
                                sort: true,
                            },
                            {
                              dataField: "",
                              text: "Kepala Purchasing",
                              sort: true,
                              
                          },
                              {
                                dataField: "",
                                text: "Direktur",
                                sort: true,
                               
                            },
                            {
                              dataField: "",
                              text: "Keterangan",
                              sort: true,
                             
                          },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <h3>DATA NOT FOUND</h3>
                          </CardHeader>
                        </div>
                        ):(
                    <ToolkitProvider
                            rowNumber={rowIndex}
                            data={allPenawaranPo}
                            keyField="id"
                            columns={[
                            {
                                dataField: "no",
                                text: "#",
                                sort: true,
                                page: 1,
                                formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                                },
                            },
                            {
                                dataField: "transaction_date",
                                text: "Tanggal",
                                sort: true,
                            },
                            {
                                dataField: "po_code",
                                text: "Kode PO",
                                sort: true,
                                formatter: (cell, row) => {
                                  return row.po_code === ""
                                    ? <h2>
                                      <Badge color="warning" >
                                        {row.po_code}
                                      </Badge>
                                      </h2>
                                    : 
                                    <h2>
                                    <Badge color="warning" >
                                        {row.po_code}
                                      </Badge>
                                      </h2>
                                },
                            },
                            {
                                dataField: "person_name",
                                text: "Supplier",
                                sort: true,
                            },
                            {
                              dataField: "approve",
                              text: "Admin Purchasing",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.approve === 3
                                  ? 
                                    <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-danger" />
                                      Proses
                                    </Badge>
                                  : row.approve === 4
                                  ? <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-warning" />
                                      Tolak
                                    </Badge>
                                  : <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-success" />
                                      Setuju
                                    </Badge>
                              },
                          },
                          {
                            dataField: "status_ap",
                            text: "Kepala Purchasing",
                            sort: true,
                            formatter: (cell, row) => {
                              return row.status_ap === 3
                                ? 
                                  <Badge color="" className="badge-dot mr-4">
                                    <i className="bg-danger" />
                                    Proses
                                  </Badge>
                                : row.status_ap === 4
                                ? <Badge color="" className="badge-dot mr-4">
                                    <i className="bg-warning" />
                                    Tolak
                                  </Badge>
                                : <Badge color="" className="badge-dot mr-4">
                                    <i className="bg-success" />
                                    Setuju
                                  </Badge>
                            },
                        },
                            {
                              dataField: "status_d",
                              text: "Direktur",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.status_d === 3
                                  ? 
                                    <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-danger" />
                                      Proses
                                    </Badge>
                                  : row.status_d === 4
                                  ? <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-warning" />
                                      Tolak
                                    </Badge>
                                  : <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-success" />
                                      Setuju
                                    </Badge>
                              },
                          },
                          {
                              dataField: "keterangan",
                              text: "Keterangan",
                              sort: true,
                          },
                          {
                            dataField: "",
                            text: "",
                            formatter: (cell, row, index) => {
                              return row.status_d === 3
                                ? <UncontrolledDropdown nav>
                                    <DropdownToggle color="danger">
                                        Tindakan
                                      </DropdownToggle>
                                      <DropdownMenu>
                                            <Link to={redirectPrefix + row.id}
                                              id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                              <i className="fas fa-user-edit" /><span>Detail</span>
                                            </DropdownItem>
                                            </Link>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                                : row.status_d === 4
                                ?  <UncontrolledDropdown nav>
                                  <DropdownToggle color="danger">
                                    Tindakan
                                  </DropdownToggle>
                                  <DropdownMenu>
                                        <Link  to={redirectPrefix + row.id}
                                          id={"tooltip_" + row.id}>
                                        <DropdownItem>
                                          <i className="fas fa-user-edit" /><span>Detail</span>
                                        </DropdownItem>
                                        </Link>
                                  </DropdownMenu>
                              </UncontrolledDropdown>
                                : <UncontrolledDropdown nav>
                                <DropdownToggle color="danger">
                                    Tindakan
                                  </DropdownToggle>
                                  <DropdownMenu>
                                        <DropdownItem onClick={() => getDataLog(row.id)}><i className="fa fa-info-circle" aria-hidden="true"></i><span>Info</span></DropdownItem>
                                        <Link  to={redirectPrefix + row.id} id={"tooltip_" + row.id}>
                                          <DropdownItem><i className="fas fa-user-edit" /><span>Detail</span></DropdownItem>
                                        </Link>
                                        {memoKurirPO && memoKurirPO === "1" && (
                                        <DropdownItem onClick={() => displayModalCetak1(row.id)}><i className="fas fa-print" /><span>Cetak Memo</span></DropdownItem>
                                        )}
                                        {cetak && cetak === "1" && (
                                        <DropdownItem onClick={() => displayModalCetak(row.id)}><i className="fas fa-print" /><span>Cetak</span></DropdownItem>
                                        )}
                                  </DropdownMenu>
                              </UncontrolledDropdown>
                            },
                          },
                            ]}
                        >
                            {(props) => (
                            <div className="py-4 table-responsive">
                                <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                                />
                            </div>
                            )}
                    </ToolkitProvider>
                    )}
              </CardBody>
            </Card>
          </div>
        </Row>
        {openModalCetak && (
        <ModalCetakPo open={openModalCetak} data={dataModalCetak} toggle={toggle}
        />
        )}
        {openModalCetak1 && (
          <ModalCetakMemo open={openModalCetak1} data={dataModalCetak1} toggle={toggle1}
          />
        )}
        </Container>
        <Modal isOpen={modal1} toggle={toggle2} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle2} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {dataField: "no",text: "#",sort: true,page: 1,formatter: (cell, row, index) => {let currentRow = ++index;return currentRow + rowIndex;},},
				          {dataField: "api_hit",text: "Tanggal Update",sort: true,},
                  {dataField: "username",text: "PIC",sort: true,},
                  {dataField: "lost_data",text: "Data Lama",sort: true,},
                  {dataField: "show_data",text: "Data Baru",sort: true,},
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle2}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
        </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default HistoriPoPusat;