import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  // Input
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
// import * as FileSaver from "file-saver";
// import styles from '../../../../../assets/scss/Print.scss'
import moment from "moment";
import ExcelJS from 'exceljs';
import { saveAs } from "file-saver";
import SweetAlert from "react-bootstrap-sweetalert";

const ModalCetakSuratJalanProyek = ({ open, toggle, data }) => {
    const token = localStorage.token;
    const idtok = data.id
    const username = localStorage.username;
    const [savedItems, setSavedItems] = useState([]);
    const [download, setDownload] = useState("")
    const [codepo, setCodePo] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [supplier, setSupplier] = useState("");
    const [warehouserfq, setWarehouseRfq] = useState("");
    const [waktu,setWaktu] = useState([]);
    const [validator, setValidator] = useState("");
    const [address, setAddress] = useState("")
    // const [phone, setPhone] = useState("")
    const [driver, setDriver] = useState("");
    const [helper, setHelper] = useState("");
    const [nopol, setNopol] = useState("");
  const [alert, setAlert] = useState(null);

    // const [item, setItem] = useState("")


  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/cetak-admin/${idtok}`,
        { headers }
      )
      .then((data) => {  
        // getItemDataSaved(data.data.response.sj.id)
        setAddress(data.data.response.sj.address)
        // setPhone(data.data.response.sj.phone)
        setSupplier(data.data.response.sj.person);
        setDownload(data.data.response.sj.counter);
        setWaktu(data.data.response.sj.created)
        setWarehouseRfq(data.data.response.sj.warehouse);
        setKeterangan(data.data.response.sj.keterangan);
        setCodePo(data.data.response.sj.sj_code);
        setValidator(data.data.response.sj.validator1);
        setNopol(data.data.response.sj.nopol);
        setDriver(data.data.response.sj.driver);
        setHelper(data.data.response.sj.helper);
        setSupplier(data.data.response.sj.customer);
        setWaktu(data.data.response.sj.created)
        setWarehouseRfq(data.data.response.sj.warehouse);
        setKeterangan(data.data.response.sj.keterangan);
        // setItem(data.data.response.list.length);
       
      })
      .catch(function (error) {
        console.log(error);
      });
  },[token, idtok]);

  const HitCounter = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/hit-counter-admin/${idtok}`,
        { headers }
      )
      .then((data) => {  
        console.log("suskes Cuk");
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/cetak-admin/${idtok}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                harga: data.harga,
                satuan: data.satuan,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                sub_total : data.sub_total,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token, idtok]);


useEffect(() => {
  getById();
  getItemDataSaved();
}, [getById,getItemDataSaved]);


const handleDownload = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    // Mengambil data dari API
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/cetak-admin/${data.id}`, {headers});
    const responseData = response.data.response;
    const { address } = responseData.sj; // Mengambil address dari receivingpo
    const { customer } = responseData.sj; // Mengambil address dari receivingpo
    const { created } = responseData.sj ;
    const {warehouse} = responseData.sj ;
    const {keterangan} = responseData.sj ;
    const {sj_code} = responseData.sj ;
    const {validator1} = responseData.sj ;
    const {driver} = responseData.sj ;
    const {helper} = responseData.sj ;
    const {nopol} = responseData.sj ;
    const {phone} = responseData.sj;
    
    // setAddress(data.data.response.sj.address)
    //     // setPhone(data.data.response.sj.phone)
    //     setSupplier(data.data.response.sj.person);
    //     setDownload(data.data.response.sj.counter);
    //     setWaktu(data.data.response.sj.created)
    //     setWarehouseRfq(data.data.response.sj.warehouse);
    //     setKeterangan(data.data.response.sj.keterangan);
    //     setCodePo(data.data.response.sj.sj_code);
    //     setValidator(data.data.response.sj.validator1);
    //     setNopol(data.data.response.sj.nopol);
    //     setDriver(data.data.response.sj.driver);
    //     setHelper(data.data.response.sj.helper);
    //     setSupplier(data.data.response.sj.customer);
    //     setWaktu(data.data.response.sj.created)
    //     setWarehouseRfq(data.data.response.sj.warehouse);
    //     setKeterangan(data.data.response.sj.keterangan);
    const list = responseData.list; // Mengambil data list dari response

    // Membaca file Excel yang diunggah
    const fileResponse = await fetch("https://api-erp.hokkybangunan.com/sj.xlsx");
    const arrayBuffer = await fileResponse.arrayBuffer();

    // Load workbook
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(arrayBuffer);

    const worksheet = workbook.getWorksheet(1); // Mengambil worksheet pertama

    // Menulis alamat ke sel I12
    worksheet.getCell('H2').value = warehouse;
    worksheet.getCell('H11').value = address;
    worksheet.getCell('H10').value = customer;
    worksheet.getCell('C10').value = sj_code;
    worksheet.getCell('C11').value = created;
    worksheet.getCell('C12').value = nopol;
    worksheet.getCell('C13').value = driver;
    worksheet.getCell('I36').value = driver;
    worksheet.getCell('C14').value = helper;
    worksheet.getCell('H12').value = phone;
    worksheet.getCell('H14').value = keterangan;
    worksheet.getCell('H14').value = keterangan;
    worksheet.getCell('C36').value = validator1;

    // Menulis data dari list ke dalam worksheet
    list.forEach((item, index) => {
      const rowNumber = 22 + index; // Mulai dari baris ke-15
      worksheet.getCell(`E${rowNumber}`).value = item.item_code;  // Kolom A untuk item_code
      worksheet.getCell(`B${rowNumber}`).value = item.item_name;  // Kolom B untuk item_name
      worksheet.getCell(`H${rowNumber}`).value = item.qty;        // Kolom C untuk qty
      worksheet.getCell(`I${rowNumber}`).value = item.satuan;     // Kolom D untuk satuan
    });

    // Menyimpan workbook ke buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Membuat blob dari buffer dan mengunduh file
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, "Surat-jalan.xlsx");

    // Tampilkan alert sukses
    setAlert(
      <SweetAlert
        success
        title="Berhasil"
        onConfirm={() => setAlert(null)}
      >
        File Excel berhasil diunduh!
      </SweetAlert>
    );
  } catch (error) {
    console.error("Error", error);
    setAlert(
      <SweetAlert
        danger
        title="Error"
        onConfirm={() => setAlert(null)}
      >
        Terjadi kesalahan. Silakan coba lagi.
      </SweetAlert>
    );
  }
};

// const downloadExcel = async ()=> {
//     const J = "Surat-Jalan-SO"
//     const fileName = J;
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     axios
//       .get(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/cetak-admin/${data.id}`,{headers})
//       .then((data) => {
//         setAddress(data.data.response.sj.address)
//         setPhone(data.data.response.sj.phone)
//         setSupplier(data.data.response.sj.person);
//         setDownload(data.data.response.sj.counter);
//         setWaktu(data.data.response.sj.created)
//         setWarehouseRfq(data.data.response.sj.warehouse);
//         setKeterangan(data.data.response.sj.keterangan);
//         setCodePo(data.data.response.sj.sj_code);
//         setValidator(data.data.response.sj.validator1);
//         setNopol(data.data.response.sj.nopol);
//         setDriver(data.data.response.sj.driver);
//         setHelper(data.data.response.sj.helper);
//         setSupplier(data.data.response.sj.customer);
//         setWaktu(data.data.response.sj.created)
//         setWarehouseRfq(data.data.response.sj.warehouse);
//         setKeterangan(data.data.response.sj.keterangan);
//         setValidator(data.data.response.sj.validator1);
//         // setItem(data.data.response.list.length);

//         var apiPo = data.data.response.list;
//         // var tglcetak = data.data.response.download_date;
//         var tableToExcel = (function() {
//           // var uri = 'data:application/vnd.ms-excel;base64,',
//           var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
//             // base64 = function(s) {
//             //   return window.btoa(unescape(encodeURIComponent(s)))
//             // },
//             format = function(s, c) {
//               return s.replace(/{(\w+)}/g, function(m, p) {
//                 return c[p];
//               })
//             }
//           return function(name) {
//             var heading = 'Surat Jalan SO' ;
//             var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
//             var po = '';
//             var a ;
//             for(a=0; a < apiPo.length; a++){
//               po += `
//               <tr style="border: 1px solid black">
//                   <td>${apiPo[a].item_name}</td>
//                   <td>${apiPo[a].item_code}</td>
//                   <td></td>
//                   <td>${apiPo[a].satuan}</td>
//               </tr>
//               `
//             }
//             var table = `
//             <table id="account_table">
//                 <tbody>
//                 <tr>
//                     <td><img src="https://i.ibb.co/jkFYDqg/150x75.png"/> </td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Hokky Bangunan</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Jl Menganti Karangan No.562</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Wiyung surabaya barat - Jawa Timur </td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Telp: 081 217 85 3300 </td>
//                 </tr>
//                 <tr>
//                     <td colspan="8"><center><b><h1>SURAT JALAN SO</h1></b><center></td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                 <tr>
//                     <td>Tanggal</td>
//                     <td>: ${waktu}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Customer : ${supplier}</td>
//                 </tr>
//                 <tr>
//                     <td>Kode SJ</td>
//                     <td>: ${codepo}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Alamat : ${address}</td>
//                 <tr>
//                 <tr>
//                     <td>Ket</td>
//                     <td rowspan="2">: ${keterangan}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Phone : ${phone}</td>
//                 <tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                   <tr style="border: 1px solid black">
//                       <th>Nama Item</th>
//                       <th>Kode Item</th>
//                       <th>Qty</th>
//                       <th>Satuan</th>
//                     </tr>
//                         ${po}
//                 </tbody>
//             </table>
//             `;
//             var ctx = {
//                 worksheet: name || 'WorkBook',
//                 imgsrc1: imgsrc1,
//                 heading: heading,
//                 table: table
//             }
//             var blob = new Blob([format(template, ctx)]);
//             return blob;
//             }
//         })()
//         var blobURL = tableToExcel('account_table', 'Surat Jalan SO');
//         FileSaver.saveAs(blobURL, fileName+'.xls');
        
//         })
//       .catch(function (error) {
//       });
//   }
  

  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    // console.log(item)
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }

  // function clickItem(e) {
  //   setItem(e.target.value)
  // }
  
  return (
    <div>
      {alert}
      <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
        <ModalHeader toggle={toggle} align="center">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
                      {/* <div style={{ textAlign: 'left' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            Set Item
                        </Label>
                        <Input type="number" pattern="[0-9]*" value={item} min="1" max={savedItems.length} onChange={clickItem} style={{width: '70px',marginRight:'50px'}}/>
                    </div> */}
                      <div style={{ textAlign: 'right' }}>
                      <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                          <Button color="info" onClick={() => {HitCounter();print();setTimeout(() => (window.location.reload("/admin/surat-jalan-so/histori")), 1000)}}>Print</Button>
                          <Button color="success" onClick={()=> {handleDownload();HitCounter()}}>Excel</Button>
                          {/* <Button color="success" onClick={()=> {HitCounter();downloadExcel()}}>Excel</Button> */}
                      </div>
                  </div>
        </ModalHeader>
        <ModalBody>
        <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
          {/* <CardBody
            className="border"
            id="targetContent"
            style={{ width: "100%" }}
          >
            <div className="col">
              <KopSurat 
              warehouseName={warehouserfq}
              // user={username}
              />
            </div>
            <div className="w-100" style={{ border: "1px solid black" }}></div>
                          <Row md="12">
                              <Col md="12">
                                  <h3><b><center>SURAT JALAN SO</center></b></h3>
                              </Col>
                          </Row>
                          <div className="w-100" style={{ border: "1px solid black" }}></div>
                          <br></br>
                          <Row md="12">
                              <Col md="5">
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Tanggal</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-5 ">
                                      <b>{waktu}</b>
                                  </div>
                              </div>
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Kode SJ</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-5 ">
                                      <b>{codepo}</b>
                                  </div>
                              </div>
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Nopol</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-5 ">
                                      <b>{nopol}</b>
                                  </div>
                              </div>
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Keterangan</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-7 ">
                                      <b>{keterangan}</b>
                                  </div>
                              </div>
                              </Col>
                              <Col md="1">
                              </Col>
                              <Col md="6">
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Customer</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-7 ">
                                      <b>{supplier}</b>
                                  </div>
                              </div>
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Alamat</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-7 ">
                                      <b>{address}</b>
                                  </div>
                              </div>
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Phone</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-7 ">
                                      <b>{phone}</b>
                                  </div>
                              </div>
                              </Col>
                          </Row>
                      <CardBody>
                        <br></br>
                        <Table size="sm" responsive>
                          <thead>
                          <tr>
                              <th>
                              <b>Nama Item</b>
                              </th>
                              <th>
                              <b>Kode Item</b>
                              </th>
                              <th>
                              <b>Qty</b>
                              </th>
                              <th>
                              <b>Satuan</b>
                              </th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                              savedItems.map((savedItem, key) => {
                                  return (
                                      <tr key={key}>
                                          <td>{savedItem.data.item_name}</td>
                                          <td>{savedItem.item_code}</td>
                                          <td>{savedItem.qty}</td>
                                          <td>{savedItem.satuan}</td>
                                      </tr>
                                  )
                              })
                          }
                          </tbody>
                        </Table>
                      </CardBody>
                      <div className="w-100" style={{ border: "0.5px solid black" }}></div>
                          <center>Terms of Price, delivery & shipping required</center>
                          <br></br>
                                  <Row md="20">
                                      <Col md="4">
                                      <div className="row align-items-center mb-3">
                                          <div className="col-5">Kepala Gudang</div>
                                          <div className="col-1 text-center">:</div>
                                          <div className="col-5 ">
                                              <b>{validator}</b>
                                          </div>
                                      </div>
                                      <div className="row align-items-center mb-3">
                                          <div className="col-5">Signature</div>
                                          <div className="col-1 text-center">:</div>
                                          <div className="col-4 ">
                                              <b>------------</b>
                                          </div>
                                      </div>
                                      </Col>
                                      <Col md="4">
                                      <div className="row align-items-center mb-3">
                                          <div className="col-4">Driver</div>
                                          <div className="col-1 text-center">:</div>
                                          <div className="col-4 ">
                                              <b>{driver}</b>
                                          </div>
                                      </div>
                                      <div className="row align-items-center mb-3">
                                          <div className="col-4">Signature</div>
                                          <div className="col-1 text-center">:</div>
                                          <div className="col-4 ">
                                              <b>------------</b>
                                          </div>
                                      </div>
                                      </Col>
                                      <Col md="4">
                                      <div className="row align-items-center mb-3">
                                          <div className="col-4">Helper</div>
                                          <div className="col-1 text-center">:</div>
                                          <div className="col-4 ">
                                              <b>{helper}</b>
                                          </div>
                                      </div>
                                      <div className="row align-items-center mb-3">
                                          <div className="col-4">Signature</div>
                                          <div className="col-1 text-center">:</div>
                                          <div className="col-4 ">
                                              <b>------------</b>
                                          </div>
                                      </div>
                                      </Col>
                                  </Row>
                                  <div className="w-100" style={{ border: "0.5px solid black" }}></div>
                        {download === 0 ? (
                          <div className="row align-items-center mb-3">
                          <div className="col-4"><i>Download Original oleh {username}</i></div>
                          </div>
                        ):(
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Di Download</i> {download} Kali Oleh {username}</div>
                      </div>
                        )}
          </CardBody> */}
        </ModalBody>
      </Modal>
    </div>
  );

  function renderPDF(){
    // let sheet = 1;
    // let length = savedItems.length
    // if (item !== length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []  
    // for (let index = 0; index < sheet; index++) {
    //   let start = 0
    //   let end = 0
    //   if(sheet === 1){
    //     end = length
    //   }else{
    //     if(sheet > 1 && index < (sheet - 1)){
    //       end = item
    //     }else{
    //       end = length % item
    //     }
    //   }

    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []
    // for (let index = 0; index < sheet; index++) {
    //   let start = (item * index)
    //   let end = length
    //   let sisa = (length % item)
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(index < (sheet - 1) && sheet == 2){
    //       end = item
    //     }else{
    //       if (index == (sheet - 1)) {
    //         end = length - sisa + (index * item)
    //       }else{
    //         if (index == 0) {
    //           end = item
    //         }else{
    //           end = (index+1) * item
    //         }
    //       }
    //     }
    //   } 
    //   let data = savedItems.slice(start,end)
    const itemsPerPage = 10; // Maksimal 7 item per halaman
    const totalItems = savedItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Hitung total halaman
  
    const sheets = [];
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      // Hitung indeks awal dan akhir untuk slice item per halaman
      const start = pageIndex * itemsPerPage;
      const end = start + itemsPerPage;
  
      // Ambil item untuk halaman saat ini
      const currentItems = savedItems.slice(start, end);
      // console.log(data.length)
      // let sub_total = 0;
      // let diskon = 0;
      // let pajak = 0;
      let usernamecok = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
      sheets.push(
        <div className='pagebreak'>
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <Row md="12">
              <Col md="12">
                  <h3 className="" style={{ color:"black" }}><b><center>SURAT JALAN PENJUALAN BARANG</center></b></h3>
              </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          
          <Row md="12">
            <Col style={{marginLeft:"2%",width:"50%",flexBasis: "unset"}}>
             
              <div className="row align-items-center ">
                  <div style={{width:"15%" , color:"black"}}  className="text-table">Tanggal</div>
                  <div style={{width:"2%", color:"black"}}  className="text-center text-table">:</div>
                  <div style={{width:"75%", color:"black"}}  className="text-table">
                      <b>{waktu}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"15%", color:"black"}} className="text-table">No SJ</div>
                  <div style={{width:"2%", color:"black"}} className="text-center text-table">:</div>
                  <div style={{width:"75%", color:"black"}} className="text-table">
                      <b>{codepo}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"15%", color:"black"}} className="text-table">Nopol</div>
                  <div style={{width:"2%", color:"black"}} className="text-center text-table">:</div>
                  <div style={{width:"75%", color:"black"}} className="text-table">
                      <b>{nopol}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"15%", color:"black"}}  className="text-table">keterangan </div>
                  <div style={{width:"2%", color:"black"}}  className="text-center text-table">:</div>
                  <div style={{width:"75%", color:"black"}}  className="text-table">
                      <b>{keterangan}</b>
                  </div>
              </div>
            </Col>
            <Col style={{width:"25%",flexBasis: "unset"}}>
              <div className="row align-items-center ">
                  <div style={{width:"15%", color:"black"}} className="text-table">Supplier</div>
                  <div style={{width:"5%", color:"black"}} className="text-table">:</div>
                  <div style={{width:"75%", color:"black"}} className="text-table">
                      <b>{supplier}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"15%", color:"black"}} className="text-table">Alamat</div>
                  <div style={{width:"5%", color:"black"}} className="text-table">:</div>
                  <div style={{width:"75%", color:"black"}} className="text-table">
                      <b>{address}</b>
                  </div>
              </div>
            </Col>
          </Row>
          <br></br>
              <div style={{ maxHeight: "400px", overflowY: "auto", width: "100%" }}>
                <Table style={{width:"100%"}} responsive>
                  <thead>
                  <tr style={{color:"black",border: "1px solid black"}}>
                      <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black"  }}>
                      <b>No</b>
                      </th>
                      <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black",border: "1px solid black" }}>
                      <b>Kode Barang</b>
                      </th>
                      <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px" , color:"black",border: "1px solid black"}}>
                      <b>Nama Barang</b>
                      </th>
                      {/* <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px" }}>
                      <b>Brand</b>
                      </th> */}
                      <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px" , color:"black",border: "1px solid black"}}>
                      <b>Qty</b>
                      </th>
                      <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px" , color:"black",border: "1px solid black"}}>
                      <b>Sat</b>
                      </th>
                      <th style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px" , color:"black",border: "1px solid black"}}>
                      <b>Keterangan</b>
                      </th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                      currentItems.map((temp,index, key) => {
                          // let item_name =  temp.data.item_name
                          // sub_total = sub_total + temp.sub_total
                          // diskon = diskon + temp.diskon_nominal
                          return (
                              <tr>
                                  <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{index+1}</td>
                                  <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{temp.item_code}</td>
                                  <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{temp.item_name}</td>
                                  {/* <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px" }}>-</td> */}
                                  <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{temp.qty}</td>
                                  <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}>{temp.satuan}</td>
                                  <td style={{ whiteSpace: "nowrap", padding: "4px 8px", fontSize: "14px", color:"black" }}></td>
                              </tr>
                          );
                      })
                  }
                  </tbody>
                </Table>
              </div>
              <div ></div>
              <Row md="12">
              </Row>
          <div className="w-100 text-table" style={{ border: "0.5px solid black" }}></div>
          <center className="text-table" style={{color:"black",border: "none"}}>Terms of Price, delivery & shipping required</center>
          <Row md="20">
            <Col style={{ marginLeft:"2%",width:"40%" }}>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  <div className="col-5 text-table">Kepala Gudang</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-6 text-table">
                      <b>{validator}</b>
                  </div>
              </div>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  <div className="col-5 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-6 text-table">
                      {/* <b>------------------</b> */}
                  </div>
              </div>
            </Col> 
            <Col style={{ width:"33%" }}>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  <div className="col-3 text-table">Driver</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                                            <b>{driver}</b>
                  </div>
              </div>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      {/* <b>------------------</b> */}
                  </div>
              </div>
            </Col>
            <Col style={{ width:"33%" }}>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  <div className="col-3 text-table">Helper</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                                            <b>{helper}</b>

                  </div>
              </div>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
          </Row>
          <div className="w-100" style={{ border: "0.5px solid black" }}></div>
                      {/* {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download {download} Kali Oleh {username}</i></div>
                    </div>
                      ) 
                    } */}
          {/* <div className="divFooter">{usernamecok}</div> */}
          <div className="divFooter" style={{ fontSize:"12px" }}><i>{usernamecok}</i>, {download === 0 ? (
                        // <div className="row align-items-center mb-3">
                        <i>,Download Original</i>
                          // </div>
                        ):(
                        // <div className="row align-items-center mb-3">
                        <i>,Di Download {download} Kali</i>
                      // </div>
                      )
                    }</div>
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }

};

export default ModalCetakSuratJalanProyek;
