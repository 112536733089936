/*eslint-disable*/
import React, { useEffect, useState,useRef } from "react";
import { Card, CardBody, Row, Col, Input, Container,InputGroup,InputGroupText,FormGroup, Table, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter,CardHeader,
	 } from "reactstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { ViewCetakSoKasir } from "utils/CetakCetak2";

export default function DetailHistoryKasir(props) {
	const token = localStorage.token;
	const warehouse = localStorage.warehouse;
	const username = localStorage.username;
	const [jasa, setJasa] = useState(0);
	const [jasa1, setJasa1] = useState(0);
	let history = useHistory();
  	const [alert, setAlert] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [qtyTotal, setTotalQty] = useState(0);
	const [pengiriman, setPengiriman] = useState([]);
	const [customer, setCustomer] = useState("");
	const waktu= new Date().toLocaleDateString("en-US");
	
	const [savedItems, setSavedItems] = useState([]);
	
	const [banks, setBanks] = useState([]);
	const [ppn, setPPN] = useState(0);
    const [promoglobal,setPromoGlobal] = useState(0);
	const [ongkir, setOngkir] = useState(0);
	const [ongkir1, setOngkir1] = useState(0);
	const [totaldiskon, setTotalDiskon] = useState(0);
	const [totaldiskon3, setTotalDiskon3] = useState(0);
	const [grandtotal, setGrandTotal] = useState(0);
	const [alamatcustomer, setAlamatCustomer] = useState("");
	const [alamatlain, setAlamatLain] = useState("");
	const [diskonglobalnominal, setDiskonGlobalNominal] = useState(0);
	const [diskonglobalpersen,setDiskonGlobalPersen] = useState(0);
	const [pajak, setPajak] = useState(1);
	const [allpajak, setAllPajak] = useState([]);
	const [sales, setSales] = useState("");
	const [ppnnew, setPPNNEW] = useState(0);
    const [totalPrice1, setTotalPrice1] = useState(0);
    const a = 0
	const b = 0
	const [diskonglobalnominal1,setDiskonGlobalNominal1] = useState(0)
	const [totaldiskon2, setTotalDiskon2] = useState(0);
	const [codenota, setCodeNota] = useState("");
    const [banks1, setBanks1] = useState([]);
    const [banks2, setBanks2] = useState([]);
    const [banks3, setBanks3] = useState([]);
    const [banks4, setBanks4] = useState([]);
    const [banks5, setBanks5] = useState([]);
	const [pay1, setPay1] = useState(0);
	const [pay2, setPay2] = useState(0);
    const [pay3, setPay3] = useState(0);
    const [pay4, setPay4] = useState(0);
    const [pay5, setPay5] = useState(0);
    const [pay6, setPay6] = useState(0);
	const [payment_method, setPaymentMethod] = useState("");
	const [payment_method1, setPaymentMethod1] = useState("");
	const [payment_method2, setPaymentMethod2] = useState("");
    const [payment_method3, setPaymentMethod3] = useState("");
    const [payment_method4, setPaymentMethod4] = useState("");
    const [payment_method5, setPaymentMethod5] = useState("");
    const [payment_method6, setPaymentMethod6] = useState("");
	
	const [pay1s, setPay1s] = useState(0);
	const [pay2s, setPay2s] = useState(0);
    const [pay3s, setPay3s] = useState(0);
    const [pay4s, setPay4s] = useState(0);
    const [pay5s, setPay5s] = useState(0);
    const [pay6s, setPay6s] = useState(0);
	const [payment_methods, setPaymentMethods] = useState("");
	const [payment_method1s, setPaymentMethod1s] = useState("");
	const [payment_method2s, setPaymentMethod2s] = useState("");
    const [payment_method3s, setPaymentMethod3s] = useState("");
    const [payment_method4s, setPaymentMethod4s] = useState("");
    const [payment_method5s, setPaymentMethod5s] = useState("");
    const [payment_method6s, setPaymentMethod6s] = useState("");

	const bulatan = grandtotal/1000
	const bulatin = Math.ceil(bulatan)
	const bulat = Math.ceil(bulatin)*1000

    const componentCetakSoKasir = useRef();
	
	const handleCetak = () => {
		const content = componentCetakSoKasir.current;
		const printWindow = window.open('', '', 'height=1,width=1');
		printWindow.document.write(content.innerHTML);
		printWindow.document.close();
		printWindow.onload = function() {
		printWindow.focus();	
		printWindow.print({silent: true});
		printWindow.close();
		};
		setTimeout(() => (window.location.reload("/admin/pos/history")), 6000);

	  };

    useEffect(() => {
        getById();
      }, []);
    
      const getById = () => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/cicilan-cashier/get-by-id/${props.match.params.id}`,
            { headers }
          )
          .then((data) => {
            // console.log(data);
            // getCustomer(data.data.response.customer_id);
			setJasa(data.data.response.nominal_jasa)
            setCustomer(data.data.response.customer)
			setPromoGlobal(data.data.response.promo_total)
            getPajak(data.data.response.pajak_id);
			setCodeNota(data.data.response.nota_code)
            setPaymentMethod(data.data.response.payment_method);
            setAlamatLain(data.data.response.manual_address);
            setOngkir(data.data.response.ongkir);
            setSales(data.data.response.sales);
			setTotalQty(data.data.response.price_real);
            setPengiriman(data.data.response.pengiriman);
            setDiskonGlobalNominal(data.data.response.promo_total);
            setPay1(data.data.response.pay_1);
            setPay2(data.data.response.pay_2);
            setPay3(data.data.response.pay_3);
            setPay4(data.data.response.pay_4);
            setPay5(data.data.response.pay_5);
            setPay6(data.data.response.pay_6);
			setPay1s(data.data.response.pay_1);
            setPay2s(data.data.response.pay_2);
            setPay3s(data.data.response.pay_3);
            setPay4s(data.data.response.pay_4);
            setPay5s(data.data.response.pay_5);
            setPay6s(data.data.response.pay_6);
            setPaymentMethod(data.data.response.payment_method);
            setPaymentMethod1(data.data.response.payment_method1);
            setPaymentMethod2(data.data.response.payment_method2);
            setPaymentMethod3(data.data.response.payment_method3);
            setPaymentMethod4(data.data.response.payment_method4);
            setPaymentMethod5(data.data.response.payment_method5);
            setPaymentMethod6(data.data.response.payment_method6);
            getBank(data.data.response.payment_method1);
            // getBank1(data.data.response.payment_method2);
            // getBank2(data.data.response.payment_method3);
            // getBank3(data.data.response.payment_method4);
            // getBank4(data.data.response.payment_method5);
            // getBank5(data.data.response.payment_method6);
            getItemDataSaved();
           
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      const getItemDataSaved = () => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/cicilan-cashier/pc-item`, {
    
            id: props.match.params.id
    
        }, {headers}).then(async response => {
            let stateItem = [];
            let stateEditing=[];
            await Promise.all(response.data.response.map(async (data) => {
                stateItem = [...stateItem, {
                    item_id: data.item_id,
                    item_name:data.item_name,
					item_code: data.item_code,
                    qty: data.qty,
                    harga: data.harga,
                    diskon_persen: data.diskon_persen,
                    diskon_nominal: data.diskon_nominal,
                    data: {
                        item_name: data.item_name,
                        harga: data.harga,
						item_code: data.item_code,
                        diskon_persen: data.diskon_persen,
                      diskon_nominal: data.diskon_nominal,
                      qty: data.qty,
                    },
                }];
                stateEditing = [...stateEditing, {
                    editing: false
                }];
            }));
            setSavedItems(stateItem);
        })
    }

    //   const getBank1 = (id) => {
	// 	const headers = {
	// 		"Content-Type": "application/json",
	// 		Authorization: `Bearer ${token}`,
	// 	};
	// 	axios
	// 		.get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-wh/${warehouse}`, { headers })
	// 		.then((data) => {
	// 			setBanks1(data.data.response);
    //             setPaymentMethod2(id);
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// };

    // const getBank2 = (id) => {
	// 	const headers = {
	// 		"Content-Type": "application/json",
	// 		Authorization: `Bearer ${token}`,
	// 	};
	// 	axios
	// 		.get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-wh/${warehouse}`, { headers })
	// 		.then((data) => {
	// 			setBanks2(data.data.response);
    //             setPaymentMethod3(id);
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// };

    // const getBank3 = (id) => {
	// 	const headers = {
	// 		"Content-Type": "application/json",
	// 		Authorization: `Bearer ${token}`,
	// 	};
	// 	axios
	// 		.get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-wh/${warehouse}`, { headers })
	// 		.then((data) => {
	// 			setBanks3(data.data.response);
    //             setPaymentMethod4(id);
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// };

    // const getBank4 = (id) => {
	// 	const headers = {
	// 		"Content-Type": "application/json",
	// 		Authorization: `Bearer ${token}`,
	// 	};
	// 	axios
	// 		.get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-wh/${warehouse}`, { headers })
	// 		.then((data) => {
	// 			setBanks4(data.data.response);
    //             setPaymentMethod5(id);
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// };

    // const getBank5 = (id) => {
	// 	const headers = {
	// 		"Content-Type": "application/json",
	// 		Authorization: `Bearer ${token}`,
	// 	};
	// 	axios
	// 		.get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-wh/${warehouse}`, { headers })
	// 		.then((data) => {
	// 			setBanks5(data.data.response);
    //             setPaymentMethod6(id);
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// };

	const handleSubmit2 = () => {
		hideAlert();
		setTimeout(() => {EditData()}, 1000);
	  };

    function EditData() {
        let dataItems = [];
        savedItems.map(
            (dataItem) =>
                (dataItems = [
                    ...dataItems,
                    {
                        item_id: dataItem.item_id,
                        qty: dataItem.qty,
                        harga: dataItem.harga,
                        diskon_nominal : dataItem.diskon_nominal,
                        diskon_persen : dataItem.diskon_persen
                    },
                ])
        );
        let data = {
            warehouse_id: parseInt(warehouse),
            username: username,
            code_rfq: "",
            customer_id: parseInt(customer),
            diskon_global_nominal : parseFloat(diskonglobalnominal),
            diskon_global_persen : parseInt(diskonglobalpersen),
            sales : sales,
            pajak_id: parseInt(pajak),
            pengiriman: parseInt(pengiriman),
            payment_method: parseInt(payment_method),
            jangka_waktu: "",
            keterangan: "",
            pay_1: parseInt(pay1),
            payment_method1: parseInt(payment_method1),
            pay_2: parseInt(pay2),
            payment_method2: parseInt(payment_method2),
            pay_3: parseInt(pay3),
            payment_method3: parseInt(payment_method3),
            pay_4: parseInt(pay4),
            payment_method4: parseInt(payment_method4),
            pay_5: parseInt(pay5),
            payment_method5: parseInt(payment_method5),
            pay_6: parseInt(pay6),
            payment_method6: parseInt(payment_method6),
            items: dataItems,
        };
        axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/cicilan-cashier/update-by-nota/${props.match.params.id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (response) {
                setSuccessAlert(response.data.message);
                history.push("/admin/pelunasan");
            })
            .catch(function (error) {
              setGagalAlert(error.response.data.message);

            });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={handleSubmit2}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };

	
	  
	// useEffect(() => {
	// 	setPay1(grandtotal);
	// }, [grandtotal]);

// 	useEffect(() => {
// 		setDiskonGlobalNominal1(diskonglobalnominal);
// 	}, [diskonglobalnominal]);

//     // diskon bayangan
//     useEffect(() => {
// 		setTotalDiskon(diskonglobalnominal1);
// 	}, [diskonglobalnominal1]);

// 	useEffect(() => {
// 		setTotalDiskon3(diskontotal-a);
// 	}, [diskontotal, a]);

//     //diskon tampil
//     useEffect(() => {
// 		setTotalDiskon2(totaldiskon + totaldiskon3 - a);
// 	}, [totaldiskon3, totaldiskon, a]);

// 	//ongkir tampil
// 	useEffect(() => {
// 		setOngkir1(ongkir - b);
// 	}, [ongkir, b]);

// 	//jasa tampil
// 	useEffect(() => {
// 		setJasa1(jasa - b);
// 	}, [jasa, b]);


//     useEffect(() => {
// 		setTotalPrice1(totalPrice);
// 	}, [totalPrice]);
	
// 	useEffect(() => {
// 		setSelisihBulat(bulat - grandtotal);
// 	}, [bulat, grandtotal]);
	


//     // diskonglobalnominal dan persen
//     useEffect(() => {
// 		diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((totalPrice - diskontotal) * diskonglobalpersen);
// 		diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((totalPrice - diskontotal) * (diskonglobalpersen/100));
// 	}, [diskonglobalnominal, totalPrice, diskonglobalpersen]);

//     // hasil nominal dari PPN
//     useEffect(() => {
//         setPPNNEW( (totalPrice -diskontotal) * (ppn / 100));
//    }, [totalPrice,diskontotal,ppn]);

//     // hasil grandtotal
// 	useEffect(() => {
// 		setGrandTotal(( totalPrice - totaldiskon2) + ppnnew + ongkir1 + jasa1 - a );
// 	}, [totalPrice, totaldiskon2,ppnnew, ongkir1, jasa1, a]);

// useEffect(() => {
//     setTotalPrice1(qtyTotal - ppnnew);
//   }, [qtyTotal, ppnnew]);

// useEffect(() => {
//     setDiskonGlobalNominal1(promoglobal);
// }, [promoglobal]);

// // diskon bayangan
// useEffect(() => {
//     setTotalDiskon(diskonglobalnominal1);
// }, [diskonglobalnominal1]);

// //diskon tampil
// useEffect(() => {
//     setTotalDiskon2(totaldiskon - a);
// }, [ totaldiskon, a]);

// useEffect(() => {
//     setTotalDiskon3(totaldiskon2 - a);
// }, [ totaldiskon2, a]);

//  // hasil nominal dari PPN
//  useEffect(() => {
// 	setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
// }, [qtyTotal,totaldiskon,ppn]);
 
// //ongkir tampil
// useEffect(() => {
//     setOngkir1(ongkir - b);
// }, [ongkir, b]);

// //jasa tampil
// useEffect(() => {
//     setJasa1(jasa - b);
// }, [jasa, b]);

// // useEffect(() => {
// // 	setSelisihBulat(bulat - grandtotal);
// // }, [bulat, grandtotal]);
   
// // hasil grandtotal
// useEffect(() => {
//     setGrandTotal(( totalPrice1 - totaldiskon3) + ppnnew + ongkir1 + jasa1 - a );
// }, [totalPrice1, totaldiskon3,ppnnew, ongkir1, jasa1, a]);

useEffect(() => {
	setTotalPrice1((qtyTotal)/((ppn/100)+1));
  }, [qtyTotal, ppnnew,ppn]);
  

useEffect(() => {
	setDiskonGlobalNominal1(promoglobal);
}, [promoglobal]);

// diskon bayangan
useEffect(() => {
	setTotalDiskon(diskonglobalnominal1);
}, [diskonglobalnominal1]);

//diskon tampil
useEffect(() => {
	setTotalDiskon2((totaldiskon)/((ppn/100)+1));
  }, [totaldiskon3, totaldiskon, ppn , a]);
  

// //diskon tampil
// useEffect(() => {
// 	setTotalDiskon2(totaldiskon - a);
// }, [ totaldiskon, a]);

useEffect(() => {
	setTotalDiskon3(totaldiskon2 - a);
}, [ totaldiskon2, a]);

 // hasil nominal dari PPN
 useEffect(() => {
	setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
}, [qtyTotal,totaldiskon,ppn]);
 
//ongkir tampil
useEffect(() => {
	setOngkir1(ongkir - b);
}, [ongkir, b]);

//jasa tampil
useEffect(() => {
	setJasa1(jasa - b);
}, [jasa, b]);
   
// hasil grandtotal
useEffect(() => {
	setGrandTotal(((totalPrice1 - totaldiskon3) + ppnnew + ongkir1 + jasa1 - a));
}, [totalPrice1, totaldiskon3,ppnnew, ongkir1, jasa1, a]);

	useEffect(() => {
        getById5();
	}, [pajak]);

	const getById5 = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/pajak/${pajak}`,
	        { headers }
	      )
	      .then((data) => {;
	        setPPN(data.data.response.persentase);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

	const getPajak = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
			.then((data) => {
				setAllPajak(data.data.response);
				setPajak(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getBank = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-wh/${warehouse}`, { headers })
			.then((data) => {
				setBanks(data.data.response);
                setPaymentMethod1(id)
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const formatRupiah = (money) => {
		return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(money);
	};

	return (
		<>
		{alert}
			<SimpleHeader name="Detail Kasir" parentName="SO" />
			<Container className="mt--6" fluid>
				<Row>
					<div className="col">
                        <Row md="12">
                            <Col md="9">
								<Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
										<div style={{ display: "flex", justifyContent: "space-between" }}>
											<h1>{codenota}</h1>
											<div style={{ textAlign: 'right' }}>
												{/* <Link className="btn btn-danger" to="/admin/kasir-sales-order/detail">
													 Closing Cashier
												</Link> */}
												{/* <Button color="danger" onClick={toggle}>
													Closing Cashier
												</Button> */}
											</div>
										</div>
                                    </CardHeader>
                                    <CardBody response>
										<Row md="8">
											<Col md="7">
												<FormGroup row>
													{/* <Col sm={14}> */}
														<textarea
															className="form-control"
															disabled
															style={{
																fontWeight: 'bold',
																fontSize: 50,
																paddingTop: 20,
																top: "50%",
																height: 120,
																resize: "none",
															}}
															value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}>
														</textarea>
													{/* </Col> */}
												</FormGroup>
											</Col>
											<Col md="5">
												<FormGroup>
													<Button
														style={{
															fontSize: 40,
															paddingTop: 10,
															height: 120,
															resize: "none",
															}}
														block
														color="primary"
														onClick={() => handleCetak()}>
															CETAK STRUK
													</Button>
												</FormGroup>
											</Col>
										</Row>
										<Row md="8">
											<Col md="4">
												<FormGroup row>
													<Col sm={12}>
														<Label style={{ color: "darkgray", fontSize: "14px" }}>Promo(Nominal) :</Label>
														<InputGroup>
															<InputGroupText>
															Rp
															</InputGroupText>
															<Input
															disabled
																autoComplete="off"
																// disabled={totalPrice < 1000}
																placeholder="Masukan Diskon (N)"
																type="text"
																value={diskonglobalnominal}
																onChange={(e) => {
																	setDiskonGlobalNominal(e.target.value);
																}}
															/>
														</InputGroup>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4">
												<FormGroup row>
													<Col sm={12}>
														<Label style={{ color: "darkgray", fontSize: "14px" }}>Pajak :</Label>
														<Input
														disabled
															autoComplete="off"
															type="select"
															value={pajak}
															onChange={(e) => {
																setPajak(e.target.value);
															}}>
															<option value="" disabled selected hidden>Pilih PPN</option>
															{allpajak.map((customer, key) => {
																return (
																	<option key={key} value={customer.id}>
																		{customer.keterangan}
																	</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4">
												<FormGroup row>
													<Col sm={12}>
														<Label style={{ color: "darkgray", fontSize: "14px" }}>Diskon (persen) :</Label>
														<InputGroup>
															<InputGroupText>
																Ongkir
															</InputGroupText>
															<Input
															disabled
																autoComplete="off"
																type="number"
																value={ongkir}
																onChange={(e) => {
																	setOngkir(e.target.value);
																}} />
														</InputGroup>
													</Col>
												</FormGroup>
											</Col>
											<Col md="2">
											</Col>
										</Row>
                                    </CardBody>
                                </Card>
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <h3>ITEM</h3>
                                    </CardHeader>
                                    <CardBody response>
                                        <Table size="sm" responsive>
                                            <thead>
                                                <tr>
                                                    <th colspan="4"><b>Nama Item</b></th>
                                                    <th colspan="4"><b>Kode Item</b></th>
                                                    <th colspan="4"><b>Harga</b></th>
                                                    <th colspan="4"><b>Qty</b></th>
                                                    <th colspan="4"><b>Diskon %</b></th>
                                                    <th colspan="4"><b>Diskon (N)</b></th>
                                                    <th colspan="4"><b>Sub Total</b></th>
                                                </tr>
                                            </thead>
                                                <tbody>
                                                {
                                                    savedItems.map((savedItem, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td colspan="4">{savedItem.item_name}</td>
                                                                <td colspan="4">{savedItem.item_code}</td>
                                                                <td colspan="4">
                                                                        <>{formatRupiah(savedItem.harga)}</>
                                                                </td>
                                                                <td colspan="4">
                                                                        <>{savedItem.qty}</>
                                                                </td>
                                                                <td colspan="4">
                                                                        <>{savedItem.diskon_persen}</>
                                                                </td>
                                                                <td colspan="4">
                                                                        <>{formatRupiah(savedItem.diskon_nominal)}</>
                                                                </td>
                                                                <td colspan="4">
                                                               {formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}

                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                            <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
										<div style={{ display: "flex", justifyContent: "space-between" }}>
											<h3>Transaksi</h3>
											<div style={{ textAlign: 'right' }}>
												
											</div>
										</div>
                                    </CardHeader>
                                    <CardBody response>
                                    <FormGroup row>
                                        <Col sm={12}>
                                            <Input
												disabled
                                                    autoComplete="off"
                                                    name="customer"
                                                    type="text"
                                                    value={customer}
                                                    onChange={(e) => {
                                                        setCustomer(e.target.value);
                                                    }}>
                                                </Input>
                                        </Col>
                                    </FormGroup>
									<FormGroup row>
												<Col sm={12}>
													<Input
														disabled
														autoComplete="off"
														placeholder="Masukan Alamat Kirim "
														type="text"
														value={alamatlain}
													/>
												</Col>
											</FormGroup>
                                        {/* <FormGroup row>
											<Col sm={12}>
												<Select2
													disabled
													value={customer}
													onChange={(e) => {
														setCustomer(e.target.value);
													}}
													options={{ 
														placeholder: "Pilih Customer"
													}}
													data={customers.map((customer) => {
														return (
														{ id: customer.id, text: customer.name }
														);
													})}
												/>
											</Col>
                                        </FormGroup> */}
                                        <FormGroup row>
                                            <Col sm={12}>
												<Input
                                                disabled
													autoComplete="off"
													type="text"
													value={payment_method}
													onChange={(e) => {
														setPaymentMethod(e.target.value);
													}}>
												</Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm={12}>
												<Input
													disabled
													autoComplete="off"
													type="select"
													value={pengiriman}
													onChange={(e) => {
														setPengiriman(e.target.value);
													}}>
													<option value="" selected disabled hidden>Pilih Pengiriman</option>
													<option value={1}>Ambil Sendiri</option>
													<option value={2}>
														Delivery
													</option>
													<option value={3}>Kurir</option>
												</Input>
                                            </Col>
                                        </FormGroup>
										{pengiriman >  1  ?(
												<FormGroup row>
												<Col sm={12}>
													<Input
														disabled
														autoComplete="off"
														placeholder="Masukan Alamat Kirim "
														type="text"
														value={alamatcustomer}
														onChange={(e) => {
															setAlamatCustomer(e.target.value);
														}}
													/>
												</Col>
											</FormGroup>
											):(
												""
											)
										}
										
                                        <hr />
										<FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={5}
                                                size="small"
                                            >
                                                <b>Sub Total</b>
                                            </Label>
                                        <Col sm={7}>
                                            <Input
                                                className="form-control-alternative"
                                                disabled
                                                style={{fontWeight: 'bold'}}
                                                type="text"
                                                name="barcode"
                                                placeholder="Harga Total"
                                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                            />
                                        </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={5}
                                            >
                                                <b>Promo</b>
                                            </Label>
                                        <Col sm={7}>
                                            <Input
                                                className="form-control-alternative"
                                                style={{fontWeight: 'bold'}}
                                                disabled
                                                type="text"
                                                name="barcode"
                                                placeholder="Total Diskon"
                                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                            />
                                        </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={5}
                                            >
                                                <b>PPN</b>
                                            </Label>
                                        <Col sm={7}>
                                            <Input
                                                className="form-control-alternative"
                                                disabled
                                                style={{fontWeight: 'bold'}}
                                                type="text"
                                                name="barcode"
                                                placeholder="PPN"
                                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                            />
                                        </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={5}
                                            >
                                                <b>Ongkir</b>
                                            </Label>
                                        <Col sm={7}>
                                            <Input
                                                className="form-control-alternative"
                                                disabled
                                                style={{fontWeight: 'bold'}}
                                                type="text"
                                                name="barcode"
                                                placeholder="PPN"
                                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                            />
                                        </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={5}
                                            >
                                                <b>Grand Total</b>
                                            </Label>
											<Col sm={7}>
												<Input
													className="form-control-alternative"
													disabled
													type="text"
													name="barcode"
													style={{fontWeight: 'bold'}}
													placeholder="Grand Total"
													value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
												/>
											</Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
					</div>
				</Row>
			</Container>
            <Modal toggle={() => setIsOpen(!isOpen)} isOpen={isOpen} fade={false} style={{ minWidth: "70%", top: "-15%" }}>
				<ModalHeader toggle={() => setIsOpen(!isOpen)}>Pecah Bayar</ModalHeader>
				<ModalBody
					cssModule={{
						alignText: "center",
					}}>
					<div className="container">
						{/* <div className="row align-items-center mb-3">
							<div className="col-3">Pembayaran 1</div>
							<div className="col-1 text-center">:</div>
							<div className="col-4 text-center">
								<Input className="form-control-alternative"
									disabled
									type="number"
									// disabled={pay2 < 1000000000}
									value={pay1}
									placeholder="Masukan Nomminal Pembayaran"
									onChange={(event) => {
										setPay1(event.target.value);
									}}></Input>
							</div>
							<div className="col-4 text-center">
								<Input className="form-control-alternative"
									disabled
									name="Tipe Po"
									type="select"
									// disabled={pay1 > 1000}
									placeholder="Metode Pembayaran 1"
									value={payment_method1}
									onChange={(e) => {
										setPaymentMethod1(e.target.value);
									}}>
									<option value="">Pilih Metode Pembayaran</option>
									{banks.map((bank, key) => {
										return (
											<option key={key} value={bank.id}>
												{bank.bank_name}
											</option>
										);
									})}
								</Input>
							</div>
						</div>
						<div className="row align-items-center mb-3">
							<div className="col-3">Pembayaran 2</div>
							<div className="col-1 text-center">:</div>
							<div className="col-4 text-center">
								<Input disabled={pay2s > 0 } className="form-control-alternative" type="number" value={pay2} onChange={(event) => setPay2(event.target.value)} placeholder="Masukan Nomminal Pembayaran"></Input>
							</div> 
							<div className="col-4 text-center">
									<Input className="form-control-alternative"
									// disabled={pay2 > 1000}
									disabled={pay2s > 0 }
									name="Tipe Po"
									type="select"
									placeholder="Metode Pembayaran 2"
									value={payment_method2}
									onChange={(e) => {
										setPaymentMethod2(e.target.value);
									}}>
									<option value="">Pilih Metode Pembayaran</option>
									{banks1.map((bank, key) => {
										return (
											<option key={key} value={bank.id}>
												{bank.bank_name}
											</option>
										);
									})}
								</Input>
							</div>
						</div>
                        <div className="row align-items-center mb-3">
							<div className="col-3">Pembayaran 3</div>
							<div className="col-1 text-center">:</div>
							<div className="col-4 text-center">
								<Input 
								// disabled={pay4 > -1} 
								disabled={pay3s > 0 }
								className="form-control-alternative" type="number" value={pay3} onChange={(event) => setPay3(event.target.value)} placeholder="Masukan Nomminal Pembayaran"></Input>
							</div>
							<div className="col-4 text-center">
								<Input className="form-control-alternative"
									name="Tipe Po"
									type="select"
									disabled={pay3s > 0 }
									// disabled={pay3 > 1000}
									placeholder="Metode Pembayaran 3"
									value={payment_method3}
									onChange={(e) => {
										setPaymentMethod3(e.target.value);
									}}>
									<option value="">Pilih Metode Pembayaran</option>
									{banks2.map((bank, key) => {
										return (
											<option key={key} value={bank.id}>
												{bank.bank_name}
											</option>
										);
									})}
								</Input>
							</div>
						</div>
                        <div className="row align-items-center mb-3">
							<div className="col-3">Pembayaran 4</div>
							<div className="col-1 text-center">:</div>
							<div className="col-4 text-center">
								<Input 
								// disabled={pay4 > 1000}
								disabled={pay4s > 0 }
								 className="form-control-alternative" type="number" value={pay4} onChange={(event) => setPay4(event.target.value)} placeholder="Masukan Nomminal Pembayaran"></Input>
							</div>
							<div className="col-4 text-center">
								<Input className="form-control-alternative"
									name="Tipe Po"
									type="select"
									// disabled={pay4 > 1000}
								disabled={pay4s > 0 }

									placeholder="Metode Pembayaran 4"
									value={payment_method4}
									onChange={(e) => {
										setPaymentMethod4(e.target.value);
									}}>
									<option value="">Pilih Metode Pembayaran</option>
									{banks3.map((bank, key) => {
										return (
											<option key={key} value={bank.id}>
												{bank.bank_name}
											</option>
										);
									})}
								</Input>
							</div>
						</div>
                        <div className="row align-items-center mb-3">
							<div className="col-3">Pembayaran 5</div>
							<div className="col-1 text-center">:</div>
							<div className="col-4 text-center">
								<Input  
								// disabled={pay5 > 1000}
								disabled={pay5s > 0 }
								 className="form-control-alternative" type="number" value={pay5} onChange={(event) => setPay5(event.target.value)} placeholder="Masukan Nomminal Pembayaran"></Input>
							</div>
							<div className="col-4 text-center">
								<Input className="form-control-alternative"
									name="Tipe Po"
									type="select"
								disabled={pay5s > 0 }
									// disabled={pay5 > 1000}
									placeholder="Metode Pembayaran 5"
									value={payment_method5}
									onChange={(e) => {
										setPaymentMethod5(e.target.value);
									}}>
									<option value="">Pilih Metode Pembayaran</option>
									{banks4.map((bank, key) => {
										return (
											<option key={key} value={bank.id}>
												{bank.bank_name}
											</option>
										);
									})}
								</Input>
							</div>
						</div>
                        <div className="row align-items-center mb-3">
							<div className="col-3">Pembayaran 6</div>
							<div className="col-1 text-center">:</div>
							<div className="col-4 text-center">
								<Input 
								disabled={pay6s > 0 }
								className="form-control-alternative" type="number" value={pay6} onChange={(event) => setPay6(event.target.value)} placeholder="Masukan Nomminal Pembayaran"></Input>
							</div>
							<div className="col-4 text-center">
								<Input className="form-control-alternative"
									name="Tipe Po"
									type="select"
									disabled={pay6s > 0 }
									placeholder="Metode Pembayaran 6"
									value={payment_method6}
									onChange={(e) => {
										setPaymentMethod6(e.target.value);
									}}>
									<option value="">Pilih Metode Pembayaran</option>
									{banks5.map((bank, key) => {
										return (
											<option key={key} value={bank.id}>
												{bank.bank_name}
											</option>
										);
									})}
								</Input>
							</div>
						</div>
						<div className="row align-items-center mb-3">
							<div className="col-3 text-start  display-1">DP</div>
							<div className="col-1 text-center">:</div>
							<div className="col-6 text-center">
								<textarea
									className="form-control"
									disabled
									value={"Rp." + pay1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
									style={{
										fontSize: 40,
										paddingTop: 20,
										top: "50%",
										height: 95,
                                        fontWeight: 'bold',
										resize: "none",
									}}></textarea>
							</div>
						</div>
						<div className="row align-items-center mb-3">
							<div className="col-3  display-3">SISA </div>
							<div className="col-1 text-center">:</div>
							<div className="col-6 text-center">
								<textarea
									className="form-control"
									disabled
									value={"Rp." + ((-1 *  parseInt(grandtotal)) + (parseInt(pay1) + parseInt(pay2)  + parseInt(pay3) + parseInt(pay4) + parseInt(pay5) + parseInt(pay6))).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
									style={{
										fontSize: 40,
										paddingTop: 20,
                                        fontWeight: 'bold',
										top: "50%",
										height: 95,
										resize: "none",
									}}></textarea>
							</div>
						</div> */}
					</div>
				</ModalBody>
				<ModalFooter>
					{/* <Button
						color="danger"
						outline
						onClick={() => setQuestionAlert()}>
						Konfirmasi Pembayaran
					</Button>{""} */}
					<Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
				</ModalFooter>
			</Modal>
            {<div style={{visibility:'hidden', display:'none'}}>
			<ViewCetakSoKasir
				namapaymentmethod={payment_method1}
				nonota={codenota}
				kasir={username}
				today={waktu}
				customer={customer} 
				alamatcustomer={alamatcustomer} 
				detailpembelian={savedItems}
				total={"Rp. " + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				promo = {"Rp. " + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				// diskon={"Rp. " + totaldiskon1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				ppn={"Rp. " + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				ongkir={"Rp. " + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				jasa={"Rp. " + jasa1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				grandtotal={"Rp. " + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				// pembulatan={"Rp. " + selisihbulat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				// totalasli={"Rp. " + bulat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				dibayar={"Rp. " + (parseInt(pay1) + parseInt(pay2)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				kembali={"Rp. " + (- 1 * parseInt(grandtotal) + parseInt(pay1) + parseInt(pay2)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				
				ref={componentCetakSoKasir}/>
			</div>}
		</>
	);
}
