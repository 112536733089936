import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  // Input,
  Label
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
// import * as FileSaver from "file-saver";

// import styles from '../../../../assets/scss/Print.scss'
import moment from "moment";

const ModalCetakSoTerbaru = ({ open, toggle, data }) => {
    const token = localStorage.token;
    const username = localStorage.username;
    const [savedItems, setSavedItems] = useState([]);
    const [download, setDownload] = useState("")
    const [codepo, setCodePo] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [supplier, setSupplier] = useState("");
    const [warehouserfq, setWarehouseRfq] = useState("");
    const [ongkir, setOngkir] = useState(0);
    const [waktu,setWaktu] = useState([]);
    const [hargatotal, setHargaTotal] = useState([]);
    const [validator, setValidator] = useState("");
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [payment, setPayment] = useState(1);
    const [dp, setDp] = useState(0);
    const [termin, setTermin] = useState(0);
    const [pricereal, setPriceReal]= useState("")
    const [diskontotal, setDiskonTotal] = useState("")
    const [pajak, setPajak] = useState("");
    const [creator, setCreator] = useState("")
    // const [item, setItem] = useState("")
    const [ppn, setPPN] = useState("")

 

  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/sales-order/cetak/${data.id}`,
        { headers }
      )
      .then((data) => {  
        setAddress(data.data.response.so.address)
        setPhone(data.data.response.so.phone)
        setCreator(data.data.response.so.creator)
        setSupplier(data.data.response.so.customer);
        setDownload(data.data.response.so.counter);
        setWaktu(data.data.response.so.created)
        setWarehouseRfq(data.data.response.so.warehouse);
        setPayment(data.data.response.so.payment_method)
        setDp(data.data.response.so.um_nominal)
        setTermin(data.data.response.so.jangka_waktu)
        setDiskonTotal(data.data.response.so.diskon_total);
        setPajak(data.data.response.so.nominal_pajak);
        setHargaTotal(data.data.response.so.payment_total);
        setPriceReal(data.data.response.so.price_total);
        setKeterangan(data.data.response.so.keterangan);
        setOngkir(data.data.response.so.ongkir);
        setCodePo(data.data.response.so.so_code);
        setValidator(data.data.response.so.validator1);
        setPPN(data.data.response.so.persen_pajak)
        // setItem(data.data.response.list.length);
        // getItemDataSaved(data.data.response.so.id)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [token, data.id]);;

  const HitCounter = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/sales-order/hit-counter/${data.id}`,
        { headers }
      )
      .then((data) => {  
        console.log("suskes Cuk");
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/sales-order/cetak/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                harga: data.harga,
                satuan: data.satuan,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                sub_total : data.sub_total,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
}, [token, data.id]);

useEffect(() => {
  getById();
  getItemDataSaved();
}, [  getById,getItemDataSaved]);

// const downloadExcel = async ()=> {
//     const J = "Sales-Order"
//     const fileName = J;
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     axios
//       .get(`${process.env.REACT_APP_API_BASE_URL}/sales-order/cetak/${data.id}`,{headers})
//       .then((data) => {
//         setAddress(data.data.response.so.address)
//         setPhone(data.data.response.so.phone)
//         setCreator(data.data.response.so.creator)
//         setSupplier(data.data.response.so.customer);
//         setWaktu(data.data.response.so.created)
//         setWarehouseRfq(data.data.response.so.warehouse);
//         setDiskonTotal(data.data.response.so.diskon_total);
//         setPajak(data.data.response.so.nominal_pajak);
//         setHargaTotal(data.data.response.so.payment_total);
//         setPriceReal(data.data.response.so.price_total);
//         setKeterangan(data.data.response.so.keterangan);
//         setOngkir(data.data.response.so.ongkir);
//         setCodePo(data.data.response.so.so_code);
//         setValidator(data.data.response.so.validator1);
//         // setItem(data.data.response.list.length);

//         var apiPo = data.data.response.list;
//         // var tglcetak = data.data.response.download_date;
//         var tableToExcel = (function() {
//           // var uri = 'data:application/vnd.ms-excel;base64,',
//           var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
//             // base64 = function(s) {
//             //   return window.btoa(unescape(encodeURIComponent(s)))
//             // },
//             format = function(s, c) {
//               return s.replace(/{(\w+)}/g, function(m, p) {
//                 return c[p];
//               })
//             }
//           return function(table, name) {
//             var heading = 'Sales-Order' ;
//             var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
//             var po = '';
//             var a ;
//             for(a=0; a < apiPo.length; a++){
//               po += `
//               <tr style="border: 1px solid black">
//                   <td>${apiPo[a].item_name}</td>
//                   <td>${apiPo[a].item_code}</td>
//                   <td>${formatRupiah(apiPo[a].harga)}</td>
//                   <td>${apiPo[a].qty}</td>
//                   <td>${apiPo[a].satuan}</td>
//                   <td>${apiPo[a].diskon_persen}</td>
//                   <td>${formatRupiah(apiPo[a].diskon_nominal)}</td>
//                   <td>${formatRupiah(apiPo[a].sub_total)}</td>
//               </tr>
//               `
//             }
//             var table = `
//             <table id="account_table">
//                 <tbody>
//                 <tr>
//                     <td><img src="https://i.ibb.co/jkFYDqg/150x75.png"/> </td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>${warehouserfq}</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Jl Menganti Karangan No.562</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Wiyung surabaya barat - Jawa Timur </td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Telp: 081 217 85 3300 </td>
//                 </tr>
//                 <tr>
//                     <td colspan="8"><center><b><h1>Sales Order</h1></b><center></td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                 <tr>
//                     <td>Tanggal</td>
//                     <td>: ${waktu}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Customer</td>
//                     <td>: ${supplier}</td>
//                 </tr>
//                 <tr>
//                     <td>Kode SO</td>
//                     <td>: ${codepo}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Alamat</td>
//                     <td>: ${address}</td>
//                 <tr>
//                 <tr>
//                     <td>Ket</td>
//                     <td rowspan="2">: ${keterangan}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Phone </td>
//                     <td>: ${phone}</td>
//                 <tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                   <tr style="border: 1px solid black">
//                       <th>Nama Item</th>
//                       <th>Kode Item</th>
//                       <th>Harga</th>
//                       <th>Qty</th>
//                       <th>Satuan</th>
//                       <th>Disc %</th>
//                       <th>Disc N</th>
//                       <th>Sub Total</th>
//                     </tr>
//                         ${po}
//                 </tbody>
//             </table>
//             `;
//             var ctx = {
//                 worksheet: name || 'WorkBook',
//                 imgsrc1: imgsrc1,
//                 heading: heading,
//                 table: table
//             }
//             var blob = new Blob([format(template, ctx)]);
//             return blob;
//             }
//         })()
//         var blobURL = tableToExcel('account_table', 'Sales Order');
//         FileSaver.saveAs(blobURL, fileName+'.xls');
        
//         })
//       .catch(function (error) {
//       });
//   }
  

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }
    
  // function print() {
  //   var printContents = document.getElementById("targetContent").innerHTML;
  //   var originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // }

  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    // console.log(item)
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }
  
  // function clickItem(e) {
  //   setItem(e.target.value)
  // }

  function renderPDF(){
    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []  
    // for (let index = 0; index < sheet; index++) {
    //   let start = 0
    //   let end = 0
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(sheet > 1 && index < (sheet - 1)){
    //       end = item
    //     }else{
    //       end = length % item
    //     }
    //   }

    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []
    // for (let index = 0; index < sheet; index++) {
    //   let start = (item * index)
    //   let end = length
    //   let sisa = (length % item)
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(index < (sheet - 1) && sheet == 2){
    //       end = item
    //     }else{
    //       if (index == (sheet - 1)) {
    //         end = length - sisa + (index * item)
    //       }else{
    //         if (index == 0) {
    //           end = item
    //         }else{
    //           end = (index+1) * item
    //         }
    //       }
    //     }
    //   } 
    //   let data = savedItems.slice(start,end)
    const itemsPerPage = 10; // Maksimal 7 item per halaman
    const totalItems = savedItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Hitung total halaman
  
    const sheets = [];
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      // Hitung indeks awal dan akhir untuk slice item per halaman
      const start = pageIndex * itemsPerPage;
      const end = start + itemsPerPage;
  
      // Ambil item untuk halaman saat ini
      const currentItems = savedItems.slice(start, end);
      // console.log(data.length)
      let usernamecok = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
      sheets.push(
        <div className='pagebreak'>
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <Row md="12">
              <Col md="12">
                  <h3 className="" style={{ color:"black" }}><b><center>PENJUALAN BARANG</center></b></h3>
              </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          
          <Row md="12">
            <Col style={{marginLeft:"2%",width:"40%",flexBasis: "unset"}}>
             
              <div className="row align-items-center ">
                  <div style={{width:"17%" ,color:"black",fontSize:"14px"}}  className="text-table">Tanggal</div>
                  <div style={{width:"2%" ,color:"black",fontSize:"14px"}}  className="text-center text-table">:</div>
                  <div style={{width:"60%" ,color:"black",fontSize:"14px"}}  className="text-table">
                      <b>{waktu}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"17%" ,color:"black",fontSize:"14px"}} className="text-table">No SO</div>
                  <div style={{width:"2%" ,color:"black",fontSize:"14px"}} className="text-center text-table">:</div>
                  <div style={{width:"60%" ,color:"black",fontSize:"14px"}} className="text-table">
                      <b>{codepo}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"17%" ,color:"black",fontSize:"14px"}} className="text-table">Pembayaran</div>
                  <div style={{width:"2%" ,color:"black",fontSize:"14px"}} className="text-center text-table">:</div>
                  <div style={{width:"60" ,color:"black",fontSize:"14px"}} className="text-table">
                    {payment === 1 ? 
                    <b>Lunas</b>
                    : payment === 2 ? 
                    <b>Tempo</b>
                    :
                    <b>Dp</b>
                  }
                  </div>
                  {/* <div style={{width:"60%"}} className="text-table">
                    {payment === 1 ? 
                    ""
                    : payment === 2 ? 
                    <b>Termin : {termin} Hari</b>
                    :
                    <b>Termin : {termin} Hari</b>
                  }
                  </div> */}
              </div>
              { payment === 1 ?
              ""
              : payment ===2 ?
              <div className="row align-items-center ">
              <div style={{width:"17%" ,color:"black",fontSize:"14px"}}  className="text-table">Termin </div>
              <div style={{width:"2%" ,color:"black",fontSize:"14px"}}  className="text-center text-table">:</div>
              <div style={{width:"60%" ,color:"black",fontSize:"14px"}}  className="text-table">
                  <b>{termin} Hari</b>
              </div>
              </div>
              :
              <div className="row align-items-center ">
                  <div style={{width:"17%" ,color:"black",fontSize:"14px"}}  className="text-table">Termin </div>
                  <div style={{width:"2%" ,color:"black",fontSize:"14px"}}  className="text-center text-table">:</div>
                  <div style={{width:"60%" ,color:"black",fontSize:"14px"}}  className="text-table">
                    <b>{termin} Hari</b>
                  </div>
              </div>
            }
              <div className="row align-items-center ">
                  <div style={{width:"17%" ,color:"black",fontSize:"14px"}}  className="text-table">keterangan </div>
                  <div style={{width:"2%" ,color:"black",fontSize:"14px"}}  className="text-center text-table">:</div>
                  <div style={{width:"60%" ,color:"black",fontSize:"14px"}}  className="text-table">
                      <b>{keterangan}</b>
                  </div>
              </div>
            </Col>
            <Col style={{width:"40%",flexBasis: "unset"}}>
              <div className="row align-items-center ">
                  <div style={{width:"15%" ,color:"black",fontSize:"14px"}} className="text-table">Supplier</div>
                  <div style={{width:"5%" ,color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"65%" ,color:"black",fontSize:"14px"}} className="text-table">
                      <b>{supplier}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"15%" ,color:"black",fontSize:"14px"}} className="text-table">Alamat</div>
                  <div style={{width:"5%" ,color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"65%" ,color:"black",fontSize:"14px"}} className="text-table">
                      <b>{address}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"15%" ,color:"black",fontSize:"14px"}} className="text-table">Kontak</div>
                  <div style={{width:"5%" ,color:"black",fontSize:"14px"}} className="text-table">:</div>
                  <div style={{width:"65%" ,color:"black",fontSize:"14px"}} className="text-table">
                      <b>{phone}</b>
                  </div>
              </div>
            </Col>
          </Row>
            <br></br>
              <Table size="sm" style={{width:"100%"}} responsive>
                <thead>
                <tr>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>No</b>
                    </th>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Kode</b>
                    </th>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Nama</b>
                    </th>
                    {/* <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Brand</b>
                    </th> */}
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Harga</b>
                    </th>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Qty</b>
                    </th>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Sat</b>
                    </th>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Disc %</b>
                    </th>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Disc N</b>
                    </th>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Total</b>
                    </th>
                    <th className="text-table" style={{color:"black",border: "1px solid black"}}>
                    <b>Keterangan</b>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    currentItems.map((temp,index, key) => {
                        // let item_name =  temp.data.item_name
                        // sub_total = sub_total + temp.sub_total
                        return (
                            <tr>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{index+1}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.item_code}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.item_name}</td>
                                {/* <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>-</td> */}
                                {/* <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{formatRupiah(temp.harga)}</td> */}
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{formatRupiah(temp.harga / (ppn/100 + 100/100))}</td>

                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.qty}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.satuan}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.diskon_persen}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>{temp.diskon_nominal}</td>
                                <td className="text-table" style={{color:"black",border: "1px solid black",whiteSpace: "nowrap", padding: "4px 8px"}}>
                                  {/* {formatRupiah(temp.sub_total)} */}
                                  {formatRupiah(((temp.harga - temp.diskon_nominal) * temp.qty) / (ppn/100 + 100/100))}

                                  </td>
                                <td className="text-table" style={{color:"black",border: "1px solid black"}}></td>
                            </tr>
                        );
                    })
                }
                  <tr className="w-100 text-table" style={{ border: "1px solid black" }}></tr>
                  <tr className="no-border">
                    <td colspan="6" className="border-non text-table" style={{color:"black",border: "none"}}></td>
                    <td colspan="2" className="text-right text-table border-non" style={{color:"black",border: "none"}}>Sub Total
                    </td>
                    <td className="text-left text-table border-non" style={{color:"black",border: "none"}}>
                          <b>{formatRupiah(pricereal)}</b>
                    </td>
                    <td className="border-non text-table" style={{color:"black",border: "none"}}></td>
                  </tr>
                  <tr>
                    <td colspan="6" className="border-non text-table" style={{color:"black",border: "none"}}></td>
                    <td colspan="2" className="text-right text-table border-non" style={{color:"black",border: "none"}}>Diskon
                    </td>
                    <td className="text-left text-table border-non" style={{color:"black",border: "none"}}>
                          <b>{formatRupiah(diskontotal)}</b>
                    </td>
                    <td className="border-non text-table" style={{color:"black",border: "none"}}></td>
                  </tr>
                  <tr>
                    <td colspan="6" className="border-non text-table" style={{color:"black",border: "none"}}></td>
                    <td colspan="2" className="text-right text-table border-non"style={{color:"black",border: "none"}}>PPN
                    </td>
                    <td className="text-left text-table border-non"style={{color:"black",border: "none"}}>
                          <b>{formatRupiah(pajak)}</b>
                    </td>
                    <td className="border-non text-table"style={{color:"black",border: "none"}}></td>
                  </tr>
                  <tr>
                    <td colspan="6" className="border-non text-table"style={{color:"black",border: "none"}}></td>
                    <td colspan="2" className="text-right text-table border-non"style={{color:"black",border: "none"}}>Ongkir
                    </td>
                    <td className="text-left text-table border-non"style={{color:"black",border: "none"}}>
                          <b>{formatRupiah(ongkir)}</b>
                    </td>
                    <td className="border-non text-table"style={{color:"black",border: "none"}}></td>
                  </tr>
                  {dp === 0 ? 
                    ""
                    :
                    <tr>
                      <td colspan="6" className="border-non text-table"style={{color:"black",border: "none"}}></td>
                      <td colspan="2" className="text-right text-table border-non"style={{color:"black",border: "none"}}>Uang Muka
                      </td>
                      <td className="text-left text-table border-non"style={{color:"black",border: "none"}}>
                            <b>{formatRupiah(dp)}</b>
                      </td>
                      <td className="border-non text-table"style={{color:"black",border: "none"}}></td>
                    </tr>
                  }
                  <tr>
                    <td colspan="6" className="border-non text-table"style={{color:"black",border: "none"}}></td>
                    <td colspan="2" className="text-right text-table border-non"style={{color:"black",border: "none"}}>Grand Total
                    </td>
                    <td className="text-left text-table border-non"style={{color:"black",border: "none"}}>
                          <b>{formatRupiah(hargatotal)}</b>
                    </td>
                    <td className="border-non text-table"style={{color:"black",border: "none"}}></td>
                  </tr>
                </tbody>
              </Table>
              <div ></div>
              <Row md="12">
              </Row>
          <div className="w-100 text-table" style={{ border: "0.5px solid black" }}></div>
          <center className="text-table" style={{color:"black",border: "none"}}>Terms of Price, delivery & shipping required</center>
          <Row md="20">
            <Col style={{ marginLeft:"2%",width:"20%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table" style={{color:"black",border: "none"}}>Di Buat</div>
                  <div className="col-1 text-center text-table" style={{color:"black",border: "none"}}>:</div>
                  <div className="col-8 text-table" style={{color:"black",border: "none"}}>
                      <b>{creator}</b>
                  </div>
              </div>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  <div className="col-3 text-table" style={{color:"black",border: "none"}}>Signature</div>
                  <div className="col-1 text-center text-table" style={{color:"black",border: "none"}}>:</div>
                  <div className="col-8 text-table">
                      {/* <b>------------------</b> */}
                  </div>
              </div>
            </Col>
            <Col style={{ width:"60%" }}>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  <div className="col-4 text-table" style={{color:"black",border: "none"}}>Head Purchasing</div>
                  <div className="col-1 text-center text-table" style={{color:"black",border: "none"}}>:</div>
                  <div className="col-6 text-table" style={{color:"black",border: "none"}}>
                      <b>{validator}</b>
                  </div>
              </div>
              <div className="row align-items-center " style={{color:"black",border: "none"}}>
                  <div className="col-4 text-table" style={{color:"black",border: "none"}}>Signature</div>
                  <div className="col-1 text-center text-table" style={{color:"black",border: "none"}}>:</div>
                  <div className="col-6 text-table">
                      {/* <b>------------------</b> */}
                  </div>
              </div>
            </Col>
          </Row>
          <div className="w-100" style={{ border: "0.5px solid black" }}></div>
                      {/* {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download {download} Kali Oleh {username}</i></div>
                    </div>
                      ) 
                    }
          <div className="divFooter">{usernamecok}</div> */}
          <div className="divFooter" style={{ fontSize:"12px" }}><i>{usernamecok}</i>, {download === 0 ? (
                        // <div className="row align-items-center mb-3">
                        <i>,Download Original</i>
                          // </div>
                        ):(
                        // <div className="row align-items-center mb-3">
                        <i>,Di Download {download} Kali</i>
                      // </div>
                      )
                    }</div>
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }
  
  return (
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <div style={{ textAlign: 'left' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            Set Item
                        </Label>
                        <Input type="number" pattern="[0-9]*" value={item} min="1" max={savedItems.length} onChange={clickItem} style={{width: '70px',marginRight:'50px'}}/>
                    </div> */}
                    <div style={{ textAlign: 'right' }}>
                    <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                        <Button color="info" onClick={() => {print();HitCounter();setTimeout(() => (window.location.reload("/admin/sales-order/histori")), 1000)}}>Print</Button>
                        {/* <Button color="success" onClick={()=> (downloadExcel(),HitCounter())}>Excel</Button> */}
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
      <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
      </ModalBody>
    </Modal>
  );

  

};

export default ModalCetakSoTerbaru;
